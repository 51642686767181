import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const UserDashboard = () => {
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Row>
            <Col lg={2}>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-sm font-size-20 me-4">
                      <span className="avatar-title bg-soft-primary text-primary rounded">
                        <i className="mdi mdi-tag-plus-outline"></i>
                      </span>
                    </div>
                    <div className="flex-1  align-self-center text-center">
                      <div
                        className="font-size-16 mt-2 text-right"
                        style={{ fontWeight: "bold" }}
                      >
                        <h3>10</h3>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-7">
                      <p className="mb-0">
                        <Link to="#">
                          <span className="text-success me-3">
                            {" "}
                            Links<i className="mdi mdi-arrow-right-thick"></i>{" "}
                          </span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
};
export default UserDashboard;

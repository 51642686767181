import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { getMonthlyInterest } from "../../services/userService";
import moment from "moment";
import Loader from "../../components/Custom/Loader";
import { numDifferentiation } from "../../helpers/amount_helper";

const InterestTable = (props) => {
  const [interestData, setInterestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    if (!showMore) {
      setLimit(interestData.length);
    } else {
      setLimit(5);
    }
    setShowMore(!showMore);
  };

  useEffect(() => {
    const getInterestData = () => {
      setLoading(true);
      const query = `?project_id=${props.projectId}`;
      getMonthlyInterest(query)
        .then((res) => {
          let data = res.data && res.data.data && res.data.data.data;
          setInterestData(data.reverse());
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    };
    getInterestData();
  }, [props.projectId]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th className="fw-bold">Month - Year </th>
            <th className="text-center  fw-bold">Outstanding Amount </th>
            <th className="text-center fw-bold">
              Outstanding Amount With Previous Interest{" "}
            </th>
            <th className="text-center fw-bold">Interest Rate </th>
            <th className="text-center fw-bold">Interest Amount </th>
            <th className="text-center fw-bold">Total Interest Amount </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={66}>
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: "10rem" }}
                >
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            interestData.map((d, i) => {
              if (i < limit) {
                return (
                  <tr key={i}>
                    <td style={{ width: "10%" }}>
                      {moment(d.month).subtract(1, "month").format("MMM-YYYY")}
                    </td>
                    <td style={{ width: "20%" }} className="text-center">
                      {numDifferentiation(d.outstanding.toFixed(2))}
                    </td>
                    <td style={{ width: "20%" }} className="text-center">
                      {numDifferentiation(d.outstadingWithInterest.toFixed(2))}
                    </td>
                    <td style={{ width: "15%" }} className="text-center">
                      {d.interestRate} %
                    </td>
                    <td style={{ width: "15%" }} className="text-center">
                      {numDifferentiation(d.interest.toFixed(2))}
                    </td>
                    <td style={{ width: "15%" }} className="text-center">
                      {numDifferentiation(d.totalInterest.toFixed(2))}
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <span
                onClick={handleShowMore}
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-end"
              >
                {" "}
                {showMore && (
                  <>
                    <i className="dripicons-chevron-up" /> {"Show Less"}
                  </>
                )}
                {interestData.length > limit && !showMore && (
                  <>
                    <i className="dripicons-chevron-down" /> {"Show More"}
                  </>
                )}
              </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default InterestTable;

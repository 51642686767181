import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Custom/Loader";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  addInterestRate,
  getInterestRate,
  updateInterestRate,
} from "../../services/userService";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import Select from "react-select";

const title = "Interest Rate";

function limitDecimalPlaces(e, count) {
  if (e.target.value.indexOf(".") == -1) {
    return;
  }
  if (e.target.value.length - e.target.value.indexOf(".") > count) {
    e.target.value = parseFloat(e.target.value).toFixed(count);
  }
}

function MonthlyInterest(props) {
  const defaultInterestArray = [
    { month: `${moment().format("YYYY")}-01`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-02`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-03`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-04`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-05`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-06`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-07`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-08`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-09`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-10`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-11`, rate: " 0 %" },
    { month: `${moment().format("YYYY")}-12`, rate: " 0 %" },
  ];
  const months = [
    { 0: "January" },
    { 1: "February" },
    { 2: "March" },
    { 3: "April" },
    { 4: "May" },
    { 5: "June" },
    { 6: "July" },
    { 7: "August" },
    { 8: "September" },
    { 9: "October" },
    { 10: "November" },
    { 11: "December" },
  ];
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [interestData, setInterestData] = useState([]);
  // const [firstMonth, setFirstMonth] = useState({});
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [updatingMonth, setUpdatingMonth] = useState(0);
  const [interestId, setInterestId] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [yearsArray, setYearsArray] = useState([]);
  const [interestArray, setInterestArray] = useState(defaultInterestArray);

  let params = window.location.search;
  let project_id = new URLSearchParams(params).get("project_id");

  const functionForSettingYears = () => {
    const yearsArray = [];
    let startYear = moment().subtract(20, "years").format("YYYY");
    for (let i = 0; i < 41; i++) {
      yearsArray.push(Number(startYear) + i);
    }
    setYearsArray(yearsArray);
  };

  useEffect(() => {
    functionForSettingYears();
  }, []);

  useEffect(() => {
    setLoading(true);
    getInterestRate(project_id)
      .then((res) => {
        const data = res && res.data && res.data.data;
        if (!data.error) {
          setInterestData(data.interestData);
          setLoading(false);

          // setFirstMonth(data.firstMonth);
          // const years = new Array(
          //   new Date().getFullYear() + 1 - data.firstMonth.year
          // )
          //   .fill()
          //   .map((d, i) => i + data.firstMonth.year);
          // setYears(years);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyError("Server Error");
      });
  }, [toggle, project_id]);

  useEffect(() => {
    const getInterestAllData = async () => {
      let array = [];
      yearsArray.map(async (year) => {
        months.map((month) => {
          let [interestRate] = interestData.filter(
            (e) =>
              moment(e.month).format("MMMM-YYYY") ===
              `${Object.values(month)[0]}-${year}`
          );
          let data = {
            month: `${year}-${String(
              Number(Object.keys(month)[0]) + 1
            ).padStart(2, "0")}`,
            _id: interestRate ? interestRate._id : "",
            rate: interestRate ? interestRate.interest_rate + " %" : null,
            // <p
            //   type="number"
            //   className="m-0 text-info fw-bold"
            //   onClick={() => {
            //     changeFocus(year, month);
            //   }}
            //   style={{ cursor: "pointer" }}
            // >
            //   {"ADD"}
            // </p>
          };
          array.push(data);
        });
      });
      setInterestArray(array);
    };
    getInterestAllData();
  }, [yearsArray, selectedYear, interestData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <Col className="" style={{ paddingLeft: "0" }}>
            <Card>
              <CardBody>
                <CardTitle className="d-flex align-items-center">
                  <p className="my-1">Monthly Interest Rate of year </p>
                  <Select
                    className="mx-4 w-25"
                    // values={selectedYear}
                    defaultValue={{ label: selectedYear, value: selectedYear }}
                    options={yearsArray.map((e) => {
                      return { label: e, value: e };
                    })}
                    onChange={(value) => {
                      setSelectedYear(value.value);
                    }}
                  ></Select>
                </CardTitle>
                <Table>
                  <thead>
                    <tr>
                      <th className="fw-bold">Month</th>
                      <th className="fw-bold text-center">Interest Rate</th>
                      <th className="fw-bold text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {interestArray
                      .filter(
                        (item) =>
                          moment(item.month).format("YYYY") == selectedYear
                      )
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{moment(item.month).format("MMM-YYYY")}</td>
                            <td className="text-center">
                              {item.rate ? item.rate : "-"}
                            </td>
                            {item.rate && (
                              <td
                                className="text-center p-0"
                                onClick={() => {
                                  setUpdateModal(true);
                                  setInterestId(item._id);
                                  setUpdatingMonth(item.month);
                                }}
                              >
                                <i
                                  className="mdi mdi-pencil"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                />
                              </td>
                            )}
                            {!item.rate && (
                              <td
                                className="text-center p-0"
                                onClick={() => {
                                  setAddModal(true);
                                  setUpdatingMonth(item.month);
                                }}
                              >
                                <i
                                  className="mdi mdi-plus"
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "24px",
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    {!loading && (
                      <tr key="">
                        <td></td>
                      </tr>
                    )}
                    {loading && (
                      <tr key="" style={{ height: "0px" }}>
                        <td colSpan={55} style={{ height: "0px" }}>
                          <Loader style={{ height: "3rem", width: "3rem" }} />
                        </td>
                      </tr>
                    )}
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {addModal && (
        <AddInterestRateModal
          modal={addModal}
          interestId={interestId}
          month={updatingMonth}
          project_id={project_id}
          toggle={() => {
            setToggle(!toggle);
          }}
          handleclosemodal={() => {
            setAddModal(!addModal);
          }}
        />
      )}
      {updateModal && (
        <UpdateInterestRateModal
          modal={updateModal}
          interestId={interestId}
          month={updatingMonth}
          toggle={() => {
            setToggle(!toggle);
          }}
          handleclosemodal={() => {
            setUpdateModal(!updateModal);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default MonthlyInterest;

const UpdateInterestRateModal = (props) => {
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [amount, setAmount] = useState(0);

  const handleChange = (e) => {
    setError(false);
    setError1(false);
    if (e.target.value > 10) {
      setError(true);
    }
    if (e.target.value < 0) {
      setError1(true);
    }
    setAmount(e.target.value);
  };

  const handleSubmit = (value) => {
    if (error || error1) {
      return;
    }
    const data = {
      interest_rate: amount,
    };
    updateInterestRate(props.interestId, data).then((res) => {
      props.toggle();
      props.handleclosemodal();
      notifySuccess("Interest Rate Updated Successfully");
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "20%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleclosemodal}>
          Update Interest Rate
        </ModalHeader>
        <ModalBody>
          <div style={{ height: "80px" }}>
            <label> Month</label>
            <input
              className="form-control"
              disabled={true}
              value={moment(props.month).format("MMMM YYYY")}
              placeholder="Select Updating Interest"
            ></input>
          </div>
          <div style={{ height: "100px" }}>
            <label>Enter Interest Rate</label>
            <input
              type="number"
              name="amount"
              value={amount}
              className="form-control"
              step={0.1}
              min={0}
              max={15}
              onChange={handleChange}
              onInput={(event) => {
                limitDecimalPlaces(event, 2);
              }}
            ></input>
            {error && (
              <div className="text-danger my-2 mx-1">
                <span> Interest Rate Must Be Less Than 10</span>
              </div>
            )}
            {error1 && (
              <div className="text-danger my-2 mx-1">
                <span> Interest Rate Must Be Greater Than 0</span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleSubmit}>Update Interest</Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const AddInterestRateModal = (props) => {
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [amount, setAmount] = useState(0);

  const handleChange = (e) => {
    setError(false);
    setError1(false);
    if (e.target.value > 10) {
      setError(true);
    }
    if (e.target.value < 0) {
      setError1(true);
    }
    setAmount(e.target.value);
  };
  const handleSubmit = () => {
    let dateObj = moment(props.month).format("YYYY-MM-DD");
    const data = {
      interest_rate: amount,
      month: dateObj,
    };
    if (error || error1) {
      return;
    }
    addInterestRate(props.project_id, data)
      .then((res) => {
        const data = res && res.data && res.data.data;
        if (!data.error) {
          props.toggle();
          props.handleclosemodal();
          notifySuccess(data.message);
        } else {
          notifyError(data.message);
        }
      })
      .catch((err) => {
        const data = err && err.data && err.data.error;
        notifyError(data.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "20%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleclosemodal}>
          Add Interest Rate
        </ModalHeader>
        <ModalBody>
          <div style={{ height: "80px" }}>
            <label> Month</label>
            <input
              className="form-control"
              disabled={true}
              value={moment(props.month).format("MMMM YYYY")}
              placeholder="Select Updating Interest"
            ></input>
          </div>
          <div style={{ height: "100px" }}>
            <label>Enter Interest Rate</label>
            <input
              type="number"
              name="amount"
              value={amount}
              className="form-control"
              step={0.1}
              min={0}
              max={15}
              onChange={handleChange}
              onInput={(event) => {
                limitDecimalPlaces(event, 2);
              }}
            ></input>
            {error && (
              <div className="text-danger my-2 mx-1">
                <span> Interest Rate Must Be Less Than 10</span>
              </div>
            )}
            {error1 && (
              <div className="text-danger my-2 mx-1">
                <span> Interest Rate Must Be Greater Than 0</span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleSubmit}>Add Interest</Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

import moment from "moment";
import React, { useState } from "react";

import ReactApexChart from "react-apexcharts";
import { Row } from "reactstrap";
import Loader from "../../components/Custom/Loader";
import { DownloadStatement } from "../../services/userService";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import Grid from "@material-ui/core/Grid";

const Chart = (props) => {
  const { isLoading } = props;
  const [date, setDate] = useState(moment().format("YYYY-MM"));
  const [statementLoading, setStatementLoading] = useState(false);
  let params = window.location.search;
  let id = new URLSearchParams(params).get("id");

  const statementDownload = () => {
    let query = `?project_id=${id}&date=${date}`;
    if (!statementLoading) {
      setStatementLoading(true);
      DownloadStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading(false);
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>Project Overview Chart</div>

        <Row className="mb-3 d-flex">
          <div className="col">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/yyyy"
                  views={["year", "month"]}
                  id="date-picker-inline"
                  value={date}
                  onChange={(date) => {
                    const formDate = moment(date).format("YYYY-MM");
                    if (moment(formDate, "YYYY-MM", true).isValid()) {
                      props.handleMonthChange(formDate);
                      setDate(formDate);
                    }
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </Row>
      </div>
      <div style={{ height: "350px" }}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Spinearea series={props.series} categories={props.categories} />
        )}
      </div>
    </>
  );
};

export default Chart;

const Spinearea = (props) => {
  const series = props.series;

  const options = {
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    series: [
      {
        name: "series1",
        data: [34, 40, 28, 52, 42, 109, 100],
      },
      {
        name: "series2",
        data: [32, 60, 34, 46, 34, 52, 41],
      },
    ],
    colors: ["#eeb902", "#F89254", "#45cb85", "#FF715B","#89CFF0"],
    xaxis: {
      type: "datetime",
      categories: props.categories,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd MMM",
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="350"
      className="apex-charts"
    />
  );
};

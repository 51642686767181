import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// users
import user4 from "../../../assets/images/users/man-1.png";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const user = useSelector((state) => state.Login.user);
  const name = user ? user.name : "user";
  const [menu, setMenu] = useState(false);
  const [logoutModal, setlogoutModal] = useState(false);
  const [username, setusername] = useState(name);
  const logout = () => {
    localStorage.clear();
    props.history.push("/user-login");
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title="Are you sure you want to logout?"
        danger
        showCancel
        confirmBtnText="Logout"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="success"
        onConfirm={logout}
        onCancel={() => setlogoutModal(false)}
      />
    );
  };
  useEffect(() => {
    setusername(name);
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [name]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">{username}</span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            className="dropdown-item text-danger"
            onClick={() => setlogoutModal(!logoutModal)}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <label>{"Logout"}</label>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {logoutModal ? sweetAlertFuction() : null}
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);

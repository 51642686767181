import { Form, Formik } from "formik";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import Select from "react-select";
import {
  addProjectGeneralExpense,
  getFormData,
} from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

const AddGeneralExpense = (props) => {
  const labels = props.labels;
  const [project, setProject] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
  ];
  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);

  const getAllProjects = () => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((project) => {
        return { label: project.name, value: project._id };
      });
      setProjectOptions(options);
      setProject(options);
    });
  };
  useEffect(() => {
    getAllProjects();
  }, []);

  let date = new Date();
  date.setHours(0, 0, 0, 0);

  let validationObject = {
    amount: Yup.number()
      .required(`Please Enter Amount`)
      .min(0, "Amount Must be Greater Than 0")
      .max(100000000, "Amount is too big"),
    payment_date: Yup.date().required(`Please Enter Date`),
    details: Yup.string()
      .trim()
      .max(100, "Details must be less than 100 characters"),
  };

  const validateFunction = () => {
    let errors = {};
    if (project.length === 0) {
      errors.project_id = "Please Select a Project";
    }
    if (paymentMode === "") {
      errors.payment_mode = "Please Select a Payment Mode";
    }

    return errors;
  };

  let initialValues = {
    project_id: project,
    payment_mode: paymentMode,
    amount: "",
    payment_date: moment().format("YYYY-MM-DD").toString(),
    details: "",
  };

  labels.map((label, index) => {
    initialValues = { ...initialValues, [label.item_name]: "" };
    if (label.is_required)
      return (validationObject = {
        ...validationObject,
        [label.item_name]: Yup.string()
          .required(`Please enter ${label.item_name}`)
          .max(
            label.item_type === "textarea" ? 100 : 50,
            `${label.item_name} must be less than ${
              label.item_type === "textarea" ? 100 : 50
            }`
          ),
      });
    else return null;
  });

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    let selectedProject = project.map((e) => e.value);
    let data = {
      ...values,
      project_id: selectedProject,
      payment_mode: paymentMode.value,
    };
    setLoading(true);
    addProjectGeneralExpense(data)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        notifySuccess("General Bill Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  //handles
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
            validate={validateFunction}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
              setFieldError,
            }) => (
              <Form>
                <div className="d-flex flex-column gap-3 mb-3">
                  <div>
                    <label>
                      Project <Required />
                    </label>
                    <Select
                      options={projectOptions}
                      name="project_id"
                      value={project.slice(0, project.length).map((i) => i)}
                      errors={errors}
                      isMulti={true}
                      touched={touched}
                      onChange={(e) => {
                        setProject(e);
                      }}
                      placeholder={"Select Project"}
                    />
                    {errors.project_id && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.project_id}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={`Enter Amount`}
                      name="amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldError("payment_mode", "");
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Payment Mode"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Payment Date <Required />
                    </label>
                    <CustomInput
                      type="date"
                      values={values}
                      name="payment_date"
                      max={moment().format("YYYY-MM-DD")}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Details</label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Details..."}
                      name="details"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  {labels.map((label, index) => {
                    return (
                      <div key={index}>
                        <label>
                          {label.item_name} {label.is_required && <Required />}
                        </label>
                        <CustomInput
                          type={label.item_type}
                          values={values}
                          name={label.item_name}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          style={{ color: "black" }}
                        />
                      </div>
                    );
                  })}
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Add
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddGeneralExpense;

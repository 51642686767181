import React, { useState } from "react";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import AddRawMaterial from "./RawMaterialExpense";
import PartyBills from "./PartyBills";
import GeneralBills from "./GeneralBills";
import StaffSalary from "./StaffSalary";
import { useSelector } from "react-redux";

const title = "Project Expenses";

const AddProjectExpenses = (props) => {
  const [page, setPage] = useState("material");

  const user = useSelector((state) => state.Login.user);
  const role = user ? user.role : "user";
  const general_bills = user ? user.general_bills : false;
  const raw_material_bills = user ? user.raw_material_bills : false;
  const party_payment = user ? user.party_payment : false;
  const staff_salary = user ? user.staff_salary : false;

  const currentComponent = () => {
    switch (page) {
      case "material":
        return <AddRawMaterial />;

      case "party":
        return <PartyBills />;

      case "general":
        return <GeneralBills />;
        case "staff":
          return <StaffSalary/>

      default:
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="page-title mb-0 font-size-18">{title}</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  Daily Entries / {title}
                </li>
              </ol>
            </div>
          </div>
          <div className="card">
            <Col md={8} lg={12}>
              <Row>
                <Col lg={12}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {(raw_material_bills || role === "superAdmin") && (
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPage("material");
                          }}
                          to="#"
                          className={page === "material" ? "active" : ""}
                        >
                          <span className="d-none d-sm-block">
                            Raw Material
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    {(general_bills || role === "superAdmin") && (
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPage("general");
                          }}
                          to="#"
                          className={page === "general" ? "active" : ""}
                        >
                          <span className="d-none d-sm-block">
                            General Bills
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    {(party_payment || role === "superAdmin") && (
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPage("party");
                          }}
                          to="#"
                          className={page === "party" ? "active" : ""}
                        >
                          <span className="d-none d-sm-block">
                            Party Paid Bills
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                     {(staff_salary || role === "superAdmin") && (
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPage("staff");
                          }}
                          to="#"
                          className={page === "staff" ? "active" : ""}
                        >
                          <span className="d-none d-sm-block">
                            Staff Salary
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </Col>
              </Row>
            </Col>
            {currentComponent()}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AddProjectExpenses;

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import Select from "react-select";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { addProjectExpense } from "../../services/userService.js";
import { getFormFields } from "../../services/adminService.js";
import moment from "moment";

const AddProjectExpense = (props) => {
  const [project, setProject] = useState(props.project);
  const [department, setDepartment] = useState(props.department);
  const [party, setParty] = useState(props.party);
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
  ];
  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);

  //?Initial Values For form
  let initialValues = {
    amount: 0,
    material_received_date: moment().format("YYYY-MM-DD").toString(),
    note: "",
    challan_no: "",
    material_receiver_name: "",
    work_details: "",
  };

  //? Validations For Form
  let validationObject = {
    material_received_date: Yup.date().required("Please Enter Date"),
    note: Yup.string().max(100, "Notes must be less than 100 characters"),
    challan_no: Yup.string().required("Please Enter Challan No"),
    material_receiver_name: Yup.string().required("Please Enter Receiver Name"),
    work_details: Yup.string().max(
      100,
      "Work details must be less than 100 characters"
    ),
    amount: Yup.number().required("Please Enter Amount"),
  };
  props.labels.map((data) => {
    initialValues = { ...initialValues, [data.item_name]: "" };
    if (data.is_required) {
      validationObject = {
        ...validationObject,
        [data.item_name]: Yup.string().required(
          `Please Enter ${data.item_name}`
        ),
      };
    }
    return null;
  });

  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    return errors;
  };

  useEffect(() => {
    const getSubDepartmentFields = () => {
      getFormFields("SUBDEPARTMENT-FORM&dept_id=" + department.value).then(
        async (res) => {
          let data = res.data && res.data.data;
          data = data.map((sub) => sub.item_name);
          setConfigData(data);
        }
      );
    };
    getSubDepartmentFields();
  }, [department.value]);

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = async (values) => {
    let data = {
      project_id: project ? project.value : "",
      department_id: department ? department.value : "",
      party_id: party ? party.value : "",
      payment_mode: paymentMode.value,
      ...values,
    };
    setLoading(true);
    addProjectExpense(data)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        notifySuccess("Raw Material Bill Added Successfully");
      })
      .catch((error) => {
        setLoading(false);
        notifyError(error.data.error.message);
      });
  };
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "50%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add Raw Material Bill
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            validate={validationFunction}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, resetForm }) => (
              <Form>
                <div className="d-flex flex-column gap-3 mb-3">
                  <div>
                    <label>Project</label>
                    <Input
                      type="text"
                      value={project.label}
                      readOnly
                      touched={touched}
                      errors={errors}
                      name="project_id"
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Department</label>
                    <Input
                      type="text"
                      value={department.label}
                      readOnly
                      name="department_id"
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Party</label>
                    <Input
                      type="text"
                      value={party?.label}
                      readOnly
                      name="party_id"
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={"Enter Amount"}
                      name="amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Project"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Material Received Date <Required />
                    </label>
                    <CustomInput
                      type="date"
                      values={values}
                      placeholder={"enter skhdfh"}
                      name="material_received_date"
                      max={moment().format("YYYY-MM-DD")}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Challan No./Bill No. <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Challan No"}
                      name="challan_no"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Material Receiver Name <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Material Receiver Name"}
                      name="material_receiver_name"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Work Details <Required />
                    </label>
                    <CustomInput
                      type="textarea"
                      values={values}
                      placeholder={"Enter Work Details"}
                      name="work_details"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>

                  {configData.length > 0 &&
                    configData.map((item, index) => {
                      return (
                        <div key={index}>
                          <label>{item} </label>
                          <CustomInput
                            type="text"
                            values={values}
                            placeholder={`Enter ${item}`}
                            name={item}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            style={{ color: "black" }}
                          />
                        </div>
                      );
                    })}
                </div>
                <div>
                  <label>Note</label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={"Enter Note"}
                    name="note"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Add
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddProjectExpense;

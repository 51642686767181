import api from "../api/api";
import * as url from "../api/url";

// Forms Configurations API
export const getFormFields = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.FORMS + "?form=" + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addFormFields = (data, form) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.FORMS + "?form=" + form, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateFormItem = (data, form, id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.FORMS + "?form=" + form + "&itemId=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const removeFormItem = (form, id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.FORMS + "?form=" + form + "&itemId=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDeptWithSubDept = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_DEPT_SUBDEPT)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllUsersList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_USER)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateUserAccess = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UPDATE_USER_ACCESS + "?user_id=" + id, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const createUser = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CREATE_USER, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteUser = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.DELETE_USER + "?user_id=" + id)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateUser = (id,data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UPDATE_USER + "?user_id=" + id,data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import Switch from "react-switch";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import { updateLand, getProjectData } from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import moment from "moment/moment.js";
import Select from "react-select";

const UpdateLand = (props) => {
  // const [project, setProject] = useState("");
  const defaultData = props.data;
  const [loading, setLoading] = useState(false);
  const [showBody, setShowBody] = useState(
    defaultData.project_id ? true : false
  );
  const [projectOptions, setProjectOptions] = useState([]);
  const [project, setProject] = useState({
    label: defaultData.project_id ? defaultData.project_name[0] : "",
    value: defaultData.project_id ? defaultData.project_id : null,
  });
  let validationObject = {
    land_owner_name: Yup.string()
      .trim()
      .required(`Please Enter Land Owner Name`),
    plot_number: Yup.string().required(`Please Enter Plot Number`),
    address: Yup.string().trim().required(`Please Enter Address`),
    rate_per_meter: Yup.number()
      .required(`Please Enter Land Rate Per Meter`)
      .min(0, "Please Enter Valid Meter"),
    area_in_meter: Yup.number()
      .required(`Please Enter Land Area In Meter`)
      .min(0, "Please Enter Valid Area"),
  };
  let initialValues = {
    land_owner_name: defaultData.land_owner_name,
    plot_number: defaultData.plot_number,
    address: defaultData.address,
    rate_per_meter: defaultData.rate_per_meter,
    area_in_meter: defaultData.area_in_meter,
    total_land_value: defaultData.total_land_value,
    project_id: defaultData.project_id,
  };
  const handleSwitchToggle = () => {
    setShowBody(!showBody);
  };
  const getAllProjects = () => {
    getProjectData().then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((project) => {
        return { label: project.name, value: project._id };
      });
      setProjectOptions(options);
    });
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  let landSchema = Yup.object().shape(validationObject);
  const validateFunction = () => {
    let errors = {};
    if (showBody && project.value === null) {
      errors.project_id = "Please Select a Project";
    }

    return errors;
  };
  const handleProjectChange = (value) => {
    if (showBody) {
      setProject(value);
    } else {
      project.value = null;
    }
  };
  const handleSubmit = (values) => {
    let data = {
      // project_id: project ? project.value : "",
      ...values,
    };

    if (!showBody) {
      data = {
        ...data,
        project_id: null,
      };
    } else {
      data = {
        ...data,
        project_id: project.value,
      };
    }

    setLoading(true);
    updateLand(defaultData._id, data)
      .then((res) => {
        props.handleCloseModal();
        setLoading(false);
        notifySuccess("Land Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>Update land</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={landSchema}
            validate={validateFunction}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => {
              const handleAreaChange = (event) => {
                const areaAmount = parseFloat(event.target.value);
                const ratePerMeter = parseFloat(values.rate_per_meter);
                const totalLandValue = areaAmount * ratePerMeter;

                setFieldValue("area_in_meter", areaAmount);
                setFieldValue("total_land_value", totalLandValue.toFixed(2));

                handleChange(event);
              };

              const handleRateChange = (event) => {
                const ratePerMeter = parseFloat(event.target.value);
                const areaAmount = parseFloat(values.area_in_meter);
                const totalLandValue = areaAmount * ratePerMeter;

                setFieldValue("rate_per_meter", ratePerMeter);
                setFieldValue("total_land_value", totalLandValue.toFixed(2));

                handleChange(event);
              };

              return (
                <Form>
                  <div style={{ height: "100px" }}>
                    <label>
                      Land Owner Name <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={`Enter Land Owner Name `}
                      name="land_owner_name"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Plot Number <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={`Enter Plot Number`}
                      name="plot_number"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Address <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={`Enter Address`}
                      name="address"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Rate (per meter) <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={`Please Enter Rate`}
                      name="rate_per_meter"
                      handleChange={handleRateChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Area (in Square Meter) <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={`Please Enter Land Area`}
                      name="area_in_meter"
                      handleChange={handleAreaChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>Total Land Value</label>
                    <CustomInput
                      type="text"
                      values={values}
                      name="total_land_value"
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      style={{ color: "black" }}
                    />
                  </div>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        alignContent: "stretch",
                      }}
                    >
                      <label style={{ marginBottom: "10px" }}>Project</label>
                      <Switch
                        onColor="#3b5de7"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        height={16}
                        width={32}
                        handleDiameter={14}
                        checked={showBody}
                        onChange={handleSwitchToggle}
                      />
                    </div>
                    {showBody && (
                      <div>
                        <Select
                          options={projectOptions}
                          name="project_id"
                          value={project}
                          onChange={handleProjectChange}
                          placeholder="Select Project"
                        />
                        {touched.project_id && errors.project_id && (
                          <div
                            style={{
                              fontSize: 14,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="text-left mt-1 text-danger"
                          >
                            {errors.project_id}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <ModalFooter className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      disabled={loading}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {" "}
                      Update
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateLand;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import Select from "react-select";
import {
  getFormData,
  getFormDataWithId,
  getProjectMaterialData,
} from "../../services/userService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { numDifferentiation } from "../../helpers/amount_helper";
import { PaginationFunction } from "../../components/Custom/Pagination";
import EmptyView from "../../components/Custom/EmptyView";
import PaymentList from "./Payment";
import { getFormFields } from "../../services/adminService";
import PartyDetails from "./PartyDetails";
import { Required } from "../../components/Custom/textinput";
import Loader from "../../components/Custom/Loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const departmentOptions = [];
const partyOptions = [];
const PartyExpense = () => {
  const [department, setDepartment] = useState("");
  const [party, setParty] = useState("");
  const [toggle, setToggle] = useState(false);
  const [partyDetails, setPartyDetails] = useState("");
  const [departmentModal, setDepartmentModal] = useState(false);
  const [configData, setConfigData] = useState([]);
  const [materialReceivedAmount, setMaterialReceivedAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [materialList, setMaterialList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataExportLoading,setDataExportLoading] = useState(false);
  const [allParties, setAllParties] = useState([]);
  let departmentId = department.value;
  let PartyId = party.value;
  const callToggle = () => setToggle(!toggle);
  const toggleDepartmentModal = () => setDepartmentModal(!departmentModal);
  useEffect(() => {
    setLoading(true);
    Promise.all([
      getFormData("PARTY"),
      getFormData("DEPARTMENT"),
      getFormFields("PARTY-FORM"),
    ])
      .then(([response1, response2, response3]) => {
        const data1 =
          response1 &&
          response1.data &&
          response1.data.data &&
          response1.data.data.data;
        setAllParties(data1);

        const data2 =
          response2 &&
          response2.data &&
          response2.data.data &&
          response2.data.data.data;
        departmentOptions.length = 0;
        data2.map((department) => {
          return departmentOptions.push({
            label: department.name,
            value: department._id,
          });
        });

        let data3 = response3 && response3.data && response3.data.data;
        data3 = data3.map((e) => e.item_name);
        setConfigData(data3);
      })
      .catch((error) => {
        console.log("getAllLinks error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getMaterialList = (party_id) => {
    const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}`;
    setLoading(true);
    getProjectMaterialData(party_id, departmentId, pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setMaterialReceivedAmount(res.data.data.totalBilledAmount);
        setMaterialList(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getPartyDetails = (party_id) => {
    setLoading(true);
    getFormDataWithId("PARTY", party_id)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setPartyDetails(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDepartmentChange = (value) => {
    setDepartment(value);
    partyOptions.length = 0;
    allParties.map((party) => {
      if (party.department_id._id === value.value) {
        return partyOptions.push({
          label: party.name,
          value: party._id,
        });
      } else {
        return null;
      }
    });
  };
  const handlePartyChange = (value) => {
    setParty(value);
  };
  const handleOnSearch = () => {
    getMaterialList(party.value);
    setShow(true);
  };
  useEffect(() => {
    party !== "" && getMaterialList(party.value);
    party !== "" && getPartyDetails(party.value);
  }, [activePage, pageDataCount, party.value]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const exportToExcel = () => {
    setDataExportLoading(true);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    getProjectMaterialData(PartyId,departmentId,"")
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        data = data.map((data) => {
          return {
            "Project Name": data.name,
            "Address": data.Address,
            "Total Amount": data.total_amount,
          };
        });

        const header = [
          "Project Name",
          "Address",
          "Total Amount",
        ]
        
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.sheet_add_aoa(ws, [header]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, "material_received_list" + fileExtension);
        setDataExportLoading(false);
      })
      .catch((err) => {
        setDataExportLoading(false);
      });
  }

  return (
    <>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Party Paid Bills</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / Party Paid Bills
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                {loading && <Loader />}
                <CardTitle>Party Paid Bills</CardTitle>
                <div className="d-flex justify-content-between">
                  <div style={{ width: "45%" }}>
                    <label>
                      Department <Required />
                    </label>
                    <Select
                      isLoading={loading}
                      options={departmentOptions}
                      onChange={handleDepartmentChange}
                    ></Select>
                  </div>
                  <div style={{ width: "45%" }}>
                    <label>
                      Party <Required />
                    </label>
                    <Select
                      options={partyOptions}
                      onChange={handlePartyChange}
                    ></Select>
                  </div>
                </div>
                <div className="mt-4">
                  <Button disabled={party === ""} onClick={handleOnSearch}>
                    Search
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
          {show && (
            <PartyDetails
              party={partyDetails}
              totalBilledAmount={materialReceivedAmount}
              totalPaidAmount={totalPaidAmount}
              configData={configData}
            />
          )}
          {show && (
            <PaymentList
              party={party}
              department={department}
              totalPaidAmount={(value) => {
                setTotalPaidAmount(value);
              }}
            />
          )}
          {show && (
            <div>
              <Card>
                <CardBody style={{ overflowX: "scroll" }}>
                  {loading && <Loader />}
                  <CardTitle style={{ display: "flex", justifyContent: "space-between" }}>
                    Material Received List
                    <div className="d-flex">
                      <Button
                        id="DataExport"
                        color="primary"
                        onClick={exportToExcel}
                        disabled={dataExportLoading}
                      >
                        {dataExportLoading && (
                          <Spinner
                            style={{ height: "0.9rem", width: "0.9rem" }}
                          />
                        )}
                        {!dataExportLoading && (
                          <i className="mdi mdi-download"></i>
                        )}
                      </Button>
                    </div>
                  </CardTitle>
                  <Table hover>
                    <thead>
                      <tr>
                        <th
                          className="text-center"
                          style={{ minWidth: "4rem" }}
                        >
                          <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "10rem" }}
                        >
                          <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "5rem" }}
                        >
                          <h6 style={{ fontWeight: "bold" }}>Address</h6>
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "5rem" }}
                        >
                          <h6 style={{ fontWeight: "bold" }}> Total Amount</h6>
                        </th>
                        {/* <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>
                            {" "}
                            Material Weight
                          </h6>
                        </th> */}

                        <th
                          className="text-center"
                          style={{ minWidth: "5rem" }}
                        >
                          <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {materialList?.length === 0 && (
                        <tr>
                          <td colSpan={12}>
                            <EmptyView
                              title={"Sorry!"}
                              discription={"No Data Found"}
                              bgcolor={"white"}
                            ></EmptyView>
                          </td>
                        </tr>
                      )}
                      {materialList?.map((item, index) => (
                        <GetDataList
                          data={item}
                          key={index}
                          index={index}
                          activePage={activePage}
                          pageDataCount={pageDataCount}
                          dataKey={configData}
                          toggle={callToggle}
                          departmentId={departmentId}
                          PartyId={PartyId}
                        />
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={10}>
                          <div
                            style={{
                              width: "fit-content",
                              display: "flex",
                              justifyItems: "flex-start",
                            }}
                          >
                            <PaginationFunction
                              activePage={activePage}
                              pageDataCount={pageDataCount}
                              totalCount={totalCount}
                              handlePageChange={handlePageChange}
                            />

                            <Input
                              style={{
                                width: "47px",
                                height: "45px",
                                marginLeft: "20px",
                                marginTop: "18px",
                                borderColor: "#00b3f4",
                                cursor: "pointer",
                              }}
                              name="pageid"
                              type="select"
                              onChange={(e) => handleChange(e)}
                            >
                              <option selected={pageDataCount === 5 ? 5 : ""}>
                                05
                              </option>
                              <option selected={pageDataCount === 10 ? 10 : ""}>
                                10
                              </option>
                              <option selected={pageDataCount === 20 ? 20 : ""}>
                                20
                              </option>
                              <option selected={pageDataCount === 30 ? 30 : ""}>
                                30
                              </option>
                            </Input>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </div>
          )}
        </Row>
      </div>
    </>
  );
};

export default PartyExpense;

const GetDataList = (props) => {
  const { data, index } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.name}</td>
        <td className="text-center">{data.Address}</td>
        <td className="text-center">{numDifferentiation(data.total_amount)}</td>
        <td className="text-center">
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              <Tooltip
                id="tooltip-bottom"
                className="font-family-class font-12 fw-normal line-height-17 text-white"
              >
                Material Details
              </Tooltip>
            }
          >
            <Link
              to={`/material-expenses?project_id=${data._id}&department_id=${props.departmentId}&party_id=${props.PartyId}`}
            >
              <i
                id="filters"
                className="mdi mdi-open-in-new font-size-20"
                style={{ marginRight: 10, cursor: "pointer" }}
              />
            </Link>
          </OverlayTrigger>
        </td>
      </tr>
    </>
  );
};

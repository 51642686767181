import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
} from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
// import AddConfiguration from "./AddConfiguration";
// import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import { notifySuccess } from "../../components/Custom/notification";
import {
  cancelUnitBooking,
  getFormData,
  getUnitDetails,
  getUnitIncomeById,
} from "../../services/userService";
import Select from "react-select";
import UnitDetails from "./UnitDetails";
import moment from "moment";
import { PaginationFunction } from "../../components/Custom/Pagination";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Custom/Loader";
import { numDifferentiation } from "../../helpers/amount_helper";

const title = "Booking Cancellation";
const CancelUnitBooking = (props) => {
  const [configData, setConfigData] = useState([]);
  const [project, setProject] = useState("");
  const [unit, setUnit] = useState("");
  const [unitDetails, setUnitDetails] = useState("");
  const [unitIncome, setUnitIncome] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const getAllProjects = () => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((project) => {
        return { label: project.name, value: project._id };
      });
      setProjectOptions(options);
    });
  };
  const getConfigData = () => {
    getFormFields("UNIT-FORM").then(async (res) => {
      let data = res && res.data && res.data.data;
      let result = await data.map((element) => element.item_name);
      setConfigData(result);
    });
  };
  useEffect(() => {
    getAllProjects();
    getConfigData();
  }, []);

  const getAllUnits = (value) => {
    getFormData("UNIT&project_id=" + value.value).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((unit) => {
        return { label: unit.name, value: unit._id };
      });
      setUnitOptions(options);
    });
  };
  const getUnitInfo = (unit_id) => {
    setLoading(true);
    getUnitDetails(unit_id)
      .then((res) => {
        let [data] = res && res.data && res.data.data && res.data.data.data;
        setUnitDetails(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getUnitPayments = (search) => {
    setLoading(true);
    const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}`;
    getUnitIncomeById(search, pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setTotalCount(res.data.data.totalCount);
        setUnitIncome(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [toggle]);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  //? handles
  const handleProjectChange = (value) => {
    setProject(value);
    setUnit("");
    getAllUnits(value);
  };
  const handleUnitChange = (value) => {
    setUnit(value);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  useEffect(() => {
    if (unit !== "") {
      setUnitIncome([]);
      getUnitPayments(unit.value);
    }
  }, [activePage, pageDataCount]);
  const handleOnSearch = () => {
    if (unit !== "") {
      setSearch(true);
      setUnitDetails("");
      setUnitIncome([]);
      getUnitInfo(unit.value);
      getUnitPayments(unit.value);
    }
  };

  const handleOnCancel = () => {
    setLoading(true);
    cancelUnitBooking(unitDetails._id)
      .then((res) => {
        setSearch(false);
        setCancel(!cancel);
        setLoading(false);
        notifySuccess("Booking Canceled");
      })
      .catch((error) => {
        setLoading(false);
        setCancel(!cancel);
      });
  };
  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title="Are you sure you want to cancel booking?"
        danger
        showCancel
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="dark"
        onConfirm={handleOnCancel}
        onCancel={() => {
          setSearch(false);
          setCancel(!cancel);
        }}
        disabled={loading}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <br />
                  <br />
                </CardTitle>
                <Row>
                  <div className="d-flex justify-content-between w-100">
                    <div style={{ height: "100px", width: "45%" }}>
                      <label>Project</label>
                      <Select
                        options={projectOptions}
                        name="project_id"
                        value={project}
                        onChange={(value) => {
                          handleProjectChange(value);
                        }}
                        placeholder={"Select Project"}
                      />
                    </div>
                    <div style={{ height: "100px", width: "45%" }}>
                      <label>Unit</label>
                      <Select
                        options={unitOptions}
                        name="unit_id"
                        value={unit}
                        onChange={(value) => {
                          handleUnitChange(value);
                        }}
                        placeholder={"Select Unit"}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button onClick={handleOnSearch}>Search</Button>
                  </div>
                </Row>
              </CardBody>
            </Card>

            {search && (
              <>
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>Unit Name</h6>
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>
                              Customer Name
                            </h6>
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>
                              Booking Amount
                            </h6>
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>Paid Amount</h6>
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>
                              Outstanding Amount
                            </h6>
                          </th>
                          <th className="text-center" style={{ width: "10%" }}>
                            <h6 style={{ fontWeight: "bold" }}>
                              View Unit Details
                            </h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading && (
                          <tr>
                            <td className="text-center">
                              {unitDetails.project_id
                                ? unitDetails.project_id.name
                                : "-"}
                            </td>
                            <td className="text-center">
                              {unitDetails.name || "-"}
                            </td>
                            <td className="text-center">
                              {unitDetails?.customer_id?.name || "-"}
                            </td>
                            <td className="text-center">
                              {numDifferentiation(
                                unitDetails?.booking_amount
                              ) || "-"}
                            </td>
                            <td className="text-center">
                              {numDifferentiation(unitDetails?.paid_amount) ||
                                "-"}
                            </td>
                            <td className="text-center">
                              {numDifferentiation(
                                unitDetails.booking_amount -
                                  unitDetails.paid_amount
                              ) || "-"}
                            </td>
                            <td className="text-center">
                              <i
                                style={{ cursor: "pointer" }}
                                className="fa fa-info"
                                onClick={showAddConfigModal}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr key="">
                          <td colSpan={55}>{loading && <Loader />}</td>
                        </tr>
                      </tfoot>
                    </Table>
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => setCancel(!cancel)}
                        disabled={unitDetails.customer_id ? false : true}
                      >
                        Cancel Booking
                      </Button>
                    </div>
                    {cancel && sweetAlertFuction()}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle
                      className="h3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Unit Payment Details</span>
                    </CardTitle>
                    {addModal && (
                      <UnitDetails
                        data={unitDetails}
                        configData={configData}
                        modal={addModal}
                        handleCloseModal={showAddConfigModal}
                      />
                    )}
                    <Row>
                      <Table hover>
                        <thead>
                          <tr>
                            <th
                              className="text-center"
                              style={{ width: "10%" }}
                            >
                              <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>Project</h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>Unit No</h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>Customer</h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>
                                Payment Mode
                              </h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>
                                Payment Date
                              </h6>
                            </th>
                            <th className="text-center">
                              <h6 style={{ fontWeight: "bold" }}>Note</h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading && unitIncome?.length === 0 && (
                            <tr>
                              <td colSpan={12}>
                                <EmptyView
                                  title={"Sorry!"}
                                  discription={"No Payments Found"}
                                  bgcolor={"white"}
                                  icon={"mdi mdi-link-variant"}
                                ></EmptyView>
                              </td>
                            </tr>
                          )}
                          {unitIncome?.map((item, index) => (
                            <RenderLinks
                              data={item}
                              key={index}
                              index={index}
                              title={title}
                              activePage={activePage}
                              pageDataCount={pageDataCount}
                              toggle={callToggle}
                            />
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={10}>
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "flex",
                                  justifyItems: "flex-start",
                                }}
                              >
                                <PaginationFunction
                                  activePage={activePage}
                                  pageDataCount={pageDataCount}
                                  totalCount={totalCount}
                                  handlePageChange={handlePageChange}
                                />

                                <Input
                                  style={{
                                    width: "47px",
                                    height: "45px",
                                    marginLeft: "20px",
                                    marginTop: "18px",
                                    borderColor: "#00b3f4",
                                    cursor: "pointer",
                                  }}
                                  name="pageid"
                                  type="select"
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option>05</option>
                                  <option>10</option>
                                  <option>20</option>
                                  <option>30</option>
                                </Input>
                              </div>
                              {loading && <Loader />}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </Row>
                  </CardBody>
                </Card>
              </>
            )}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default withRouter(CancelUnitBooking);

const RenderLinks = (props) => {
  const { data, index } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.project}</td>
        <td className="text-center">{data.unit}</td>
        <td className="text-center">{data.customer}</td>
        <td className="text-center">
          {numDifferentiation(data.amount.toFixed(2))}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{data.note || "-"}</td>
      </tr>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import { getDepartmentOverview } from "../../services/userService";
import { numDifferentiation } from "../../helpers/amount_helper";
import { Link } from "react-router-dom";
import Loader from "../../components/Custom/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RawMaterialExpense = (props) => {
  const [departmentData, setDepartmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const departmentOverviewData = () => {
      setIsLoading(true);
      let query = `?project_id=${props.projectId}`;
      getDepartmentOverview(query)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          setDepartmentData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
    departmentOverviewData();
  }, [props.projectId]);

  return (
    <>
      <Card>
        <CardBody style={{ overflowX: "scroll" }}>
          {isLoading && <Loader />}
          <CardTitle
            className="h3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {" "}
            Department Overview
            <div className="d-flex">
              <Button
                onClick={() => {
                  props.handleChangeDisplay("Party");
                }}
              >
                Party Overview
              </Button>
            </div>
          </CardTitle>
          <Table hover>
            <thead>
              <tr>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}> Department Name</h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}> Material Weight</h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}>
                    {" "}
                    Total Material Received Amount
                  </h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}> Details</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                departmentData.map((department, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{department.name}</td>
                      <td className="text-center">
                        {department.items.map((item, index) => (
                          <div key={index}>
                            <p>
                              {item.itemNames}:
                              <span
                                style={{ color: "black",  }}
                              >
                                {" "}
                                {numDifferentiation(item.Quantity)}
                              </span>
                            </p>
                          </div>
                        ))}
                      </td>
                      <td className="text-center">
                        {numDifferentiation(department.totalExpense.toFixed(2))}
                      </td>
                      <td className="text-center">
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id="tooltip-bottom"
                              className="font-family-class font-12 fw-normal line-height-17 text-white"
                            >
                              Expenses
                            </Tooltip>
                          }
                        >
                          <Link
                            to={`dashboard/department-expense?department_id=${department._id}&department=${department.name}&project_id=${props.projectId}`}
                          >
                            <i
                              id="filters"
                              className="mdi mdi-open-in-new font-size-20"
                              style={{ marginRight: 10, cursor: "pointer" }}
                            />
                          </Link>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default RawMaterialExpense;

import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { STORAGEKEY } from "../../components/Constant";
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  settings,
  administration,
  path,
  ...rest
}) => (
  <Route
    // {...rest}

    render={(props) => {
      let error = false;

      //? define variable for permissions
      const customer_revenue = localStorage.getItem(
        STORAGEKEY.CUSTOMER_REVENUE
      );
      const customer_refund = localStorage.getItem(
        STORAGEKEY.CUSTOMER_REFUND
      );
      const role = localStorage.getItem(STORAGEKEY.ROLE);
      const staff_salary = localStorage.getItem(STORAGEKEY.STAFF_SALARY);
      const management = localStorage.getItem(STORAGEKEY.MANAGEMENT);
      const party_payment = localStorage.getItem(STORAGEKEY.PARTY_PAYMENT);
      const general_bills = localStorage.getItem(STORAGEKEY.GENERAL_BILL);
      const raw_material_bills = localStorage.getItem(
        STORAGEKEY.RAW_MATERIAL_BILL
      );
      const cancel_booking = localStorage.getItem(STORAGEKEY.CANCEL_BOOKING);

      //? handling user has permission or not
      if (isAuthProtected && !localStorage.getItem(STORAGEKEY.ACCESSTOKEN)) {
        error = true;
      }

      //? expenses permissions
      if (
        ((path === "/unit-incomes" && customer_revenue !== "true") ||
        (path === "/customer-refund" && customer_refund !== "true") ||
          (path === "/cancel-booking" && cancel_booking !== "true") ||
          (path === "/material-expenses" && raw_material_bills !== "true") ||
          (path === "/general-expenses" && general_bills !== "true") ||
          (path === "/party-expenses" && party_payment !== "true") ||
          (path === "/employee-salary" && staff_salary !== "true")) &&
        role !== "superAdmin"
      ) {
        localStorage.clear();
        error = true;
      }

      //? configuration permissions
      if (settings && role !== "superAdmin") {
        localStorage.clear();
        error = true;
      }

      //? management permissions
      if (management !== "true" && administration && role !== "superAdmin") {
        localStorage.clear();
        error = true;
      }
      
      if (error) {
        return (
          <Redirect
            to={{ pathname: "/user-login", state: { from: props.location } }}
          />
        );
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;

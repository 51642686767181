import React, { useEffect } from "react";
import {
  Button,
  Table,
  Card,
  CardBody,
  CardTitle,
  Input,
  Row,
} from "reactstrap";
import { useState } from "react";
import { numDifferentiation } from "../../helpers/amount_helper";
import Chart from "./EmployeeChart";
import UpdateEmployeeSalary from "../../pages/Employee/UpdateEmployeeSalary";
import AddEmployeeSalary from "../../pages/Employee/AddEmployeeSalary";
import EmptyView from "../../components/Custom/EmptyView";
import {
  getDataForEmployeeChart,
  getEmloyeePayments,
  deleteEmployeeSalary,
  getEmployeeData,
} from "../../services/userService";
import { notifySuccess } from "../../components/Custom/notification";
import Loader from "../../components/Custom/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment/moment";
import { PaginationFunction } from "../../components/Custom/Pagination";

const title = "Payment";
const EmployeeData = () => {
  let params = window.location.search;
  let employee_id = new URLSearchParams(params).get("employee_id");
  const [month, setMonth] = useState(moment().format("YYYY-MM-DD"));
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [addModal, setAddModal] = useState(false);
  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };
  useEffect(() => {
    setIsLoading(true);
    getDataForEmployeeChart(`?employee_id=${employee_id}&date=${month}`).then(
      (res) => {
        let data = res && res.data && res.data.data;
        let series = [
          {
            name: "Staff Salary",
            data: Object.values(data.staffSalaryData),
          },
        ];
        setSeries(series);
        setCategories(Object.keys(data.staffSalaryData));
        setIsLoading(false);
      }
    );
  }, [employee_id, month]);
  const fetchLandData = () => {
    setLoading(true);

    getEmployeeData(employee_id)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setEmployeeData(data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getAllEmployeePaymentData = () => {
    setLoading(true);
    const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&search=${search}`;
    getEmloyeePayments(employee_id, pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setPaymentData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllEmployeePaymentData();
    fetchLandData();
  }, [activePage, pageDataCount, search, toggle]);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Employee Details</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Employee</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Card>
                <CardBody>
                  <CardTitle>Employee Details</CardTitle>
                  <Table hover>
                    <thead>
                      <tr>
                        <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>Employee Name</h6>
                        </th>
                        <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>Phone No</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">{employeeData?.name}</td>
                        <td className="text-center">
                          {employeeData["Phone No"]}
                        </td>
                        <td
                          style={{ maxWidth: "10rem" }}
                          className="text-center"
                        >
                          {employeeData.item_name}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
            <Card>
              <CardBody>
                <Chart
                  series={series}
                  categories={categories}
                  handleMonthChange={(month) => {
                    setMonth(month);
                  }}
                  isLoading={isLoading}
                />
              </CardBody>
            </Card>

            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Employee Payment List</span>
                  <Button
                    type="submit"
                    name="btn"
                    className="custombtn mr-2"
                    style={{
                      marginRight: 10,
                    }}
                    color="primary"
                    onClick={showAddConfigModal}
                  >
                    Add
                  </Button>
                  {addModal && (
                    <AddEmployeeSalary
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                    />
                  )}
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentData.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Payments Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {paymentData.length > 0 &&
                      paymentData.map((item, index) => {
                        return (
                          <GetDataList
                            data={item}
                            key={index}
                            index={index}
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            toggle={callToggle}
                          ></GetDataList>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EmployeeData;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteEmployeeSalary(data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("Salary Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this payment ?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {numDifferentiation(data.amount.toFixed(2))}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ maxWidth: "10rem" }}>
          {data.note}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateEmployeeSalary
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              data={props.data}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

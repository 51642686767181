import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Table, Input } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import {
  deleteProjectExpense,
  getProjectGeneralExpense,
} from "../../services/userService";
import { PaginationFunction } from "../../components/Custom/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import moment from "moment";
import { numDifferentiation } from "../../helpers/amount_helper";
import UpdateGeneralExpense from "../GeneralExpense/UpdateGeneralExpense";
import Loader from "../../components/Custom/Loader";

const title = "General Bills";
const form = "GENERAL-EXPENSE-FORM";
const GeneralExpsLedger = (props) => {
  const [configData, setConfigData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const getAllFields = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("----error----", err);
      });
  };

  useEffect(() => {
    getAllFields();
  }, []);

  const getAllProjectExpense = () => {
    setLoading(true);

    const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}&date=${props.date}`;
    getProjectGeneralExpense(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllProjectExpense();
  }, [toggle, activePage, pageDataCount, props.date]);

  return (
    <React.Fragment>
      <div>
        <Card>
          <CardBody style={{ overflowX: "scroll" }}>
            <CardTitle>{title}</CardTitle>
            {loading && <Loader />}
            <Table hover>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: "4rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "10rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Project</h6>
                  </th>

                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Details</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                  </th>
                  {configData.map((config, index) => {
                    return (
                      <th
                        className="text-center"
                        style={{ maxWidth: "5rem" }}
                        key={index}
                      >
                        <h6 style={{ fontWeight: "bold" }}>
                          {config.item_name}
                        </h6>
                      </th>
                    );
                  })}
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && projectsData && projectsData.length === 0 && (
                  <tr>
                    <td colSpan={12}>
                      <EmptyView
                        title={"Sorry!"}
                        discription={"No Data Found"}
                        bgcolor={"white"}
                      ></EmptyView>
                    </td>
                  </tr>
                )}
                {!loading &&
                  projectsData &&
                  projectsData.map((item, index) => (
                    <GetDataList
                      data={item}
                      key={index}
                      index={index}
                      activePage={activePage}
                      pageDataCount={pageDataCount}
                      configData={configData}
                      toggle={callToggle}
                    />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={10}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "flex",
                        justifyItems: "flex-start",
                      }}
                    >
                      <PaginationFunction
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        totalCount={totalCount}
                        handlePageChange={handlePageChange}
                      />

                      <Input
                        style={{
                          width: "47px",
                          height: "45px",
                          marginLeft: "20px",
                          marginTop: "18px",
                          borderColor: "#00b3f4",
                          cursor: "pointer",
                        }}
                        name="pageid"
                        type="select"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected={pageDataCount === 5 ? 5 : ""}>
                          05
                        </option>
                        <option selected={pageDataCount === 10 ? 10 : ""}>
                          10
                        </option>
                        <option selected={pageDataCount === 20 ? 20 : ""}>
                          20
                        </option>
                        <option selected={pageDataCount === 30 ? 30 : ""}>
                          30
                        </option>
                      </Input>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default GeneralExpsLedger;

export const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteProjectExpense(data._id)
      .then((res) => {
        handleShowDeleteModal();
        props.toggle();
        notifySuccess("General Bill Deleted Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {" "}
          {data.project_id.map((project, index) => {
            if (index + 1 < data.project_id.length) {
              return project.name + ",";
            } else {
              return project.name;
            }
          })}
        </td>

        <td className="text-center">{data.details}</td>
        <td className="text-center">
          {numDifferentiation(
            !Number.isInteger(data.amount) && typeof data.amount === "number"
              ? data.amount.toFixed(2)
              : data.amount
          )}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        {props.configData.map((config, index) => {
          return (
            <td
              className="text-center"
              key={index}
              style={{ maxWidth: "10rem" }}
            >
              {data[config.item_name] ? data[config.item_name] : "-"}
            </td>
          );
        })}
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateGeneralExpense
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              labels={props.configData}
              data={props.data}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { updateUser } from "../../services/adminService.js";

const UpdateUser = (props) => {
  const defaultData = props.data;
  const [state, setState] = useState({
    management: defaultData.management,
    customer_revenue: defaultData.customer_revenue,
    general_bills: defaultData.general_bills,
    party_payment: defaultData.party_payment,
    raw_material_bills: defaultData.raw_material_bills,
    staff_salary: defaultData.staff_salary,
    cancel_booking: defaultData.cancel_booking,
    dashboards: defaultData.dashboards,
    lands:defaultData.lands,
  });

  let validationObject = {
    name: Yup.string()
      .trim()
      .required(`Please Enter User Name`)
      .max(25, "Maximum 25 Character Allowed"),
    email: Yup.string()
      .trim()
      .email("Enter valid e-mail")
      .required("Please enter e-mail address"),
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password must be at least 8 character")
      .max(16, "Maximum Password Length is 16 character"),
    confirm_password: Yup.string()
      .required("Please enter confirm password")
      .min(8, "Password must be at least 8 character"),
  };
  let initialValues = {
    name: defaultData.name,
    email: defaultData.email,
    phoneNumber: defaultData.phoneNumber,
    password: defaultData.password,
    confirm_password: defaultData.password,
    management: defaultData.management,
    dashboards: defaultData.dashboards,
    lands:defaultData.lands,
    dashboard_projects: defaultData.dashboard_projects
  };

  const handleRadioChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };
  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    const data = { ...values, ...state, role: "user" };
    if (values.password !== values.confirm_password) {
      return setFieldError(
        "confirm_password",
        "Password And Confirm Password Must Be Same"
      );
    }
    updateUser(defaultData._id, data)
      .then((res) => {
        props.handleCloseModal();
        notifySuccess("User Updated Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "40%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>Update User</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setValues,
              resetForm,
            }) => (
              <Form>
                {!props.permissions && (
                  <>
                    {" "}
                    <div style={{ height: "100px" }}>
                      <label>
                        {" "}
                        Name <Required />
                      </label>
                      <CustomInput
                        type="text"
                        values={values}
                        placeholder={`Enter User Name`}
                        name="name"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                    <div style={{ height: "100px" }}>
                      <label>
                        {" "}
                        Email <Required />
                      </label>
                      <CustomInput
                        type="email"
                        values={values}
                        placeholder={`Enter Email`}
                        name="email"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                    <div style={{ height: "100px" }}>
                      <label>
                        Password <Required />
                      </label>
                      <CustomInput
                        type="password"
                        values={values}
                        placeholder={`Enter Password`}
                        name="password"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                    <div style={{ height: "100px" }}>
                      <label>
                        Confirm Password <Required />
                      </label>
                      <CustomInput
                        type="password"
                        values={values}
                        placeholder={`Confirm Password Must Be Same As Password`}
                        name="confirm_password"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                  </>
                )}
                <div className="">
                  <label style={{ fontWeight: "bold" }}>PERMISSIONS :</label>
                  <div>
                    <label>Management Permissions:</label>
                    <div>
                      <Input
                        className="form-check-input mx-2"
                        type="checkbox"
                        name="management"
                        value={state.management}
                        checked={state.management}
                        onChange={handleRadioChange}
                      />
                      <label style={{ width: "9rem" }}>Management</label>
                    </div>
                  </div>
                  <div>
                    <label>Expense Permissions:</label>
                    <div className="permissions-menu">
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="customer_revenue"
                          value={state.customer_revenue}
                          checked={state.customer_revenue}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Customer Revenue
                        </label>
                      </div>
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="general_bills"
                          value={state.general_bills}
                          checked={state.general_bills}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>General Bills</label>
                      </div>
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="raw_material_bills"
                          value={state.raw_material_bills}
                          checked={state.raw_material_bills}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Raw Material Bill
                        </label>
                      </div>
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="party_payment"
                          value={state.party_payment}
                          checked={state.party_payment}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Party Paid Bills
                        </label>
                      </div>
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="cancel_booking"
                          value={state.cancel_booking}
                          checked={state.cancel_booking}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Booking Cancelation
                        </label>
                      </div>
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="staff_salary"
                          value={state.staff_salary}
                          checked={state.staff_salary}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>Staff Salary</label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="dashboards"
                          value={state.dashboards}
                          checked={state.dashboards}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>Dashboards</label>
                      </div>
                      <div>
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="lands"
                          value={state.lands}
                          checked={state.lands}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>Lands</label>
                      </div>
                    </div>
                  </div>
                  
                  {state.dashboards && (
                    <div>
                      <label>Project Permissions:</label>
                      <div className="">
                        {props.projectsData &&
                          props.projectsData.length > 0 &&
                          props.projectsData.map((project, index) => (
                            <div key={index} className="d-flex">
                              <Input
                                className="form-check-input mx-2"
                                type="checkbox"
                                name="dashboard_projects"
                                value={project._id}
                                checked={values.dashboard_projects.includes(
                                  project._id
                                )}
                                onChange={(e) => {
                                  const projectId = e.target.value;
                                  const isChecked = e.target.checked;
                                  if (isChecked) {
                                    // add the projectId to the dashboard_projects array
                                    setValues((values) => ({
                                      ...values,
                                      dashboard_projects: [
                                        ...values.dashboard_projects,
                                        projectId,
                                      ],
                                    }));
                                  } else {
                                    // remove the projectId from the dashboard_projects array
                                    setValues((values) => ({
                                      ...values,
                                      dashboard_projects:
                                        values.dashboard_projects.filter(
                                          (id) => id !== projectId
                                        ),
                                    }));
                                  }
                                }}
                              />
                              <label>{project.name}</label>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateUser;

import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
  Tooltip,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
} from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import {
  deleteCustomerRefund,
  getAllCustomerRefund,
} from "../../services/userService";
// import AddUnitIncome from "./AddUnitIncome";
import { PaginationFunction } from "../../components/Custom/Pagination";
// import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import UpdateRefund from "./UpdateRefund";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import moment from "moment/moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../../components/Custom/Loader";
import { numDifferentiation } from "../../helpers/amount_helper";
import AddCustomerRefund from "./AddCustomerRefund";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const form = "UNIT-FORM";
const title = "Customer Refund";
const CustomerRefund = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [search, setSearch] = useState("");
  const [toolTip, setToolTip] = useState(false);
  const [dataExportLoading, setDataExportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [month, setMonth] = useState(new Date());
  const [customDateModal, setCustomDateModal] = useState(false)
  const [customMonthModal, setCustomMonthModal] = useState(false)

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };
  const getAllUnitIncomeData = () => {
    setLoading(true);
    const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}&search=${search}`;
    getAllCustomerRefund(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllUnitIncomeData();
  }, [toggle, activePage, pageDataCount, search]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("----error----", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllLinks();
  }, []);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  const handleCustomDateToggle = () => {
    setCustomDateModal(!customDateModal)
    setStartDate(new Date())
    setEndDate(new Date())
  }

  const handleCustomMonthToggle = () => {
    setCustomMonthModal(!customMonthModal)
    setMonth(new Date())
  }

  const handleSelectDateRange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const exportToExcel = () => {
    let query = '';
    if (dataExportLoading) {
      return;
    }
    setDataExportLoading(true);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    setLoading(true);

    if (selectedValue == "customDate") {
      const start = moment(startDate).format('YYYY-MM-DD')
      const end = moment(!endDate ? startDate : endDate).format('YYYY-MM-DD')

      query += `?start_date=${start}&end_date=${end}`
    } else if (selectedValue == "customMonth") {
      const date = moment(month).format('YYYY-MM-DD')

      query += `?month=${date}`
    }

    getAllCustomerRefund(query)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        data = data.map((data) => {
          return {
            "Project Name": data.project,
            "Unit": data.unit,
            "Customer Name": data.customer,
            "Amount": data.amount,
            "Payment Mode": data.payment_mode,
            "Payment Date": moment(data.payment_date).format("DD-MM-YYYY"),
            "Note": data.note,
          };
        });

        const header = [
          "Project Name",
          "Unit",
          "Customer Name",
          "Amount",
          "Payment Mode",
          "Payment Date",
          "Note",
        ]

        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.sheet_add_aoa(ws, [header]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, "customer_refund" + fileExtension);
        setDataExportLoading(false);
        if (selectedValue == "customDate") {
          handleCustomDateToggle()
        } else if (selectedValue == "customMonth") {
          handleCustomMonthToggle()
        }
        setLoading(false);
      })
      .catch((err) => {
        setDataExportLoading(false);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by Project/Customer/Unit Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        margin: "0px 10px",
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                    <div className="d-flex justify-content-center align-items-center">
                      <Dropdown isOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)} direction="down">
                        <DropdownToggle
                          caret
                          id="dataExport"
                          color="primary"
                          style={{
                            marginRight: "10px",
                          }}
                          disabled={dataExportLoading}
                        >
                          {dataExportLoading && (
                            <Spinner
                              style={{ height: "0.9rem", width: "0.9rem" }}
                            />
                          )}
                          {!dataExportLoading && (
                            <i className="mdi mdi-download"></i>
                          )}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem onClick={() => { setSelectedValue("customDate"); setCustomDateModal(true) }}>Custom Date</DropdownItem>
                          <DropdownItem onClick={() => { setSelectedValue("customMonth"); setCustomMonthModal(true) }}>Custom Month</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  {
                    selectedValue == "customDate" && (
                      <Modal isOpen={customDateModal} size="sm" centered={true}>
                        <ModalHeader toggle={handleCustomDateToggle}>Select Custom Date</ModalHeader>
                        <ModalBody className="text-center">
                          <DatePicker
                            dateFormat="YYYY-MM-DD"
                            selected={startDate}
                            onChange={handleSelectDateRange}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={startDate
                              ? moment(startDate).add(3, 'months').toDate()
                              : null}
                            selectsRange
                            inline
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" disabled={dataExportLoading} onClick={exportToExcel}>
                            {dataExportLoading ? "Loading..." : "Export"}
                          </Button>{' '}
                          <Button color="secondary" onClick={handleCustomDateToggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )
                  }
                  {
                    selectedValue == "customMonth" && (
                      <Modal isOpen={customMonthModal} size="sm" centered={true}>
                        <ModalHeader toggle={handleCustomMonthToggle}>Select Custom Month</ModalHeader>
                        <ModalBody className="text-center">
                          <DatePicker
                            showIcon
                            selected={month}
                            onChange={(date) => setMonth(date)}
                            inline
                            showMonthYearPicker
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" disabled={dataExportLoading} onClick={exportToExcel}>
                            {dataExportLoading ? "Loading..." : "Export"}
                          </Button>{' '}
                          <Button color="secondary" onClick={handleCustomMonthToggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )
                  }
                  {addModal && (
                    <AddCustomerRefund
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                      form={form}
                      labels={configData}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Unit</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Customer Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData && projectsData.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Data Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        dataKey={configData}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            value={pageDataCount}
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default CustomerRefund;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteCustomerRefund(data._id)
      .then((res) => {
        handleShowDeleteModal();
        props.toggle();
        notifySuccess("Refund Deleted Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this Customer Refund?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.project}</td>
        <td className="text-center">{data.unit}</td>
        <td className="text-center">{data.customer}</td>
        <td className="text-center">
          {data.amount ? numDifferentiation(data.amount.toFixed(2)) : "-"}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ maxWidth: "10rem" }}>
          {data.note ? data.note : "-"}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateRefund
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              form={form}
              data={props.data}
              labels={props.dataKey}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

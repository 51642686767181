import React, { useState, useEffect } from "react";
import { Table, Input, Card, CardBody, CardTitle } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import { getCancelledBooking } from "../../services/userService";
import { PaginationFunction } from "../../components/Custom/Pagination";
import moment from "moment/moment";
import Loader from "../../components/Custom/Loader";
import { numDifferentiation } from "../../helpers/amount_helper";

const title = "Cancelled Booking";
const BookingCancelLedger = (props) => {
  const [projectsData, setProjectsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };
  const getCancelledBookingData = () => {
    setLoading(true);
    const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}&date=${props.date}`;
    getCancelledBooking(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCancelledBookingData();
  }, [activePage, pageDataCount, props.date]);

  return (
    <React.Fragment>
      <div>
        <Card>
          <CardBody style={{ overflowX: "scroll" }}>
            <CardTitle>{title}</CardTitle>
            {loading && <Loader />}
            <Table hover>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: "4rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "10rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Unit</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Customer Name</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Booking Amount</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Date</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && projectsData && projectsData.length === 0 && (
                  <tr>
                    <td colSpan={12}>
                      <EmptyView
                        title={"Sorry!"}
                        discription={"No Data Found"}
                        bgcolor={"white"}
                      ></EmptyView>
                    </td>
                  </tr>
                )}
                {projectsData?.map((item, index) => (
                  <GetDataList
                    data={item}
                    key={index}
                    index={index}
                    activePage={activePage}
                    pageDataCount={pageDataCount}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={10}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "flex",
                        justifyItems: "flex-start",
                      }}
                    >
                      <PaginationFunction
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        totalCount={totalCount}
                        handlePageChange={handlePageChange}
                      />

                      <Input
                        style={{
                          width: "47px",
                          height: "45px",
                          marginLeft: "20px",
                          marginTop: "18px",
                          borderColor: "#00b3f4",
                          cursor: "pointer",
                        }}
                        name="pageid"
                        type="select"
                        value={pageDataCount}
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected={pageDataCount === 5 ? 5 : ""}>
                          05
                        </option>
                        <option selected={pageDataCount === 10 ? 10 : ""}>
                          10
                        </option>
                        <option selected={pageDataCount === 20 ? 20 : ""}>
                          20
                        </option>
                        <option selected={pageDataCount === 30 ? 30 : ""}>
                          30
                        </option>
                      </Input>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default BookingCancelLedger;

const GetDataList = (props) => {
  const { data, index } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.project_id?.name}</td>
        <td className="text-center">{data.unit_id?.name}</td>
        <td className="text-center">{data.customer_id?.name}</td>
        <td className="text-center">
          {data.booking_amount
            ? numDifferentiation(data.booking_amount.toFixed(2))
            : "-"}
        </td>
        <td className="text-center">
          {moment(data.created_at).format("DD-MM-YYYY")}
        </td>
      </tr>
    </>
  );
};

import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
} from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import { deleteFormData, getFormData } from "../../services/userService";
// import { PaginationFunction } from "../../components/Custom/Pagination";
// import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import { notifySuccess } from "../../components/Custom/notification";
import { Link } from "react-router-dom";
import Loader from "../../components/Custom/Loader";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const form = "PROJECT-FORM";
const title = "Project";
const CustomersProjectData = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  useEffect(() => {
    const getAllProjects = () => {
      setLoading(true);
      // const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&search=${search}`;
      const pageAndLimit = `&search=${search}`;
      getFormData("PROJECT", pageAndLimit)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          data.sort((a, b) => {
            const dateA = new Date(a["Launch Date"]);
            const dateB = new Date(b["Launch Date"]);
            return dateA - dateB;
          });
          data.reverse();
          setActivePage(data.length === 0 ? 1 : activePage);
          setTotalCount(res.data.data.totalCount);
          setProjectsData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getAllLinks();
    getAllProjects();
  }, [toggle, activePage, pageDataCount, search]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("----error----", err);
        setLoading(false);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">{title} Management</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title} Management
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by ${title} Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                  </div>
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>{title} Name</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Area (in Square Meter)
                        </h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Projects Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData &&
                      projectsData.map((item, index) => (
                        <GetDataList
                          data={item}
                          key={index}
                          index={index}
                          activePage={activePage}
                          pageDataCount={pageDataCount}
                          dataKey={configData}
                          toggle={callToggle}
                        />
                      ))}
                  </tbody>
                  {/* <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option>05</option>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot> */}
                  {/* <tfoot>
                    <tr key="">
                      <td colSpan={55}> {loading && <Loader />}</td>
                    </tr>
                  </tfoot> */}
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default CustomersProjectData;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteFormData(form, data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("Project Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.name}</td>
        <td className="text-center">{data.area ? data.area : "-"}</td>
        <td className="text-center">
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              <Tooltip
                id="tooltip-bottom"
                className="font-family-class font-12 fw-normal line-height-17 text-white"
              >
                View Customers
              </Tooltip>
            }
          >
            <Link to={`/project-unit-list?project_id=${data._id}&project=${data.name}`}>
              <i
                id="filters"
                className="mdi mdi-open-in-new font-size-20"
                style={{ marginRight: 10, cursor: "pointer" }}
              />
            </Link>
          </OverlayTrigger>
        </td>
      </tr>
    </>
  );
};

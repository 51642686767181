import { Form, Formik } from "formik";
import React, { useState } from "react";
import Select from "react-select";
import CustomInput, { Required } from "../../components/Custom/textinput.js";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import { addPartyPayments } from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import moment from "moment";

const paymentModeOptions = [
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "Online", value: "Online" },
];
const AddPartyPayment = (props) => {
  const [department] = useState(props.department);
  const [party] = useState(props.party);
  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);
  const [loading, setLoading] = useState(false);

  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  const handleSubmit = (values) => {
    let data = {
      ...values,
      department_id: department,
      party_id: party,
      payment_mode: paymentMode.value,
    };
    setLoading(true);
    addPartyPayments(data)
      .then((res) => {
        props.toggle();
        setLoading(false);
        notifySuccess("Payment Added Successfully");
      })
      .catch((error) => {
        setLoading(false);
        notifyError(error.data.error.message);
      });
  };

  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    return errors;
  };

  let validationObject = {
    payment_date: Yup.date().required("Please Enter Date"),
    note: Yup.string().required("Please Enter Note"),
    amount: Yup.number().required("Please Enter Amount"),
  };

  let submitFormSchema = Yup.object().shape(validationObject);

  const initialValues = {
    payment_mode: paymentMode,
    amount: "",
    payment_date: moment().format("YYYY-MM-DD").toString(),
    note: "",
  };

  return (
    <>
      <Modal isOpen={props.modal} centered={true} style={{ width: "100%" }}>
        <ModalHeader toggle={props.toggle}>Add Party Payments</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={submitFormSchema}
            validate={validationFunction}
          >
            {({ errors, touched, values, handleChange, setFieldError }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    Amount <Required />{" "}
                  </label>
                  <CustomInput
                    type="number"
                    values={values}
                    placeholder={"Enter Amount"}
                    name="amount"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Mode <Required />{" "}
                  </label>
                  <Select
                    options={paymentModeOptions}
                    name="payment_mode"
                    value={paymentMode}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldError("payment_mode", "");
                      handlePaymentModeChange(value);
                    }}
                    placeholder={"Select Payment Mode"}
                  />
                  {errors.payment_mode && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.payment_mode}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Date <Required />{" "}
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    placeholder={"Select Date"}
                    max={moment().format("YYYY-MM-DD")}
                    name="payment_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Note <Required />{" "}
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={"Enter Note"}
                    name="note"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Add
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddPartyPayment;

import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  addBorrow,
  addLoan,
  editBorrow,
  editLoan,
} from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import moment from "moment";
import Select from "react-select";

const AddLoan = ({
  modal,
  handleCloseModal,
  title,
  data = {},
  isLoanTable,
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState({
    value: "Cash",
    label: "Cash",
  });
  const paymentModeOptions = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online", label: "Online" },
  ];
  let initialValues = {
    party_name: data?.party_name || "",
    mobile_number: +data?.mobile_number || "",
    monthly_interest_rate: data?.monthly_interest_rate || "",
    amount: data?.amount || "",
    payment_mode: data?.payment_mode || paymentMode.value,
    payment_date:
      data?.payment_date?.split("T")[0] ||
      moment().format("YYYY-MM-DD").toString(),
    note: data?.note || "",
  };

  let validationObject = {
    party_name: Yup.string().required(`Please Enter Party Name`),
    mobile_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain numeric characters")
      .min(10, "Phone number must not exceed 10 digits")
      .max(10, "Phone number must not exceed 10 digits")
      .required("Phone number is required"),
    monthly_interest_rate: Yup.string().required(
      `Please Enter Monthly Interest Rate`
    ),
    payment_mode: Yup.string().required(`Please Select Payment Mode`),
    payment_date: Yup.string().required(`Please Select Payment Date`),
    amount: Yup.number()
      .required(`Please Enter amount`)
      .min(0, "Amount must be greater than 0"),
    note: Yup.string().max(100, "notes must be less than 100 characters"),
  };

  let loanSchema = Yup.object().shape(validationObject);

  const handleSubmit = (values) => {
    setLoading(true);
    if (isLoanTable) {
      if (title === "Update Loan" && data._id) {
        editLoan({
          updateValue: { ...values, date: values.payment_date },
          data: data._id,
        })
          .then((res) => {
            handleCloseModal();
            setLoading(false);
            notifySuccess("Loan Data Update Successfully");
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err.data.error.message);
          });
      } else {
        addLoan({
          ...values,
          date: values.payment_date,
          total_amount: values.amount,
        })
          .then((res) => {
            handleCloseModal();
            setLoading(false);
            notifySuccess("Loan Added Successfully");
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err.data.error.message);
          });
      }
    } else {
      if (title === "Update Borrow" && data._id) {
        editBorrow({
          updateValue: { ...values, date: values.payment_date },
          data: data._id,
        })
          .then((res) => {
            handleCloseModal();
            setLoading(false);
            notifySuccess("Borrow Data Update Successfully");
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err.data.error.message);
          });
      } else {
        addBorrow({
          ...values,
          date: values.payment_date,
          total_amount: values.amount,
        })
          .then((res) => {
            handleCloseModal();
            setLoading(false);
            notifySuccess("Borrow Added Successfully");
          })
          .catch((err) => {
            setLoading(false);
            notifyError(err.data.error.message);
          });
      }
    }
  };

  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={handleCloseModal}>{title}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={loanSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div style={{ height: "100px" }}>
                    <label>
                      Party Name <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={`Party Name `}
                      name="party_name"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Mobile Number <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder="Mobile Number"
                      name="mobile_number"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Monthly Interest Rate <Required />
                    </label>

                    <CustomInput
                      type="number"
                      values={values}
                      placeholder="Monthly Interest Rate"
                      name="monthly_interest_rate"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                      min={0}
                      max={100}
                      step={"any"}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder="Enter Amount"
                      name="amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                      min={0}
                      step={"any"}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      getOptionLabel={(option) => option.value}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldValue("payment_mode", value.value);
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Payment Mode"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Payment Date <Required />
                    </label>
                    <CustomInput
                      type="date"
                      values={values}
                      placeholder={"Select Date"}
                      name="payment_date"
                      max={moment().format("YYYY-MM-DD")}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>Note</label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Note"}
                      name="note"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <ModalFooter className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      disabled={loading}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {title === "Update Loan" ? "Update" : "Create"}
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddLoan;

import React, { useState } from "react";
import { Row, Col, Container, Button, Input, FormGroup } from "reactstrap";

import { Link, withRouter } from "react-router-dom";
import CustomInput from "../../components/Custom/textinput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { STORAGEKEY } from "../../components/Constant";
import { userAuthLogin } from "../../services/authService";
// import images
import logo from "../../assets/images/logo-sm-dark.png";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/auth/login/actions";
import { notifyError } from "../../components/Custom/notification";

const Login = (props) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const LogInSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Enter valid e-mail")
      .required("Please enter e-mail address"),
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password must be at least 8 character"),
  });

  const handleSubmit = (values) => {
    const data = {
      email: values.email.toLowerCase().trim(),
      password: values.password,
      pin: values.pin,
    };
    userAuthLogin(data)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        localStorage.setItem(STORAGEKEY.ACCESSTOKEN, data.accessToken);
        let role = data.role;
        dispatch(loginSuccess(data));
        localStorage.setItem(STORAGEKEY.ROLE, data.role);
        localStorage.setItem(STORAGEKEY.MANAGEMENT, data.management);
        localStorage.setItem(STORAGEKEY.CANCEL_BOOKING, data.cancel_booking);
        localStorage.setItem(STORAGEKEY.PARTY_PAYMENT, data.party_payment);
        localStorage.setItem(
          STORAGEKEY.RAW_MATERIAL_BILL,
          data.raw_material_bills
        );
        localStorage.setItem(STORAGEKEY.STAFF_SALARY, data.staff_salary);
        localStorage.setItem(
          STORAGEKEY.CUSTOMER_REVENUE,
          data.customer_revenue
        );
        localStorage.setItem(STORAGEKEY.GENERAL_BILL, data.general_bills);
        role === "superAdmin"
          ? props.history.push("/dashboard")
          : data.management
          ? props.history.push("/project-management")
          : data.cancel_booking
          ? props.history.push("/cancel-booking")
          : data.general_bills
          ? props.history.push("/general-expenses")
          : data.raw_material_bills
          ? props.history.push("/material-expenses")
          : data.staff_salary
          ? props.history.push("/employee-salary")
          : data.customer_revenue
          ? props.history.push("/unit-incomes")
          : data.customer_refund
          ? props.history.push("/customer-refund")
          : data.party_payment
          ? props.history.push("/party-expenses")
          : notifyError("Please Contact Administartion For Permissions");
      })
      .catch((err) => {
        err && notifyError(err.data.error.message);
        !err && notifyError("Server Is Under Maintenance");
      });
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">
                      Welcome To BlueCPM
                    </h5>

                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                        pin: "",
                      }}
                      validationSchema={LogInSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched, values, handleChange }) => (
                        <Form>
                          <div className="mb-3">
                            <FormGroup>
                              <label htmlFor="email" className="">
                                Email
                              </label>
                              <div className="has-wrapper">
                                <Input
                                  style={{
                                    paddingRight: "30px",
                                  }}
                                  className={
                                    errors.email ? "border-danger" : ""
                                  }
                                  type="text"
                                  value={values.email}
                                  placeholder="Enter email"
                                  name="email"
                                  onChange={(e) => handleChange(e)}
                                />
                                <span className="has-icon">
                                  <i className={"mdi mdi-account"} />
                                </span>
                                {errors.email && touched.email && (
                                  <div
                                    style={{ fontSize: 14 }}
                                    className="text-left mt-1 text-danger"
                                  >
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </FormGroup>
                          </div>

                          <div className="mb-3">
                            <FormGroup>
                              <label htmlFor="email" className="">
                                Password
                              </label>
                              <div className="has-wrapper">
                                <Input
                                  style={{ paddingRight: "30px" }}
                                  className={
                                    errors.password && touched.password
                                      ? "border-danger"
                                      : ""
                                  }
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  placeholder="Enter password"
                                  name="password"
                                  onChange={(e) => handleChange(e)}
                                />
                                <span
                                  className="has-icon"
                                  style={{ top: "4px" }}
                                >
                                  <i
                                    className={
                                      showPassword
                                        ? "mdi mdi-eye-outline"
                                        : "mdi mdi-eye-off"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  />
                                </span>
                                {errors.password && touched.password && (
                                  <div
                                    style={{ fontSize: 14 }}
                                    className="text-left mt-1 text-danger"
                                  >
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                            </FormGroup>
                          </div>
                          <div className="mb-3">
                            <FormGroup>
                              <label htmlFor="pin" className="">
                                Pin
                              </label>
                              <div className="has-wrapper">
                                <Input
                                  style={{
                                    paddingRight: "30px",
                                  }}
                                  className={errors.pin ? "border-danger" : ""}
                                  type="text"
                                  value={values.pin}
                                  placeholder="Enter pin"
                                  name="pin"
                                  onChange={(e) => handleChange(e)}
                                />
                                <span className="has-icon">
                                  <i className={"fas fa-portrait"} />
                                </span>
                                {errors.pin && touched.pin && (
                                  <div
                                    style={{ fontSize: 14 }}
                                    className="text-left mt-1 text-danger"
                                  >
                                    {errors.pin}
                                  </div>
                                )}
                              </div>
                            </FormGroup>
                          </div>

                          <div
                            className="mt-3 text-center w-100 waves-effect waves-light"
                            style={{ width: "20%" }}
                          >
                            <Button
                              type="submit"
                              name="btn"
                              className="btn btn-primary "
                              style={{ backgroundColor: "#3b5de7" }}
                              onSubmit={(values) => handleSubmit(values)}
                            >
                              Log In
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              {/* <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{" "}
                  <Link to="/user-register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

import api from "../api/api";
import * as url from "../api/url";

export const getFormData = (query, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.USER_FORMS + "?form=" + query + (pageAndLimit ? pageAndLimit : "")
      )

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getFormDataWithId = (query, id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.FORM_DATA + "?form=" + query + "&form_id=" + id)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const submitForm = (query, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.USER_FORMS + "?form=" + query, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateFormData = (query, form_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(
        url.USER_FORMS + "?form=" + query + "&form_id=" + form_id,
        data
      )

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteFormData = (query, form_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(
        url.USER_FORMS + "?form=" + query + "&form_id=" + form_id
      )

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllCustomers = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_CUSTOMERS + pageAndLimit)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

//? customers API
export const addCustomer = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CUSTOMER, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateCustomer = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.CUSTOMER + "?customer_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const deleteCustomer = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.CUSTOMER + "?customer_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCustomerDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.CUSTOMER + "?customer_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPartyDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_PARTY_DETAIL + "?party_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
//?department data API

export const getDeptWithParty = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_DEP_WITH_PARTY + pageAndLimit)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDepartmentExpenses = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DEPARTMENT_EXPENSE + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllUnitIncomes = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_UNIT_INCOMES + pageAndLimit)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllCustomerRefund = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_CUSTOMER_REFUND + pageAndLimit)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCustomerRefundRevenue = (query, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.ALL_BOOKED_UNIT_DATA +
          "?project_id=" +
          query +
          (pageAndLimit ? pageAndLimit : "")
      )

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getUnitDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_UNIT_DETAILS + id)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addUnitIncome = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.UNIT_INCOME, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addCustomerRefund = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.CUSTOMER_REFUND, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateUnitIncome = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UNIT_INCOME + "?unit_income_id=" + id, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const updateRefund = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.CUSTOMER_REFUND + "?refund_id=" + id, data)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteUnitIncome = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.UNIT_INCOME + "?unit_income_id=" + id)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteCustomerRefund = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.CUSTOMER_REFUND + "?refund_id=" + id)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
//? general expense API

export const getProjectGeneralExpense = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.PROJECT_GENERAL_EXPENSE + (pageAndLimit ? pageAndLimit : "")
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getProjectStaffSalary = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_STAFF_SALARY + (pageAndLimit ? pageAndLimit : ""))
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getProjectWiseGeneralExpense = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.GET_PROJECT_WISE_GENERAL_EXPENSE +
          (pageAndLimit ? pageAndLimit : "")
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addProjectGeneralExpense = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.PROJECT_GENERAL_EXPENSE, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteProjectGeneralExpense = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.PROJECT_GENERAL_EXPENSE + "?expense_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateProjectGeneralExpense = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.PROJECT_GENERAL_EXPENSE + "?expense_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

//? Employee Salary API
export const getAllEmployeeSalary = (pageAndLimit, employee_id) => {
  return new Promise(async (resolve, reject) => {
    let query = employee_id ? `&employee_id=${employee_id}` : "";
    return api
      .getWithToken(url.GET_ALL_EMPLOYEE_SALARY + pageAndLimit + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteEmployeeSalary = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.EMPLOYEE_SALARY + "?salary_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateEmployeeSalary = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.EMPLOYEE_SALARY + "?salary_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addEmployeeSalary = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.EMPLOYEE_SALARY, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getEmployeeSalary = (employee_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.EMPLOYEE_SALARY + "?employee_id=" + employee_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getUnitIncomeById = (unit_id, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.UNIT_INCOME + "?unit_id=" + unit_id + pageAndLimit)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const cancelUnitBooking = (unit_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.CANCEL_UNIT_BOOKING + unit_id)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCancelledBooking = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_CANCELLED_BOOKING + query)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addProjectExpense = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_PROJECT_EXPENSE, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const updateProjectExpense = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.ADD_PROJECT_EXPENSE + "?expense_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getProjectExpense = (
  id,
  department_id,
  party_id,
  pageAndLimit
) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.ADD_PROJECT_EXPENSE +
          "?project_id=" +
          (id ? id : "") +
          "&department_id=" +
          (department_id ? department_id : "") +
          "&party_id=" +
          (party_id ? party_id : "") +
          pageAndLimit
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const deleteProjectExpense = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.ADD_PROJECT_EXPENSE + "?expense_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllPartyPayments = (party_id, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.PARTY_PAYMENTS + "?party_id=" + party_id + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addPartyPayments = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.PARTY_PAYMENTS, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deletePartyPayments = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.PARTY_PAYMENTS + "?payment_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updatePartyPayments = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.PARTY_PAYMENTS + "?payment_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addBulkUnits = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.BULK_UNITS, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DownloadDemoCSVFile = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DEMO_UNITS_EXCEL)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addPartyRate = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.PARTY_RATE, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPartyRates = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.PARTY_RATE + "?party_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updatePartyRates = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.PARTY_RATE + "?rate_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deletePartyRates = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.PARTY_RATE + "?rate_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDashboardDataCount = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_DASHBOARD_COUNT + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDataForChart = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_CHART_DATA + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDepartmentOverview = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DEPARTMENT_OVERVIEW + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPartiesOverview = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.PARTY_OEVRVIEW + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getInterestRate = (project_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_INTEREST_RATE + "?project_id=" + project_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addInterestRate = (project_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_INTEREST_RATE + "?project_id=" + project_id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateInterestRate = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UPDATE_INTEREST_RATE + "?interest_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DownloadStatement = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DOWNLOAD_STATEMENT + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const statementOverview = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_STATEMENT_OVERVIEW + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getMonthlyInterest = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_MONTHLY_INTEREST + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DeleteAllUnits = (params) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.DELETE_ALL_UNITS + params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getSalaryPayRoll = (employee_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.SALARY_PAYROLL + "?employee_id=" + employee_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addSalaryPayRoll = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.SALARY_PAYROLL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateSalaryPayRoll = (payroll_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.SALARY_PAYROLL + "?payroll_id=" + payroll_id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteSalaryPayRoll = (payroll_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.SALARY_PAYROLL + "?payroll_id=" + payroll_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPartyPayments = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_PARTY_PAYMENT + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllLand = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_LAND + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteLand = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.GET_ALL_LAND + "?land_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addLand = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.GET_ALL_LAND, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

// -----------------------------------------------actions on particular borrow---------------------------------------
// export const editBorrowPayment = (data, _id) => {
//   return new Promise(async (resolve, reject) => {
//     return api
//       .putWithToken(
//         url.EDIT_BORROW_PAYMENT_DETAIL + "?borrow_payment_id=" + _id,
//         data
//       )
//       .then((response) => {
//         if (response) {
//           resolve(response);
//         }
//       })
//       .catch((err) => {
//         reject(err.response);
//       });
//   });
// };

export const deleteBorrowPayment = (_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(
        url.REMOVE_BORROW_PAYMENT_DETAIL + "?borrow_payment_id=" + _id
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addBorrowPayment = (data, _id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_BORROW_PAYMENT_DETAIL + "?borrow_id=" + _id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getBorrowPaymentDetail = (_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_BORROW_PAYMENT_DETAIL + "?borrow_id=" + _id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getBorrowDetail = (_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_BORROW_DETAIL + "?borrow_id=" + _id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
// -----------------------------------end---------------------------------------------

//-------------------------------------------actions on all borrows data------------------------------------
export const getAllBorrow = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_BORROW + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addBorrow = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_BORROW, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editBorrow = ({ updateValue, data }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(`${url.UPDATE_BORROW}/${data}`, updateValue)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteBorrow = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(`${url.REMOVE_BORROW}/${data}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
// -----------------------------------end---------------------------------------------

// -----------------------------------------------actions on particular loan---------------------------------------
// export const editLoanPayment = (data, _id) => {
//   return new Promise(async (resolve, reject) => {
//     return api
//       .putWithToken(
//         url.EDIT_LOAN_PAYMENT_DETAIL + "?loan_payment_id=" + _id,
//         data
//       )
//       .then((response) => {
//         if (response) {
//           resolve(response);
//         }
//       })
//       .catch((err) => {
//         reject(err.response);
//       });
//   });
// };

export const deleteLoanPayment = (_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(
        url.REMOVE_LOAN_PAYMENT_DETAIL + "?loan_payment_id=" + _id
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addLoanPayment = (data, _id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_LOAN_PAYMENT_DETAIL + "?loan_id=" + _id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getLoanPaymentDetail = (_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_LOAN_PAYMENT_DETAIL + "?loan_id=" + _id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getLoanDetail = (_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_LOAN_DETAIL + "?loan_id=" + _id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
// -----------------------------------end---------------------------------------------

//-------------------------------------------actions on all Loan data------------------------------------
export const getAllLoan = (pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_LOAN + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addLoan = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.ADD_LOAN, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const editLoan = ({ updateValue, data }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(`${url.UPDATE_LOAN}/${data}`, updateValue)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteLoan = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(`${url.REMOVE_LOAN}/${data}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
// -----------------------------------end---------------------------------------------

export const updateLand = (land_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.GET_ALL_LAND + "?land_id=" + land_id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getLandPayments = (land_id, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_LAND_PAYMENT + "?land_id=" + land_id + pageAndLimit)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const deleteLandPayment = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(url.GET_LAND_PAYMENT + "?land_payment_id=" + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const addLandPayments = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.GET_LAND_PAYMENT, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const updateLandPayments = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.GET_LAND_PAYMENT + "?land_payment_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const getLandData = (land_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_LAND + "?land_id=" + land_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getProjectData = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_PROJECT_DATA)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getDataForEmployeeChart = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_EMPLOYEE_CHART_DATA + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getEmloyeePayments = (employee_id, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.GET_EMPLOYEE_PAYMENT_DATA +
          "?employee_id=" +
          employee_id +
          pageAndLimit
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getEmployeeData = (employee_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_EMPLOYEE + "?employee_id=" + employee_id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getPartyListData = (department_id, project_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.GET_PARTYLISTDATA +
          "?department_id=" +
          department_id +
          "&project_id=" +
          project_id
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getProjectMaterialData = (id, department_id, pageAndLimit) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        url.GET_PROJECT_MATERIAL +
          "?party_id=" +
          id +
          "&department_id=" +
          department_id +
          pageAndLimit
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
export const updateProjectStatus = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.PROJECT_STATUS + "?project_id=" + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllArchiveProject = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_ALL_ARCHIVE)

      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const staffSalaryStatement = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.STAFF_SALARY_STATEMENT + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const bookedUnitsStatement = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.BOOKED_UNIT_STATEMENT + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const departmentStatement = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.DEPARTMENT_STATEMENT + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const partyStatement = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.PARTY_STATEMENT + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const generalBillStatement = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GENERAL_BILL_STATEMENT + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

import React from "react";
import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Table,
  Input,
} from "reactstrap";
import Loader from "../../components/Custom/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import EmptyView from "../../components/Custom/EmptyView";
import { PaginationFunction } from "../../components/Custom/Pagination";
import {
  deleteBorrow,
  deleteLoan,
  getAllBorrow,
  getAllLoan,
} from "../../services/userService";
import { useEffect } from "react";
import AddLoan from "./AddLoanModal";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import { Link } from "react-router-dom";
import { numDifferentiation } from "../../helpers/amount_helper";

const TableDetails = ({
  title = "Interest Detail",
  loanTitle = "",
  isLoanTable = false,
}) => {
  const [addModal, setAddModal] = useState(false);
  const [loanData, setloanData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const callToggle = () => {
    setToggle(!toggle);
    setLoading(false);
  };
  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  const getAllLoanData = () => {
    setLoading(true);
    const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}&search=${search}`;
    if (isLoanTable) {
      getAllLoan(pageAndLimit)
        .then((res) => {
          let { data, totalCount } = res?.data?.data;
          setActivePage(data.length === 0 ? 1 : activePage);
          setTotalCount(totalCount);
          setloanData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      getAllBorrow(pageAndLimit)
        .then((res) => {
          let { data, totalCount } = res?.data?.data;
          setActivePage(data.length === 0 ? 1 : activePage);
          setTotalCount(totalCount);
          setloanData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllLoanData();
  }, [activePage, pageDataCount, search, toggle]);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          {isLoanTable && (
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="page-title mb-0 font-size-18">{title}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active">{title}</li>
                  </ol>
                </div>
              </div>
            </div>
          )}
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {loanTitle}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by Party Name`}
                      style={{ width: 280 }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Backspace") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                  </div>
                  {addModal && (
                    <AddLoan
                      modal={addModal}
                      title={isLoanTable ? "Add Loan" : "Add Borrow"}
                      handleCloseModal={showAddConfigModal}
                      loan={loanTitle}
                      isLoanTable={isLoanTable}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "2rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "14rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Party Name</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Mobile Number</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "8rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Monthly Interest Rate ( % )
                        </h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "14rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Loan Amount</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "14rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount ( Int. )</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "6rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "14rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "6rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "8rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Details</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "6rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && loanData.length === 0 ? (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Data Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    ) : (
                      loanData?.map((item, index) => (
                        <RenderLinks
                          data={item}
                          key={index}
                          index={index}
                          title={title}
                          toggle={callToggle}
                          isLoanTable={isLoanTable}
                          pageDataCount={pageDataCount}
                          activePage={activePage}
                        />
                      ))
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />
                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TableDetails;

const RenderLinks = ({
  data,
  index,
  toggle,
  title,
  key,
  isLoanTable,
  activePage,
  pageDataCount,
}) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleShowUpdateModal = (data) => {
    if (data?.payment_status) return;
    setUpdateModal(!updateModal);
    toggle();
  };

  const handleShowDeleteModal = (data) => {
    if (data?.payment_status) return;
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    if (isLoanTable) {
      deleteLoan(data._id)
        .then((res) => {
          handleShowDeleteModal();
          toggle();
          notifySuccess(`${title} Field Deleted Successfully`);
        })
        .catch((err) => {
          notifyError(err.data.error.message);
        });
      setDeleteModal(false);
    } else {
      deleteBorrow(data._id)
        .then((res) => {
          handleShowDeleteModal();
          toggle();
          notifySuccess(`${title} Field Deleted Successfully`);
        })
        .catch((err) => {
          notifyError(err.data.error.message);
        });
      setDeleteModal(false);
    }
  };

  const sweetAlert = () => {
    return (
      <SweetAlert
        title="Are you sure you want to delete this field?"
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr key={key}>
        <td className="text-center">
          {" "}
          {index + 1 + (activePage - 1) * pageDataCount}
        </td>
        <td className="text-center">{data.party_name}</td>
        <td className="text-center">{data.mobile_number}</td>
        <td className="text-center">{data.monthly_interest_rate}</td>
        <td className="text-center">
          {numDifferentiation(data.total_amount.toFixed(2))}
        </td>
        <td className="text-center">
          {numDifferentiation(data.amount.toFixed(2))}
        </td>
        <td className="text-center">{data?.date?.split("T")[0] || "-"}</td>
        <td className="text-center">{data.note || "-"}</td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          <Link
            to={`${
              isLoanTable
                ? `/interest-details/loan/`
                : `/interest-details/borrow/`
            }${data._id}`}
            className="btn btn-primary waves-effect waves-light"
          >
            View Details
          </Link>
        </td>
        <td className="text-center">
          <i
            style={{
              color: "#134a87",
              fontSize: 20,
              cursor: data?.payment_status ? "not-allowed" : "pointer",
            }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={() => handleShowUpdateModal(data)}
          />
          {updateModal && (
            <AddLoan
              modal={updateModal}
              title={isLoanTable ? "Update Loan" : "Update Borrow"}
              handleCloseModal={handleShowUpdateModal}
              data={data}
              isLoanTable={isLoanTable}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={() => handleShowDeleteModal(data)}
            style={{
              fontSize: 24,
              color: "rgb(223, 71, 89)",
              cursor: data?.payment_status ? "not-allowed" : "pointer",
            }}
          />
          {deleteModal && sweetAlert()}
        </td>
      </tr>
    </>
  );
};

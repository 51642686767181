import React, { useEffect, useState } from "react";
import { Button, CardBody, CardTitle, Row } from "reactstrap";
import Select from "react-select";
import { addPartyPayments, getFormData } from "../../../services/userService";
import Loader from "../../../components/Custom/Loader";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomInput, { Required } from "../../../components/Custom/textinput.js";

import {
  notifyError,
  notifySuccess,
} from "../../../components/Custom/notification";
import moment from "moment";
import { Link } from "react-router-dom";
const departmentOptions = [];
const partyOptions = [];

const PartyBills = () => {
  const [department, setDepartment] = useState("");
  const [party, setParty] = useState("");
  const [loading, setLoading] = useState(false);
  const [allParties, setAllParties] = useState([]);

  const getAllParties = () => {
    setLoading(true);
    getFormData("PARTY")
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setAllParties(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getAllDepartments = () => {
    setLoading(true);
    getFormData("DEPARTMENT")
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        departmentOptions.length = 0;
        data.map((department) => {
          return departmentOptions.push({
            label: department.name,
            value: department._id,
          });
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllDepartments();
    getAllParties();
  }, []);

  const handleDepartmentChange = (value) => {
    setDepartment(value);
    partyOptions.length = 0;
    allParties.map((party) => {
      if (party.department_id._id === value.value) {
        return partyOptions.push({
          label: party.name,
          value: party._id,
        });
      } else {
        return null;
      }
    });
  };
  const handlePartyChange = (value) => {
    setParty(value);
  };

  // add party
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
    { label: "Online", value: "Online" },
  ];

  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);

  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  const handleSubmit = (values, { resetForm }) => {
    let data = {
      ...values,
      department_id: department.value,
      party_id: party.value,
      payment_mode: paymentMode.value,
    };
    setLoading(true);
    addPartyPayments(data)
      .then((res) => {
        setLoading(false);
        resetForm();
        setDepartment("");
        setParty("");
        setPaymentMode(paymentModeOptions[0]);
        notifySuccess("Payment Added Successfully");
      })
      .catch((error) => {
        setLoading(false);
        notifyError(error.data.error.message);
      });
  };

  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    return errors;
  };

  let validationObject = {
    payment_date: Yup.date().required("Please Enter Date"),
    note: Yup.string().required("Please Enter Note"),
    amount: Yup.number().required("Please Enter Amount"),
  };

  let submitFormSchema = Yup.object().shape(validationObject);

  const initialValues = {
    payment_mode: paymentMode,
    amount: "",
    payment_date: moment().format("YYYY-MM-DD").toString(),
    note: "",
  };

  return (
    <>
      <Row>
        <div>
          <CardBody style={{ overflowX: "scroll" }}>
            <div>
              {loading && <Loader />}
              <CardTitle
                className="h3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span className="text-center">Add Party Paid Bills</span>
                <Link
                  to={`/party-payments`}
                  className="btn btn-primary waves-effect waves-light "
                >
                  View All Party Bills
                </Link>
              </CardTitle>
              <div className="d-flex flex-column gap-3 mb-3 mt-3">
                <div>
                  <label>
                    Department <Required />
                  </label>
                  <Select
                    options={departmentOptions}
                    value={department}
                    onChange={handleDepartmentChange}
                  ></Select>
                </div>
                <div>
                  <label>
                    Party <Required />
                  </label>
                  <Select
                    options={partyOptions}
                    value={party}
                    onChange={handlePartyChange}
                  ></Select>
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={submitFormSchema}
                validate={validationFunction}
              >
                {({ errors, touched, values, handleChange, setFieldError }) => (
                  <Form>
                    <div className="d-flex flex-column gap-3 mb-3">
                      <div>
                        <label>
                          Amount <Required />{" "}
                        </label>
                        <CustomInput
                          type="number"
                          values={values}
                          placeholder={"Enter Amount"}
                          name="amount"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          style={{ color: "black" }}
                        />
                      </div>
                      <div>
                        <label>
                          Payment Mode <Required />{" "}
                        </label>
                        <Select
                          options={paymentModeOptions}
                          name="payment_mode"
                          value={paymentMode}
                          errors={errors}
                          touched={touched}
                          onChange={(value) => {
                            setFieldError("payment_mode", "");
                            handlePaymentModeChange(value);
                          }}
                          placeholder={"Select Payment Mode"}
                        />
                        {errors.payment_mode && (
                          <div
                            style={{
                              fontSize: 14,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="text-left mt-1 text-danger"
                          >
                            {errors.payment_mode}
                          </div>
                        )}
                      </div>
                      <div>
                        <label>
                          Payment Date <Required />{" "}
                        </label>
                        <CustomInput
                          type="date"
                          values={values}
                          placeholder={"Select Date"}
                          name="payment_date"
                          max={moment().format("YYYY-MM-DD")}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          style={{ color: "black" }}
                        />
                      </div>
                      <div>
                        <label>
                          Note <Required />{" "}
                        </label>
                        <CustomInput
                          type="text"
                          values={values}
                          placeholder={"Enter Note"}
                          name="note"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          style={{ color: "black" }}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        type="submit"
                        disabled={loading}
                        onSubmit={(values) => {
                          handleSubmit(values);
                        }}
                      >
                        {" "}
                        Add
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </CardBody>
        </div>
      </Row>
    </>
  );
};

export default PartyBills;

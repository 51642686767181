import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Card, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { userAuthRegister } from "../../services/authService";
// import images
import logo from "../../assets/images/logo-sm-dark.png";

const Register = (props) => {
  const handleValidSubmit = (event, values) => {
    const data = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmpassword,
      phone_number: values.phonenumber,
      role: "user",
    };
    userAuthRegister(data)
      .then((res) => {
        console.log("---response--", res);
      })
      .catch((err) => {
        console.log("---error----", err);
      });
  };

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Register</h5>

                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {props.registrationError && props.registrationError ? (
                        <Alert color="danger">{props.registrationError}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <AvField
                          name="firstname"
                          label="First Name"
                          type="text"
                          required
                          placeholder="enter firstname"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="lastname"
                          label="Last Name"
                          type="text"
                          required
                          placeholder="enter lastname"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="enter email"
                          type="email"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="phonenumber"
                          name="phonenumber"
                          label="Phone Number"
                          className="form-control"
                          placeholder="enter phone number"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="enter Password"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="confirmpassword"
                          label="Confirm Password"
                          type="password"
                          required
                          placeholder="enter Confirm Password"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Qovex{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </div>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/user-login" className="fw-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;

import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
} from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import { deleteFormData, getFormData } from "../../services/userService";
import SweetAlert from "react-bootstrap-sweetalert";
import { notifySuccess } from "../../components/Custom/notification";
import Loader from "../../components/Custom/Loader";
import { useHistory, Link } from "react-router-dom";

const form = "EMPLOYEE-FORM";
const title = "Employee";
const EmployeesData = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  useEffect(() => {
    const getAllEmployees = () => {
      setLoading(true);
      const pageAndLimit = `&search=${search}`;
      getFormData("EMPLOYEE", pageAndLimit)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          setActivePage(data.length === 0 ? 1 : activePage);
          setTotalCount(res.data.data.totalCount);
          setProjectsData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getAllLinks();
    getAllEmployees();
  }, [toggle, activePage, pageDataCount, search]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("----error----", err);
        setLoading(false);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">{title}</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by ${title} Name`}
                      style={{ width: 250 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                    <Link
                      to={`/employee-salary`}
                      className="btn btn-primary waves-effect waves-light "
                    >
                      View All Staff Salary
                    </Link>
                  </div>
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>{title} Name</h6>
                      </th>
                      {configData?.map((config, index) => {
                        return (
                          <th
                            key={index}
                            className="text-center"
                            style={{ minWidth: "5rem" }}
                          >
                            <h6 style={{ fontWeight: "bold" }}>
                              {config.item_name}
                            </h6>
                          </th>
                        );
                      })}
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Employee Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        dataKey={configData}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td colSpan={55}>{loading && <Loader />}</td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EmployeesData;

const GetDataList = (props) => {
  const { data, index } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center" item_name>
          {data.name}
        </td>
        {props.dataKey.map((key, index) => {
          return (
            <td
              key={index}
              style={{ maxWidth: "10rem" }}
              className="text-center"
            >
              {data[key.item_name] || "-"}
            </td>
          );
        })}
        <td className="text-center">
          {" "}
          <Link
            to={`/salary-details?employee_id=${props.data._id}`}
            className="btn btn-primary waves-effect waves-light"
          >
            View Details
          </Link>
        </td>
      </tr>
    </>
  );
};

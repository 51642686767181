import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
} from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import {
  deleteCustomer,
  deleteFormData,
  getAllCustomers,
} from "../../services/userService";
import AddCustomer from "./AddCustomer";
import { PaginationFunction } from "../../components/Custom/Pagination";
// import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import UpdateCustomer from "./UpdateCustomer";
import { notifySuccess } from "../../components/Custom/notification";
import Loader from "../../components/Custom/Loader";

const form = "CUSTOMER-FORM";
const title = "Customer";
const Customers = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  let params = window.location.search;
  let project_id = new URLSearchParams(params).get("project_id");
  let projectName = new URLSearchParams(params).get("project");

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  useEffect(() => {
    const getAllProjects = () => {
      setLoading(true);
      // const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}&search=${search}`;
      const pageAndLimit = `?project_id=${project_id}&page=${activePage}&limit=${pageDataCount}&search=${search}`;
      getAllCustomers(pageAndLimit)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          console.log("data====", data);
          setActivePage(data.length === 0 ? 1 : activePage);
          setTotalCount(res.data.data.totalCount);
          setProjectsData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getAllLinks();
    getAllProjects();
  }, [toggle, activePage, pageDataCount, search]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        // setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("----error----", err);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>
              <div className="page-title-middle">
                {/* <ol className="page-title mb-0 font-size-18"> */}
                  <h3 className="page-title mb-0 font-size-18">
                    {" "}
                    {projectName}{" "}
                  </h3>
                {/* </ol> */}
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">{title} Management</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title} Management
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by ${title} Name,Unit`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                  </div>
                  {addModal && (
                    <AddCustomer
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                      form={form}
                      labels={configData}
                      projectData={projectsData}
                      project_id={project_id}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        style={{ maxWidth: "4rem", width: "4rem" }}
                      >
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th
                        className="text-center"
                        style={{ maxWidth: "5rem", width: "5rem" }}
                      >
                        <h6 style={{ fontWeight: "bold" }}>Unit</h6>
                      </th>
                      <th
                        className="text-center"
                        style={{ maxWidth: "10rem", width: "10rem" }}
                      >
                        <h6 style={{ fontWeight: "bold" }}>{title} Name</h6>
                      </th>
                      {configData?.map((config, index) => {
                        return (
                          <th
                            key={index}
                            className="text-center"
                            style={{ maxWidth: "5rem", width: "5rem" }}
                          >
                            <h6 style={{ fontWeight: "bold" }}>
                              {config.item_name}
                            </h6>
                          </th>
                        );
                      })}
                      <th
                        className="text-center"
                        style={{ maxWidth: "5rem", width: "5rem" }}
                      >
                        <h6 style={{ fontWeight: "bold" }}>Booking Price</h6>
                      </th>
                     
                      <th
                        className="text-center"
                        style={{ maxWidth: "5rem", width: "5rem" }}
                      >
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Customer Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        dataKey={configData}
                        toggle={callToggle}
                        project_id={project_id}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option>05</option>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                  {/* <tfoot>
                    <tr>
                      <td colSpan={55}> {loading && <Loader />}</td>
                    </tr>
                  </tfoot> */}
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default Customers;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteCustomer(data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("Customer Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {data.unit_name ? data.unit_name : "-"}
        </td>
        <td className="text-center">{data.name}</td>
        {props.dataKey.map((key, index) => {
          return (
            <td
              key={index}
              className="text-center"
              style={{ maxWidth: "10rem", width: "10rem" }}
            >
              {data[key.item_name] || "-"}
            </td>
          );
        })}
        <td className="text-center">
          {data.booking_amount ? data.booking_amount : "-"}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateCustomer
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              form={form}
              data={props.data}
              labels={props.dataKey}
              project_id={props.project_id}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAllLand, deleteLand } from "../../services/userService";
import EmptyView from "../../components/Custom/EmptyView";
import AddLand from "./AddLand";
import UpdateLand from "./UpdateLand";
import { PaginationFunction } from "../../components/Custom/Pagination";
import Loader from "../../components/Custom/Loader";
import { notifySuccess } from "../../components/Custom/notification";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { numDifferentiation } from "../../helpers/amount_helper";

const title = "Land Details";
const land = "Land";
const Lands = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [landsData, setLandData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const callToggle = () => setToggle(!toggle);
  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  const getAllLandData = () => {
    setLoading(true);
    const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}&search=${search}`;
    getAllLand(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setLandData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllLandData();
  }, [activePage, pageDataCount, search, toggle]);
  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">{title}</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by Land Owner Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                  </div>
                  {addModal && (
                    <AddLand
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                      land={land}
                      // labels ={configData}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Plot Number</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Address</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Land Owner Name</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Rate per Meter</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Area (in Square Meter)
                        </h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Total Land Value</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Outstanding Amount
                        </h6>
                      </th>
                      <th className="text-center" style={{ maxWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && landsData.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Lands Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {landsData &&
                      landsData.map((item, index) => (
                        <GetDataList
                          data={item}
                          key={index}
                          index={index}
                          activePage={activePage}
                          pageDataCount={pageDataCount}
                          dataKey={configData}
                          toggle={callToggle}
                        />
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default Lands;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteLand(data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("Land Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };
  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.plot_number}</td>
        <td className="text-center">{data.address}</td>
        <td className="text-center">{data.land_owner_name}</td>
        <td className="text-center">{numDifferentiation(data.rate_per_meter)}</td>
        <td className="text-center">{numDifferentiation(data.area_in_meter)}</td>
        <td className="text-center">{numDifferentiation(data.total_land_value)}</td>
        <td className="text-center">
          {data.project_name && data.project_name.length > 0
            ? data.project_name[0]
            : "-"}
        </td>

        <td className="text-center">
          {data.total_outstanding && data.total_outstanding
            ? numDifferentiation(data.total_outstanding)
            : "-"}
        </td>
        {props.dataKey.map((key, index) => {
          return (
            <td
              key={index}
              style={{ maxWidth: "10rem" }}
              className="text-center"
            >
              {key.item_type === "Date"
                ? moment(data[key.item_name]).format("DD-MM-YYYY")
                : data[key.item_name] || "-"}
            </td>
          );
        })}
        <td className="text-center">
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={
              <Tooltip
                id="tooltip-bottom"
                className="font-family-class font-12 fw-normal line-height-17 text-white"
              >
                Land Payment
              </Tooltip>
            }
          >
            <Link to={`/land-details?land_id=${data._id}`}>
              <i
                id="filters"
                className="mdi mdi-open-in-new font-size-20"
                style={{ marginRight: 10, cursor: "pointer" }}
              />
            </Link>
          </OverlayTrigger>
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateLand
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              land={land}
              data={props.data}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

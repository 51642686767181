import moment from "moment";
import React, { useState } from "react";

import ReactApexChart from "react-apexcharts";
import { Row } from "reactstrap";
import Loader from "../../components/Custom/Loader";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import Grid from "@material-ui/core/Grid";

const Chart = (props) => {
  const { isLoading } = props;
  const [date, setDate] = useState(moment().format("YYYY-MM"));
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>Employee Payment Overview Chart</div>

        <Row className="mb-3 d-flex">
          <div className="col">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container="space-around">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy"
                  views={["year"]}
                  id="date-picker-inline"
                  value={date}
                  onChange={(date) => {
                    const formDate = moment(date).format("YYYY");
                    if (moment(formDate, "YYYY", true).isValid()) {
                      props.handleMonthChange(formDate);
                      setDate(formDate);
                    }
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </Row>
      </div>
      <div style={{ height: "350px" }}>
        {isLoading && <Loader />}
        {!isLoading && (
          <Spinearea series={props.series} categories={props.categories} />
        )}
      </div>
    </>
  );
};

export default Chart;

const Spinearea = (props) => {
  const series = props.series;

  return (
    <div id="chart">
      <div id="chart">
        <ReactApexChart
          options={{
            chart: { toolbar: { show: false } },
            dataLabels: { enabled: false },
            stroke: { curve: "smooth", width: 4 },
            colors: ["#00b3f4"],
            legend: { show: true },
            grid: { borderColor: "#00b3f4", strokeDashArray: 2 },
            xaxis: {
              categories: props.categories,
              axisTicks: { show: true },
              type: "datetime",
            },
            tooltip: { x: { format: "MMM yyyy" } },
            yaxis: {
              labels: {
                rotate: 0,
                rotateAlways: false,
                formatter: (val) => {
                  return val.toFixed(0);
                },
              },
              decimalsInFloat: 0,
            },
          }}
          series={series}
          type="bar"
          height={350}
          className="apexcharts-xaxis-texts-g"
        />
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Button, CardTitle, Table } from "reactstrap";
// import "../Links/links.css";
import { getFormFields, removeFormItem } from "../../services/adminService";
import AddConfiguration from "./AddConfiguration";
import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import Loader from "../../components/Custom/Loader";

const form = "EMPLOYEE-FORM";
const title = "Employee";
const EmployeeConfiguration = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  useEffect(() => {
    getAllLinks();
  }, [toggle]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("----error----", err);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Settings / {title} Settings
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title} Settings
                  <Button
                    type="submit"
                    name="btn"
                    className="custombtn mr-2"
                    style={{
                      marginRight: 10,
                    }}
                    color="primary"
                    onClick={showAddConfigModal}
                  >
                    Add
                  </Button>
                  {addModal && (
                    <AddConfiguration
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                      form={form}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: "10%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ width: "30%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Item Name</h6>
                      </th>
                      <th className="text-center" style={{ width: "20%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Item Type</h6>
                      </th>
                      <th className="text-center" style={{ width: "20%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Item Requirement</h6>
                      </th>
                      <th className="text-center" style={{ width: "20%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {configData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Links Found"}
                            bgcolor={"white"}
                            icon={"mdi mdi-link-variant"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )} */}
                    <tr>
                      <td className="text-center">1</td>
                      <td className="text-center">{title} Name</td>
                      <td className="text-center">Text</td>
                      <td className="text-center">Yes</td>
                      <td className="text-center" style={{ fontSize: "24px" }}>
                        -
                      </td>
                    </tr>
                    {configData?.map((item, index) => (
                      <RenderLinks
                        data={item}
                        key={index}
                        index={index}
                        title={title}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={55}>{loading && <Loader />}</td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EmployeeConfiguration;

const RenderLinks = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    removeFormItem(form, data._id)
      .then((res) => {
        handleShowDeleteModal();
        props.toggle();
        notifySuccess(`${props.title} Field Deleted Successfully`);
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title="Are you sure you want to delete this field?"
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">{index + 2}</td>
        <td className="text-center">{data.item_name}</td>
        <td className="text-center">{data.item_type}</td>
        <td className="text-center">
          {data.is_required === true ? "YES" : "NO"}
        </td>
        {!data.is_default ? (
          <td className="text-center">
            <i
              style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
              id="edit-btn"
              className="mdi mdi-pencil"
              onClick={handleShowUpdateModal}
            />
            {updateModal && (
              <UpdateConfiguration
                modal={updateModal}
                title={title}
                handleCloseModal={handleShowUpdateModal}
                form={form}
                data={data}
              />
            )}
            <i
              className="mdi mdi-trash-can"
              id="delete-btn"
              type="submit"
              onClick={handleShowDeleteModal}
              style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
            ></i>
            {deleteModal && sweetAlertFuction()}
          </td>
        ) : (
          <td className="text-center" style={{ fontSize: "24px" }}>
            -
          </td>
        )}
      </tr>
    </>
  );
};

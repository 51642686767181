import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Table, Input } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import {
  deleteProjectExpense,
  getProjectExpense,
} from "../../services/userService";
import { PaginationFunction } from "../../components/Custom/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import moment from "moment";
import { numDifferentiation } from "../../helpers/amount_helper";
import UpdateProjectExpense from "../ProjectExpense/UpdateProjectExpense";
import UpdateRatedBill from "../ProjectExpense/UpdateRatedBill";
import Loader from "../../components/Custom/Loader";

const title = "Raw Material Bills ";
const RawMaterialExpsLedger = (props) => {
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const getAllProjectExpense = () => {
    setLoading(true);
    const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&date=${props.date}`;
    getProjectExpense("","","", pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllProjectExpense();
  }, [toggle, activePage, pageDataCount, props.date]);

  return (
    <React.Fragment>
      <div>
        <Card>
          <CardBody style={{ overflowX: "scroll" }}>
            <CardTitle>{title}</CardTitle>
            {loading && <Loader />}
            <Table hover>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: "4rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "10rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Department Name</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Party Name</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Challan No/Bill No</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>
                      Material Receiver Name
                    </h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>
                      Material Received Date
                    </h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Note</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loading && projectsData && projectsData.length === 0 && (
                  <tr>
                    <td colSpan={12}>
                      <EmptyView
                        title={"Sorry!"}
                        discription={"No Data Found"}
                        bgcolor={"white"}
                      ></EmptyView>
                    </td>
                  </tr>
                )}
                {projectsData &&
                  projectsData.map((item, index) => (
                    <GetDataList
                      data={item}
                      key={index}
                      index={index}
                      activePage={activePage}
                      pageDataCount={pageDataCount}
                      toggle={callToggle}
                    />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={10}>
                    <div
                      style={{
                        width: "fit-content",
                        display: "flex",
                        justifyItems: "flex-start",
                      }}
                    >
                      <PaginationFunction
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        totalCount={totalCount}
                        handlePageChange={handlePageChange}
                      />

                      <Input
                        style={{
                          width: "47px",
                          height: "45px",
                          marginLeft: "20px",
                          marginTop: "18px",
                          borderColor: "#00b3f4",
                          cursor: "pointer",
                        }}
                        name="pageid"
                        type="select"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected={pageDataCount === 5 ? 5 : ""}>
                          05
                        </option>
                        <option selected={pageDataCount === 10 ? 10 : ""}>
                          10
                        </option>
                        <option selected={pageDataCount === 20 ? 20 : ""}>
                          20
                        </option>
                        <option selected={pageDataCount === 30 ? 30 : ""}>
                          30
                        </option>
                      </Input>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default RawMaterialExpsLedger;

export const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteProjectExpense(data._id)
      .then((res) => {
        handleShowDeleteModal();
        props.toggle();
        notifySuccess("Raw Material Bill Deleted Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this Raw Material Bill?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {data.project_id ? data.project_id.name : ""}
        </td>
        <td className="text-center">
          {data.department_id ? data.department_id.name : ""}
        </td>
        <td className="text-center">
          {data.party_id ? data.party_id.name : ""}
        </td>
        <td className="text-center">
          {data.amount ? numDifferentiation(data.amount.toFixed(2)) : "-"}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {data.challan_no ? data.challan_no : "-"}
        </td>
        <td className="text-center">
          {data.material_receiver_name ? data.material_receiver_name : "-"}
        </td>
        <td className="text-center">
          {moment(data.material_received_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ maxWidth: "10rem" }}>
          {data.note}
        </td>
        {!props.disableActions && (
          <td className="text-center">
            <i
              style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
              id="edit-btn"
              className="mdi mdi-pencil"
              onClick={handleShowUpdateModal}
            />
            {updateModal &&
              data.department_id.department_type === "fixed_bill" && (
                <UpdateProjectExpense
                  modal={updateModal}
                  title={title}
                  handleCloseModal={handleShowUpdateModal}
                  data={props.data}
                />
              )}
            {updateModal &&
              data.department_id.department_type === "rated_bill" && (
                <UpdateRatedBill
                  modal={updateModal}
                  title={title}
                  handleCloseModal={handleShowUpdateModal}
                  data={props.data}
                />
              )}
            <i
              className="mdi mdi-trash-can"
              id="delete-btn"
              type="submit"
              onClick={handleShowDeleteModal}
              style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
            ></i>
            {deleteModal && sweetAlertFuction()}
          </td>
        )}
      </tr>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Button, CardTitle, Table } from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import {
  getDeptWithSubDept,
  getFormFields,
  removeFormItem,
  updateFormItem,
} from "../../services/adminService";
import { getFormDataWithId } from "../../services/userService";
import AddConfiguration from "./AddConfiguration";
import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import Loader from "../../components/Custom/Loader";

const form = "SUBDEPARTMENT-FORM";
const title = "Sub-Department";
const SubDepartmentConfiguration = () => {
  let params = window.location.search;
  let id = new URLSearchParams(params).get("id");
  let departmentName = new URLSearchParams(params).get("department");
  const [configData, setConfigData] = useState([]);
  const [department, setDepartments] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const getAllLinks = () => {
    if (id) {
      setLoading(true);
      getFormFields(form + "&dept_id=" + id)
        .then((res) => {
          const data = res && res.data && res.data.data;
          setConfigData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          // console.log("----error----", err);
        });
    }
  };
  const getDepartmentDetails = () => {
    if (id) {
      setLoading(true);
      getFormDataWithId("DEPARTMENT", id)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          setDepartmentDetails(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllLinks();
    getAllDepartment();
    getDepartmentDetails();
  }, [toggle, id]);

  const getAllDepartment = () => {
    setLoading(true);
    getDeptWithSubDept()
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setDepartments(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Settings / {title} Settings
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            {id && (
              <Card>
                <CardBody>
                  {loading && <Loader />}
                  <CardTitle
                    className="h3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {departmentName} Settings
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                    {addModal && (
                      <AddConfiguration
                        modal={addModal}
                        title={title}
                        handleCloseModal={showAddConfigModal}
                        form={form}
                      />
                    )}
                  </CardTitle>

                  <Table hover>
                    <thead>
                      <tr>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                        </th>
                        <th className="text-center" style={{ width: "30%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Item Name</h6>
                        </th>
                        <th className="text-center" style={{ width: "15%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Item Type</h6>
                        </th>
                        <th className="text-center" style={{ width: "15%" }}>
                          <h6 style={{ fontWeight: "bold" }}>
                            Item Requirement
                          </h6>
                        </th>
                        <th className="text-center" style={{ width: "15%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                        </th>
                        {departmentDetails &&
                          departmentDetails.department_type ===
                            "rated_bill" && (
                            <th
                              className="text-center"
                              style={{ width: "15%" }}
                            >
                              <h6 style={{ fontWeight: "bold" }}>
                                Payment Calculation Field
                              </h6>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {configData?.length === 0 && (
                        <tr>
                          <td colSpan={12}>
                            <EmptyView
                              title={"Sorry!"}
                              discription={"No Links Found"}
                              bgcolor={"white"}
                              icon={"mdi mdi-link-variant"}
                            ></EmptyView>
                          </td>
                        </tr>
                      )} */}
                      <tr>
                        <td className="text-center">1</td>
                        <td className="text-center">Challan No/Bill No</td>
                        <td className="text-center">Text</td>
                        <td className="text-center">YES</td>
                        <td
                          className="text-center"
                          style={{ fontSize: "24px" }}
                        >
                          -
                        </td>
                        {departmentDetails &&
                          departmentDetails.department_type ===
                            "rated_bill" && (
                            <td
                              className="text-center"
                              style={{ fontSize: "24px" }}
                            >
                              -
                            </td>
                          )}
                      </tr>
                      <tr>
                        <td className="text-center">2</td>
                        <td className="text-center">Material Receiver Name</td>
                        <td className="text-center">Text</td>
                        <td className="text-center">YES</td>
                        <td
                          className="text-center"
                          style={{ fontSize: "24px" }}
                        >
                          -
                        </td>
                        {departmentDetails &&
                          departmentDetails.department_type ===
                            "rated_bill" && (
                            <td
                              className="text-center"
                              style={{ fontSize: "24px" }}
                            >
                              -
                            </td>
                          )}
                      </tr>
                      {configData?.map((item, index) => (
                        <RenderLinks
                          data={item}
                          key={index}
                          index={index}
                          departmentDetails={departmentDetails}
                          toggle={callToggle}
                        />
                      ))}
                      {departmentDetails.department_type === "fixed_bill" && (
                        <tr>
                          <td className="text-center">
                            {configData.length + 3}
                          </td>
                          <td className="text-center">Work Details</td>
                          <td className="text-center">Textarea</td>
                          <td className="text-center">YES</td>
                          <td
                            className="text-center"
                            style={{ fontSize: "24px" }}
                          >
                            -
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan={55}>{loading && <Loader />} </td>
                      </tr>
                    </tfoot> */}
                  </Table>
                </CardBody>
              </Card>
            )}
            {!id && (
              <Card>
                <CardBody>
                  {loading && <Loader />}
                  <CardTitle
                    className="h3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {title} Configuration
                    {/* <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button> */}
                    {addModal && (
                      <AddConfiguration
                        modal={addModal}
                        title={title}
                        handleCloseModal={showAddConfigModal}
                        form={form}
                      />
                    )}
                  </CardTitle>

                  <Table hover>
                    <thead>
                      <tr>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                        </th>
                        <th className="text-center" style={{ width: "30%" }}>
                          <h6 style={{ fontWeight: "bold" }}>
                            Department Name
                          </h6>
                        </th>
                        <th className="text-center" style={{ width: "20%" }}>
                          <h6 style={{ fontWeight: "bold" }}>
                            Sub Department Configuration
                          </h6>
                        </th>
                        <th className="text-center" style={{ width: "20%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading && department.length === 0 && (
                        <tr>
                          <td colSpan={12}>
                            <EmptyView
                              title={"Sorry!"}
                              discription={"No Department Found"}
                              bgcolor={"white"}
                            ></EmptyView>
                          </td>
                        </tr>
                      )}
                      {department.length > 0 &&
                        department.map((dep, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">{dep.name}</td>
                              <td className="text-center">
                                {" "}
                                Challan No/Bill No,Receiver Name,
                                {dep.department_type === "fixed_bill" &&
                                  "Work Details,"}
                                {dep.subdepartment.map((e, index) => {
                                  return (
                                    e.item_name +
                                    (index + 1 !== dep.subdepartment.length
                                      ? ","
                                      : "")
                                  );
                                })}
                              </td>
                              <td className="text-center">
                                <Link
                                  to={
                                    "/subdepartment-configuration?department=" +
                                    dep.name +
                                    "&id=" +
                                    dep._id
                                  }
                                >
                                  <i
                                    id="filters"
                                    className="mdi mdi-open-in-new font-size-20"
                                    style={{
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                  />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    {/* <tfoot>
                      <tr>
                        <td colSpan={55}> {loading && <Loader />}</td>
                      </tr>
                    </tfoot> */}
                  </Table>
                </CardBody>
              </Card>
            )}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default SubDepartmentConfiguration;

const RenderLinks = (props) => {
  const { data, index } = props;
  const departmentDetails = props.departmentDetails;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [paymentFieldModal, setPaymentFieldModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const handleShowpaymentFieldModal = () => {
    setPaymentFieldModal(!paymentFieldModal);
  };

  const handleOnDelete = () => {
    removeFormItem(form, data._id)
      .then((res) => {
        handleShowDeleteModal();
        props.toggle();
        notifySuccess(`${props.title} Field Deleted Successfully`);
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  const handleUpdatePaymentField = (data) => {
    let updateData = data.countable
      ? { countable: false }
      : { countable: true };
    if (!data.countable) {
      updateData = { countable: true };
    }
    updateFormItem(updateData, "SUBDEPARTMENT-FORM", data._id).then((res) => {
      notifySuccess("Payment Field Updated Successfully");
      handleShowpaymentFieldModal();
      props.toggle();
    });
  };

  const sweetAlertFuction2 = (data) => {
    return (
      <SweetAlert
        title={`Are you sure you want to Update ?`}
        showCancel
        confirmBtnText="Update It!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleUpdatePaymentField(data);
        }}
        onCancel={handleShowpaymentFieldModal}
      />
    );
  };
  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title="Are you sure you want to delete this field?"
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">{index + 3}</td>
        <td className="text-center">{data.item_name}</td>
        <td className="text-center">{data.item_type}</td>
        <td className="text-center">
          {data.is_required === true ? "YES" : "NO"}
        </td>
        {!data.is_default ? (
          <td className="text-center">
            <i
              style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
              id="edit-btn"
              className="mdi mdi-pencil"
              onClick={handleShowUpdateModal}
            />
            {updateModal && (
              <UpdateConfiguration
                modal={updateModal}
                title={title}
                handleCloseModal={handleShowUpdateModal}
                form={form}
                data={data}
              />
            )}
            <i
              className="mdi mdi-trash-can"
              id="delete-btn"
              type="submit"
              onClick={handleShowDeleteModal}
              style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
            ></i>
            {deleteModal && sweetAlertFuction()}
          </td>
        ) : (
          <td className="text-center" style={{ fontSize: "24px" }}>
            -
          </td>
        )}
        {departmentDetails &&
          departmentDetails.department_type === "rated_bill" &&
          (data.item_type === "Number" ? (
            <td className="text-center">
              {data.countable === true ? (
                <i
                  id="primary"
                  className="mdi mdi-check"
                  style={{ fontSize: 20, color: "green", cursor: "pointer" }}
                  onClick={() => {
                    !data.is_default && handleShowpaymentFieldModal();
                  }}
                ></i>
              ) : (
                <i
                  id="primary"
                  className="mdi mdi-window-close"
                  style={{ fontSize: 20, color: "red", cursor: "pointer" }}
                  onClick={() => {
                    !data.is_default && handleShowpaymentFieldModal();
                  }}
                ></i>
              )}
              {paymentFieldModal && sweetAlertFuction2(data)}
            </td>
          ) : (
            <td className="text-center"> - </td>
          ))}
      </tr>
    </>
  );
};

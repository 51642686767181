import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Button,
  CardTitle,
  Table,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import {
  deleteProjectExpense,
  getFormData,
  getProjectExpense,
} from "../../services/userService";
import AddProjectExpense from "./AddProjectExpense";
import { PaginationFunction } from "../../components/Custom/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import moment from "moment/moment";
import { ModalFooter } from "react-bootstrap";
import UpdateProjectExpense from "./UpdateProjectExpense";
import { Required } from "../../components/Custom/textinput";
import AddRatedBill from "./AddRatedBill";
import UpdateRatedBill from "./UpdateRatedBill";
import Loader from "../../components/Custom/Loader";
import { numDifferentiation } from "../../helpers/amount_helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const form = "UNIT-FORM";
const title = "Raw Material Bills";
const ProjectExpenses = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [departmentModal, setDepartmentModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [search, setSearch] = useState("");
  const [department, setDepartment] = useState();
  const [project, setProject] = useState();
  const [party, setParty] = useState();
  const [labels, setLabels] = useState([]);
  const [toolTip, setToolTip] = useState(false);
  const [dataExportLoading, setDataExportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [month, setMonth] = useState(new Date());
  const [customDateModal, setCustomDateModal] = useState(false)
  const [customMonthModal, setCustomMonthModal] = useState(false)
  let params = window.location.search;
  let project_id = new URLSearchParams(params).get("project_id");
  let department_id = new URLSearchParams(params).get("department_id");
  let party_id = new URLSearchParams(params).get("party_id");
  const callToggle = () => setToggle(!toggle);
  const toggleDepartmentModal = () => setDepartmentModal(!departmentModal);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };
  const amountFunction = (value) => {
    setAmount(value);
    setActivePage(1);
  };
  const handleDepartmentChange = (department, subDepartmentConfig) => {
    setDepartment(department);
    // setParty("");
    setLabels(subDepartmentConfig);
  };
  const handleProjectChange = (project) => {
    setProject(project);
  };

  const handlePartyChange = (party) => {
    setParty(party);
  };
  const getMaterialList = () => {
    setLoading(true);
    const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&search=${search}`;
    getProjectExpense(project_id, department_id,party_id, pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);      
        setProjectsData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getMaterialList();
  }, [toggle, activePage, pageDataCount, search]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log("----error----", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllLinks();
  }, []);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  const handleCustomDateToggle = () => {
    setCustomDateModal(!customDateModal)
    setStartDate(new Date())
    setEndDate(new Date())
  }

  const handleCustomMonthToggle = () => {
    setCustomMonthModal(!customMonthModal)
    setMonth(new Date())
  }

  const handleSelectDateRange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const exportToExcel = () => {
    let query = '';
    if (dataExportLoading) {
      return;
    }
    setDataExportLoading(true);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    if (selectedValue == "customDate") {
      const start = moment(startDate).format('YYYY-MM-DD')
      const end = moment(!endDate ? startDate : endDate).format('YYYY-MM-DD')

      query += `&start_date=${start}&end_date=${end}`
    } else if (selectedValue == "customMonth") {
      const date = moment(month).format('YYYY-MM-DD')

      query += `&month=${date}`
    }

    getProjectExpense(project_id, department_id, party_id, query)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        data = data.map((data) => {
          let materialWeight = data.items.some((item) => item.Quantity !== 0) ? (
            data.items.map(
              (item, itemIndex) => {  
                return item.Quantity !== 0 ? `${item.itemNames}: ${numDifferentiation(item.Quantity)}` : ""
              }
            ).filter(Boolean)
          ) : "-"

          return {
            "Project Name": data.project_id.name,
            "Department Name": data.department_id.name,
            "Party Name": data.party_id.name,
            "Amount": data.amount,
            "Payment Mode": data.payment_mode,
            "Challan No/Bill No": data.challan_no,
            "Material Weight": typeof(materialWeight) === "string" ? materialWeight : materialWeight.join(" , "),
            "Material Receiver Name": data.material_receiver_name,
            "Material Received Date": moment(data.material_received_date).format("DD-MM-YYYY"),
            "Note": data.note,
          };
        });

        const header = [
          "Project Name",
          "Department Name",
          "Party Name",
          "Amount",
          "Payment Mode",
          "Challan No/Bill No",
          "Material Weight",
          "Material Receiver Name",
          "Material Received Date",
          "Note",
        ]

        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.sheet_add_aoa(ws, [header]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, "raw_materials" + fileExtension);
        setDataExportLoading(false);
        if (selectedValue == "customDate") {
          handleCustomDateToggle()
        } else if (selectedValue == "customMonth") {
          handleCustomMonthToggle()
        }
      })
      .catch((err) => {
        setDataExportLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`search by project/department/party name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                          // amountFunction(e.target.value);
                        }
                      }}
                    />
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        margin: "0px 10px",
                      }}
                      color="primary"
                      onClick={toggleDepartmentModal}
                    >
                      Add
                    </Button>
                    <div className="d-flex justify-content-center align-items-center">
                      <Dropdown isOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)} direction="down">
                        <DropdownToggle
                          caret
                          id="dataExport"
                          color="primary"
                          style={{
                            marginRight: "10px",
                          }}
                          disabled={dataExportLoading}
                        >
                          {dataExportLoading && (
                            <Spinner
                              style={{ height: "0.9rem", width: "0.9rem" }}
                            />
                          )}
                          {!dataExportLoading && (
                            <i className="mdi mdi-download"></i>
                          )}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem onClick={() => { setSelectedValue("customDate"); setCustomDateModal(true) }}>Custom Date</DropdownItem>
                          <DropdownItem onClick={() => { setSelectedValue("customMonth"); setCustomMonthModal(true) }}>Custom Month</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  {
                    selectedValue == "customDate" && (
                      <Modal isOpen={customDateModal} size="sm" centered={true}>
                        <ModalHeader toggle={handleCustomDateToggle}>Select Custom Date</ModalHeader>
                        <ModalBody className="text-center">
                          <DatePicker
                            dateFormat="YYYY-MM-DD"
                            selected={startDate}
                            onChange={handleSelectDateRange}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={startDate
                              ? moment(startDate).add(3, 'months').toDate()
                              : null}
                            selectsRange
                            inline
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" disabled={dataExportLoading} onClick={exportToExcel}>
                            {dataExportLoading ? "Loading..." : "Export"}
                          </Button>{' '}
                          <Button color="secondary" onClick={handleCustomDateToggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )
                  }
                  {
                    selectedValue == "customMonth" && (
                      <Modal isOpen={customMonthModal} size="sm" centered={true}>
                        <ModalHeader toggle={handleCustomMonthToggle}>Select Custom Month</ModalHeader>
                        <ModalBody className="text-center">
                          <DatePicker
                            showIcon
                            selected={month}
                            onChange={(date) => setMonth(date)}
                            inline
                            showMonthYearPicker
                          />
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" disabled={dataExportLoading} onClick={exportToExcel}>
                            {dataExportLoading ? "Loading..." : "Export"}
                          </Button>{' '}
                          <Button color="secondary" onClick={handleCustomMonthToggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    )
                  }
                  {addModal && department.type === "fixed_bill" && (
                    <AddProjectExpense
                      modal={addModal}
                      handleCloseModal={showAddConfigModal}
                      department={department}
                      project={project}
                      party={party}
                      labels={labels}
                    />
                  )}
                  {addModal && department.type === "rated_bill" && (
                    <AddRatedBill
                      modal={addModal}
                      handleCloseModal={showAddConfigModal}
                      department={department}
                      project={project}
                      party={party}
                      labels={labels}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Department Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Party Name</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Material Weight</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Challan No/Bill No
                        </h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Material Receiver Name
                        </h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Material Received Date
                        </h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData && projectsData.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Data Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData &&
                      projectsData.map((item, index) => (
                        <GetDataList
                          data={item}
                          key={index}
                          index={index}
                          activePage={activePage}
                          pageDataCount={pageDataCount}
                          dataKey={configData}
                          toggle={callToggle}
                          getMaterialList={getMaterialList}
                        />
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
      {departmentModal && (
        <SelectDepartment
          modal={departmentModal}
          handleCloseModal={toggleDepartmentModal}
          addModal={showAddConfigModal}
          department={handleDepartmentChange}
          project={handleProjectChange}
          party={handlePartyChange}
        />
      )}
    </React.Fragment>
  );
};
export default ProjectExpenses;

export const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteProjectExpense(data._id)
      .then((res) => {
        handleShowDeleteModal();
        props.toggle();
        props.getMaterialList();
        notifySuccess("Raw Material Bill Deleted Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this Raw Material Bill?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {data.project_id ? data.project_id.name : ""}
        </td>
        <td className="text-center">
          {data.department_id ? data.department_id.name : ""}
        </td>
        <td className="text-center">
          {data.party_id ? data.party_id.name : ""}
        </td>
        <td className="text-center">
          {data.items.some((item) => item.Quantity !== 0) ? (
            data.items.map(
              (item, itemIndex) =>
                item.Quantity !== 0 && (
                  <div key={itemIndex}>
                    <p>
                      {item.itemNames}:
                      <span style={{ color: "black" }}>
                        {numDifferentiation(item.Quantity)}
                      </span>
                    </p>
                  </div>
                )
            )
          ) : (
            <div>
              <p>-</p>
            </div>
          )}
        </td>
        <td className="text-center">
          {data.amount ? numDifferentiation(data.amount.toFixed(2)) : "-"}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {data.challan_no ? data.challan_no : "-"}
        </td>
        <td className="text-center">
          {data.material_receiver_name ? data.material_receiver_name : "-"}
        </td>
        <td className="text-center">
          {moment(data.material_received_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ maxWidth: "10rem" }}>
          {data.note}
        </td>
        {!props.disableActions && (
          <td className="text-center">
            <i
              style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
              id="edit-btn"
              className="mdi mdi-pencil"
              onClick={handleShowUpdateModal}
            />
            {updateModal &&
              data.department_id.department_type === "fixed_bill" && (
                <UpdateProjectExpense
                  modal={updateModal}
                  title={title}
                  handleCloseModal={handleShowUpdateModal}
                  data={props.data}
                  labels={props.dataKey}
                  getMaterialList={props.getMaterialList}
                />
              )}
            {updateModal &&
              data.department_id.department_type === "rated_bill" && (
                <UpdateRatedBill
                  modal={updateModal}
                  title={title}
                  handleCloseModal={handleShowUpdateModal}
                  data={props.data}
                  labels={props.dataKey}
                  getMaterialList={props.getMaterialList}
                />
              )}
            <i
              className="mdi mdi-trash-can"
              id="delete-btn"
              type="submit"
              onClick={handleShowDeleteModal}
              style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
            ></i>
            {deleteModal && sweetAlertFuction()}
          </td>
        )}
      </tr>
    </>
  );
};

const projectOptions = [];
const departmentOptions = [];
const partyOptions = [];
export const SelectDepartment = (props) => {
  const [department, setDepartment] = useState("");
  const [project, setProject] = useState("");
  const [party, setParty] = useState("");

  const getAllParties = (value) => {
    getFormData("PARTY").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      partyOptions.length = 0;
      data.map((party) => {
        if (party.department_id._id === value.value) {
          return partyOptions.push({
            label: party.name,
            value: party._id,
          });
        } else {
          return null;
        }
      });
    });
  };

  const getSubDepartmentFields = (department) => {
    getFormFields("SUBDEPARTMENT-FORM&dept_id=" + department.value).then(
      async (res) => {
        let data = res.data && res.data.data;
        props.department(department, data);
      }
    );
  };

  useEffect(() => {
    const getAllDepartments = () => {
      getFormData("DEPARTMENT").then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        departmentOptions.length = 0;
        data.map((department) => {
          return departmentOptions.push({
            label: department.name,
            value: department._id,
            type: department.department_type,
          });
        });
      });
    };

    const getAllProjects = () => {
      getFormData("PROJECT").then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        projectOptions.length = 0;
        data.map((project) => {
          return projectOptions.push({
            label: project.name,
            value: project._id,
          });
        });
      });
    };
    getAllDepartments();
    getAllProjects();
  }, []);
  const handleProjectChange = (value) => {
    setProject(value);
    props.project(value);
  };
  const handlePartyChange = (value) => {
    setParty(value);
    props.party(value);
  };
  const handleDepartmentChange = (value) => {
    setParty("");
    setDepartment(value);
    getAllParties(value);
    getSubDepartmentFields(value);
  };

  const handleAddClick = () => {
    if (party !== "") {
      props.handleCloseModal();
      props.addModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Select Department
        </ModalHeader>
        <ModalBody>
          <div style={{ height: "100px" }}>
            <label>
              Project <Required />
            </label>
            <Select
              options={projectOptions}
              value={project}
              onChange={(value) => {
                handleProjectChange(value);
              }}
              placeholder={"Select Project"}
            />
          </div>
          <div style={{ height: "100px" }}>
            <label>
              Department <Required />
            </label>
            <Select
              options={departmentOptions}
              name="department_id"
              value={department}
              onChange={(value) => {
                handleDepartmentChange(value);
              }}
              placeholder={"Select Department"}
            />
          </div>
          <div style={{ height: "100px" }}>
            <label>
              Party <Required />
            </label>
            <Select
              options={partyOptions}
              name="department_id"
              value={party}
              onChange={(value) => {
                handlePartyChange(value);
              }}
              placeholder={"Select Party"}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={department === "" || project === "" || party === ""}
            onClick={handleAddClick}
            style={{ width: "" }}
          >
            Add Expense
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Input, Row, Spinner, Table } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import { PaginationFunction } from "../../components/Custom/Pagination";
import { getPartyDetails, getProjectExpense } from "../../services/userService";
import { getFormFields } from "../../services/adminService";
import { numDifferentiation } from "../../helpers/amount_helper";
import Loader from "../../components/Custom/Loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const form = "PARTY-FORM";
const title = "Party";

function PartyDetails() {
  const [user, setUser] = useState({});
  const [userConfig, setUserConfig] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [transaction, setTransaction] = useState([]);
  const [materialReceivedAmount, setMaterialReceivedAmount] = useState(0);
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [partyExpenseLoading, setPartyExpenseLoading] = useState(false);

  let params = window.location.search;
  let party_id = new URLSearchParams(params).get("party_id");
  let project_id = new URLSearchParams(params).get("project_id");
  let department_id = new URLSearchParams(params).get("department_id");
  let departmentName = new URLSearchParams(params).get("department");
  let materialWeight = new URLSearchParams(params).get("total");
  useEffect(() => {
    setRevenueLoading(true);
    const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}`;
    getProjectExpense(project_id, department_id, party_id, pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setTotalCount(res.data.data.totalCount);
        setMaterialReceivedAmount(res.data.data.totalBilledAmount);
        setTransaction(data);
        setRevenueLoading(false);
      })
      .catch((err) => {
        setRevenueLoading(false);
      });
  }, [activePage, pageDataCount]);

  useEffect(() => {
    getAllLinks();
  }, []);
  const getAllLinks = () => {
    setLoading(true);
    Promise.all([
      getFormFields(form),
      getFormFields("PARTY-FORM"),
      getPartyDetails(party_id),
    ])
      .then(([response1, response2, response3]) => {
        const data1 = response1 && response1.data && response1.data.data;
        setConfigData(data1);

        const data2 = response2 && response2.data && response2.data.data;
        setUserConfig(data2);

        if (!response3.data.data.error) setUser(response3.data.data.data);
      })
      .catch((error) => {
        console.log("getAllLinks error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const exportToExcel = () => {
    setPartyExpenseLoading(true)

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    getProjectExpense(project_id, department_id, party_id,"")
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        data = data.map((data) => {
          return {
            "Amount": data.amount,
            "Payment Mode": data.payment_mode,
            "Payment Date": moment(data.material_received_date).format("DD-MM-YYYY"),
            "Note": data.note,
          };
        });

        const header = [
            "Amount",
            "Payment Mode",
            "Payment Date",
            "Note",
        ]

        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.sheet_add_aoa(ws, [header]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, "patry_expense" + fileExtension);
        setPartyExpenseLoading(false);
      })
      .catch((err) => {
        setPartyExpenseLoading(false);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{departmentName}</h4>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3 fw-semibold"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title} Details
                </CardTitle>
                {loading ? (
                  <Loader />
                ) : (
                  Object.keys(user).length > 0 && (
                    <div className="d-flex flex-column d-grid gap-2 mt-4">
                      <div className="row">
                        <label className="col-sm-4">Name:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>{user["name"]}</p>
                        </div>
                      </div>
                      {userConfig?.map((config, index) => {
                        return (
                          <div className="row">
                            <label className="col-sm-4">
                              {config.item_name}:
                            </label>
                            <div className="col-sm-8 fw-semibold">
                              <p style={{ color: "#414248" }}>
                                {user[config.item_name]}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="row">
                        <label className="col-sm-4">Total Expense:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>
                            {numDifferentiation(
                              materialReceivedAmount.toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-sm-4">
                          Total Material Weight:
                        </label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>
                            {numDifferentiation(materialWeight)}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3 fw-semibold"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Party Expenses
                  <div className="d-flex">
                    <Button
                      id="dataExport"
                      color="primary"
                      style={{
                        marginRight: "10px",
                      }}
                      onClick={exportToExcel}
                      disabled={partyExpenseLoading}
                    >
                      {partyExpenseLoading && (
                        <Spinner
                          style={{ height: "0.9rem", width: "0.9rem" }}
                        />
                      )}
                      {!partyExpenseLoading && (
                        <i className="mdi mdi-download"></i>
                      )}
                    </Button>
                  </div>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {transaction?.length === 0 && (
                          <tr>
                            <td colSpan={12}>
                              <EmptyView
                                title={"Sorry!"}
                                discription={"No Data Found"}
                                bgcolor={"white"}
                              ></EmptyView>
                            </td>
                          </tr>
                        )}
                        {transaction?.map((item, index) => (
                          <GetDataList
                            data={item}
                            key={index}
                            index={index}
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            dataKey={configData}
                          />
                        ))}
                      </>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default PartyDetails;

const GetDataList = (props) => {
  const { data, index } = props;
  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {numDifferentiation(
            !Number.isInteger(data.amount) && typeof data.amount === "number"
              ? data.amount.toFixed(2)
              : data.amount
          )}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.material_received_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{data.note}</td>
        <td className="text-center"></td>
      </tr>
    </>
  );
};

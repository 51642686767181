import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import { submitForm } from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import moment from "moment/moment.js";

const form = "PROJECT";
const AddProject = (props) => {
  const labels = props.labels;
  const [loading, setLoading] = useState(false);

  let validationObject = {
    name: Yup.string()
      .trim()
      .required(`Please Enter ${props.title} Name`)
      .max(25, "Maximum 25 Character Allowed"),
    area: Yup.number()
      .required(`Please Enter Project Area in Square Meter`)
      .min(0, "Please Enter Valid Area")
      .max(100000, "Exceed Maximum Area Limit"),
  };
  let initialValues = { name: "", area: "" };
  labels.map((label) => {
    if (label.item_type === "Date") {
      initialValues = {
        ...initialValues,
        [label.item_name]: moment().format("YYYY-MM-DD").toString(),
      };
    } else {
      initialValues = { ...initialValues, [label.item_name]: "" };
    }
    if (label.is_required)
      return (validationObject = {
        ...validationObject,
        [label.item_name]: Yup.string()
          .required(`Please enter ${label.item_name}`)
          .max(100, "Maximum 100 Character Allowed"),
      });
    else return null;
  });

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    setLoading(true);
    submitForm(form, values)
      .then((res) => {
        props.handleCloseModal();
        setLoading(false);
        notifySuccess("Project Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
            }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    {props.title} Name <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={`Enter ${props.title} Name`}
                    name="name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Area (in Square Meter) <Required />
                  </label>
                  <CustomInput
                    type="number"
                    values={values}
                    placeholder={`Please Enter Project Area`}
                    name="area"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                {labels.map((label, index) => {
                  return (
                    <div style={{ height: "100px" }} key={index}>
                      <label>
                        {label.item_name} {label.is_required && <Required />}
                      </label>
                      <CustomInput
                        type={label.item_type}
                        values={values}
                        // required={label.is_required}
                        placeholder={"Enter " + label.item_name}
                        name={label.item_name}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                  );
                })}
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddProject;

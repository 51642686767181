import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Table, Tooltip } from "reactstrap";
import { getPartiesOverview } from "../../services/userService";
import { numDifferentiation } from "../../helpers/amount_helper";
import { Link } from "react-router-dom";

const PartyOverview = (props) => {
  const [partyData, setPartyData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  useEffect(() => {
    const partyOverviewData = () => {
      let query = `?project_id=${props.projectId}`;
      getPartiesOverview(query).then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setPartyData(data);
      });
    };
    partyOverviewData();
  }, [props.projectId]);

  return (
    <>
      <Card>
        <CardBody style={{ overflowX: "scroll" }}>
          <CardTitle
            className="h3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {" "}
            Party Overview
            <div className="d-flex">
              <Button
                onClick={() => {
                  props.handleChangeDisplay("Department");
                }}
              >
                Department Overview
              </Button>
            </div>
          </CardTitle>
          <Table hover>
            <thead>
              <tr>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}> Party Name</h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}>
                    {" "}
                    Total Material Received Amount
                  </h6>
                </th>
                <th className="text-center">
                  <h6 style={{ fontWeight: "bold" }}> Details</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {partyData.map((department, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{department.name}</td>
                    <td className="text-center">
                      {numDifferentiation(department.totalExpense.toFixed(2))}
                    </td>
                    <td className="text-center">
                      <Tooltip
                        placement="bottom"
                        isOpen={tooltipOpen}
                        target="filters"
                        toggle={() => {
                          setTooltipOpen(!tooltipOpen);
                        }}
                      >
                        Expenses
                      </Tooltip>
                      <Link
                        to={`/party-expense-details?party_id=${department._id}&party=${department.name}&project_id=${props.projectId}`}
                      >
                        <i
                          id="filters"
                          className="mdi mdi-open-in-new font-size-20"
                          style={{ marginRight: 10, cursor: "pointer" }}
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default PartyOverview;

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomInput, { Required } from "../../../components/Custom/textinput.js";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";

import {
  updateLandPayments,
} from "../../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../../components/Custom/notification.js";
import moment from "moment";

const paymentModeOptions = [
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "Online", label: "Online" },
];
const UpdateLandPayment = (props) => {
  const defaultData = props.data;

  const [paymentMode, setPaymentMode] = useState({
    value: defaultData.payment_mode,
    label: defaultData.payment_mode,
  });

  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  const handleSubmit = (values) => {
    let data = {
      ...values,
      payment_mode: paymentMode.value,
    };

    updateLandPayments(defaultData._id, data)
      .then((res) => {
        props.toggle();
        notifySuccess("Payment Updated Successfully");
      })
      .catch((error) => {
        notifyError(error.data.error.message);
      });
  };

  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    return errors;
  };

  let validationObject = {
    payment_date: Yup.date().required("Please Enter Date"),
    notes: Yup.string().required("Please Enter Note"),
    amount: Yup.number().required("Please Enter Amount"),
  };

  let submitFormSchema = Yup.object().shape(validationObject);

  const initialValues = {
    payment_mode: defaultData.payment_mode,
    amount: defaultData.amount,
    payment_date: moment(defaultData.payment_date).format("YYYY-MM-DD"),
    notes: defaultData.notes,
  };

  return (
    <>
      <Modal isOpen={props.modal} centered={true} style={{ width: "100%" }}>
        <ModalHeader toggle={props.toggle}>Update Land Payments</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={submitFormSchema}
            validate={validationFunction}
          >
            {({ errors, touched, values, handleChange, setFieldError }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    Amount <Required />
                  </label>
                  <CustomInput
                    type="number"
                    values={values}
                    placeholder={"Enter Amount"}
                    name="amount"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Mode <Required />
                  </label>
                  <Select
                    options={paymentModeOptions}
                    name="payment_mode"
                    value={paymentMode}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldError("payment_mode", "");
                      handlePaymentModeChange(value);
                    }}
                    placeholder={"Select Payment Mode"}
                  />
                  {errors.payment_mode && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.payment_mode}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Date <Required />
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    placeholder={"Select Date"}
                    name="payment_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Note <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={"Enter Note"}
                    name="notes"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateLandPayment;

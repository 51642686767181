import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Row, Table } from "reactstrap";
import { getPartyListData } from "../../services/userService";
import EmptyView from "../../components/Custom/EmptyView";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Custom/Loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { numDifferentiation } from "../../helpers/amount_helper";

const PartyByDepartment = () => {
  let params = window.location.search;
  let department_id = new URLSearchParams(params).get("department_id");
  let project_id = new URLSearchParams(params).get("project_id");
  let departmentName = new URLSearchParams(params).get("department");
  const history = useHistory();
  const [partyList, setPartyList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPartyListData(department_id, project_id).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      setPartyList(data);
      setLoading(false);
    });
  }, [department_id, project_id]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{departmentName}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Party List</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  Party List
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Party Name</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Material Weight</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Phone Number</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Notes</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Details</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && partyList?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Booked Unit Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {partyList.map((party, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            {party.name}
                          </td>
                          <td className="text-center">
                            {party.items.some((item) => item.Quantity !== 0) ? (
                              party.items.map(
                                (item, itemIndex) =>
                                  item.Quantity !== 0 && (
                                    <div key={itemIndex}>
                                      <p>
                                        {item.itemNames}:
                                        <span style={{ color: "black" }}>
                                          {numDifferentiation(item.Quantity)}
                                        </span>
                                      </p>
                                    </div>
                                  )
                              )
                            ) : (
                              <div>
                                <p>-</p>
                              </div>
                            )}
                          </td>
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                          >
                            {party["Phone No"]}
                          </td>
                          <td className="text-center">{party["Notes"]}</td>
                          <td className="text-center">
                            <OverlayTrigger
                              key="bottom"
                              placement="bottom"
                              overlay={
                                <Tooltip
                                  id="tooltip-bottom"
                                  className="font-family-class font-12 fw-normal line-height-17 text-white"
                                >
                                  Party Details
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/party-details?party_id=${party._id}&department_id=${department_id}&project_id=${project_id}&department=${departmentName}&total=${party.totalQuantity}`}
                              >
                                <i
                                  id="filters"
                                  className="mdi mdi-open-in-new font-size-20"
                                  style={{ marginRight: 10, cursor: "pointer" }}
                                />
                              </Link>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PartyByDepartment;

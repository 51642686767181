import { Form, Formik } from "formik";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import Select from "react-select";
import {
  getFormData,
  updateEmployeeSalary,
} from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

const UpdateEmployeeSalary = (props) => {
  const defaultData = props.data;
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
  ];

  const [projectOptions, setProjectOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState({
    label: defaultData.employee_id ? defaultData.employee_id.name : "",
    value: defaultData.employee_id ? defaultData.employee_id._id : "",
  });
  const [project, setProject] = useState(props.data.project_id.map((proj) => ({label: proj.name, value: proj._id})));
  const [paymentMode, setPaymentMode] = useState(
    paymentModeOptions.filter((e) => e.value === defaultData.payment_mode)
  );

  const getAllProjects = () => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((project) => {
        return { label: project.name, value: project._id };
      });
      setProjectOptions(options);
    });
  };

  const getAllEmployees = () => {
    getFormData("EMPLOYEE").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((employee) => {
        return { label: employee.name, value: employee._id };
      });
      setEmployeeOptions(options);
    });
  };
  useEffect(() => {
    getAllProjects();
    getAllEmployees();
  }, []);

  var date = new Date();
  date.setHours(0, 0, 0, 0);
  let validationObject = {
    amount: Yup.number()
      .required(`Please Enter Amount`)
      .min(0, "Amount Must be Greater Than 0"),
    payment_date: Yup.date().required(`Please Select Payment Date`),
    note: Yup.string().trim().required(`Please Add Note`),
  };

  const validateFunction = () => {
    let errors = {};
    if (project.length === 0) {
      errors.project_id = "Please Select a Project";
    }
    if (paymentMode === "") {
      errors.payment_mode = "Please Select a Payment Mode";
    }

    return errors;
  };

  const initialValues = {
    project_id: defaultData.project_id,
    payment_mode: defaultData.payment_mode,
    employee_id: defaultData.employee_id,
    amount: defaultData.amount,
    payment_date: moment(defaultData.payment_date).format("YYYY-MM-DD"),
    note: defaultData.note,
  };

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    let selectedProject = project.map((e) => e.value);
    let data = {
      ...values,
      project_id: selectedProject,
      employee_id: employee.value,
      payment_mode: paymentMode.value,
    };
    setLoading(true);
    updateEmployeeSalary(defaultData._id, data)
      .then((res) => {
        props.handleCloseModal();
        setLoading(false);
        notifySuccess("Salary Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  //handles
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };
  const handleProjectChange = (value) => {
    setProject(value);
  };
  const handleEmployeeChange = (value) => {
    setEmployee(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Update Staff Salary
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
            validate={validateFunction}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
              setFieldError,
            }) => (
              <Form>
                <div className="d-flex flex-column gap-3 mb-3 mt-3">
                  <div>
                    <label>
                      Project <Required />
                    </label>
                    <Select
                      options={projectOptions}
                      name="project_id"
                      value={project}
                      errors={errors}
                      isMulti={true}
                      touched={touched}
                      onChange={(value) => {
                        setFieldError("project_id", "");
                        handleProjectChange(value);
                      }}
                      placeholder={"Select Project"}
                    />
                    {errors.project_id && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.project_id}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Employee <Required />
                    </label>
                    <Select
                      options={employeeOptions}
                      name="employee_id"
                      value={employee}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldError("employee_id", "");
                        handleEmployeeChange(value);
                      }}
                      placeholder={"Select Employee"}
                    />
                    {errors.employee_id && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.employee_id}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={`Enter Amount`}
                      name="amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldError("payment_mode", "");
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Payment Mode"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Payment Date <Required />
                    </label>
                    <CustomInput
                      type="date"
                      values={values}
                      name="payment_date"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Note <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={`Enter Notes...`}
                      name="note"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>

                  <ModalFooter className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      disabled={loading}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {" "}
                      Update
                    </Button>
                  </ModalFooter>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateEmployeeSalary;

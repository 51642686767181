import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Input, Row, Spinner, Table } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import { PaginationFunction } from "../../components/Custom/Pagination";
import {
  getAllUnitIncomes,
  getAllCustomerRefund,
  getCustomerDetails,
} from "../../services/userService";
import { getFormFields } from "../../services/adminService";
import { numDifferentiation } from "../../helpers/amount_helper";
import Loader from "../../components/Custom/Loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const form = "UNIT-FORM";
const title = "Customer";

function CustomerDetails() {
  const [user, setUser] = useState({});
  const [userConfig, setUserConfig] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [totalCount, setTotalCount] = useState(0);
  const [transaction, setTransaction] = useState([]);
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [revenueLoading, setRevenueLoading] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [refund, setRefund] = useState([]);
  const [refundActivePage, setRefundActivePage] = useState(1);
  const [refundPageDataCount, setRefundPageDataCount] = useState(30);
  const [refundTotalCount, setRefundTotalCount] = useState(0);
  const [refundAmount, SetRefundAmount] = useState(0);
  const [revenueExportLoading, setRevenueExportLoading] = useState(false)
  const [refundExportLoading, setRefundExportLoading] = useState(false)
  let params = window.location.search;
  let id = new URLSearchParams(params).get("id");
  useEffect(() => {
    setRevenueLoading(true);
    const pageAndLimit = `?customer_id=${id}&page=${activePage}&limit=${pageDataCount}`;
    getAllUnitIncomes(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setTransaction(data);
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setRevenueLoading(false);
      })
      .catch((err) => {
        setRevenueLoading(false);
      });
  }, [activePage, pageDataCount]);

  useEffect(() => {
    setRefundLoading(true);
    const pageAndLimit = `?customer_id=${id}&page=${refundActivePage}&limit=${refundPageDataCount}`;
    getAllCustomerRefund(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setRefund(data);
        SetRefundAmount(res.data.data.totalAmount);
        setRefundActivePage(data.length === 0 ? 1 : refundActivePage);
        setRefundTotalCount(res.data.data.totalCount);
        setRefundLoading(false);
      })
      .catch((err) => {
        setRefundLoading(false);
      });
  }, [refundActivePage, refundTotalCount]);

  useEffect(() => {
    getAllLinks();
  }, []);
  const getAllLinks = () => {
    setLoading(true);
    Promise.all([
      getFormFields(form),
      getFormFields("CUSTOMER-FORM"),
      getCustomerDetails(id),
    ])
      .then(([response1, response2, response3]) => {
        const data1 = response1 && response1.data && response1.data.data;
        setConfigData(data1);

        const data2 = response2 && response2.data && response2.data.data;
        setUserConfig(data2);

        if (!response3.data.data.error) setUser(response3.data.data.data);
      })
      .catch((error) => {
        console.log("getAllLinks error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const customerRevenueExportToExcel = () => {
    setRevenueExportLoading(true);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let pagination = `?customer_id=${id}`

    getAllUnitIncomes(pagination)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        data = data.map((data) => {
          return {
            "Project Name": data.project,
            "Unit": data.unit,
            "Customer Name": data.customer,
            "Amount": data.amount,
            "Payment Mode": data.payment_mode,
            "Payment Date": moment(data.payment_date).format("DD-MM-YYYY"),
            "Note": data.note,
          };
        });

        const header = [
            "Project Name",
            "Unit",
            "Customer Name",
            "Amount",
            "Payment Mode",
            "Payment Date",
            "Note",
        ]

        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.sheet_add_aoa(ws, [header]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, "customer_revenue" + fileExtension);
        setRevenueExportLoading(false);
      })
      .catch((err) => {
        setRevenueExportLoading(false);
      });
  }

  const customerRefundExportToExcel = () => {
    setRefundExportLoading(true);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let pagination = `?customer_id=${id}`

    getAllCustomerRefund(pagination)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        data = data.map((data) => {
          return {
            "Project Name": data.project,
            "Unit": data.unit,
            "Customer Name": data.customer,
            "Amount": data.amount,
            "Payment Mode": data.payment_mode,
            "Payment Date": moment(data.payment_date).format("DD-MM-YYYY"),
            "Note": data.note,
          };
        });

        const header = [
          "Project Name",
          "Unit",
          "Customer Name",
          "Amount",
          "Payment Mode",
          "Payment Date",
          "Note",
        ]

        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.sheet_add_aoa(ws, [header]);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, "customer_refund" + fileExtension);
        setRefundExportLoading(false);
      })
      .catch((err) => {
        setRefundExportLoading(false);
      });
  }

  const handleChangeCustomerRevenue = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const handleChangeCustomerRefund = (e) => {
    setRefundActivePage(1);
    setRefundPageDataCount(Number(e.target.value));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">{title} Management</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3 fw-semibold"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title} Details
                </CardTitle>
                {loading ? (
                  <Loader />
                ) : (
                  Object.keys(user).length > 0 && (
                    <div className="d-flex flex-column d-grid gap-2 mt-4">
                      <div className="row">
                        <label className="col-sm-4">Name:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>{user["name"]}</p>
                        </div>
                      </div>
                      {userConfig?.map((config, index) => {
                        return (
                          <div className="row">
                            <label className="col-sm-4">
                              {config.item_name}:
                            </label>
                            <div className="col-sm-8 fw-semibold">
                              <p style={{ color: "#414248" }}>
                                {user[config.item_name]}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="row">
                        <label className="col-sm-4">Booking Amount:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>
                            {numDifferentiation(
                              user["booking_amount"].toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-sm-4">Paid Amount:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>
                            {numDifferentiation(user["paid_amount"].toFixed(2))}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-sm-4">Refund Amount:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p style={{ color: "#414248" }}>
                            {numDifferentiation(refundAmount.toFixed(2))}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-sm-4">Outstanding Amount:</label>
                        <div className="col-sm-8 fw-semibold">
                          <p
                            style={{
                              color:
                                Number(
                                  user["booking_amount"] -
                                    user["paid_amount"] +
                                    refundAmount
                                ) > 0
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {numDifferentiation(
                              Number(
                                user["booking_amount"] -
                                  user["paid_amount"] +
                                  refundAmount
                              ).toFixed(2)
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3 fw-semibold"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Customer Revenue
                  <div className="d-flex">
                    <Button
                      id="customerRevenueDataExport"
                      color="primary"
                      onClick={customerRevenueExportToExcel}
                      disabled={revenueExportLoading}
                    >
                      {revenueExportLoading && (
                        <Spinner
                          style={{ height: "0.9rem", width: "0.9rem" }}
                        />
                      )}
                      {!revenueExportLoading && (
                        <i className="mdi mdi-download"></i>
                      )}
                    </Button>
                  </div>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Unit</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Customer Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {revenueLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {transaction?.length === 0 && (
                          <tr>
                            <td colSpan={12}>
                              <EmptyView
                                title={"Sorry!"}
                                discription={"No Unit Income Found"}
                                bgcolor={"white"}
                              ></EmptyView>
                            </td>
                          </tr>
                        )}
                        {transaction?.map((item, index) => (
                          <GetDataList
                            data={item}
                            key={index}
                            index={index}
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            dataKey={configData}
                          />
                        ))}
                      </>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={(number) => setActivePage(number)}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChangeCustomerRevenue(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3 fw-semibold"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Customer Refund
                  <div className="d-flex">
                    <Button
                      id="customerRefundDataExport"
                      color="primary"
                      onClick={customerRefundExportToExcel}
                      disabled={refundExportLoading}
                    >
                      {refundExportLoading && (
                        <Spinner
                          style={{ height: "0.9rem", width: "0.9rem" }}
                        />
                      )}
                      {!refundExportLoading && (
                        <i className="mdi mdi-download"></i>
                      )}
                    </Button>
                  </div>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Unit</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Customer Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {refundLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {refund?.length === 0 && (
                          <tr>
                            <td colSpan={12}>
                              <EmptyView
                                title={"Sorry!"}
                                discription={"No Unit Income Found"}
                                bgcolor={"white"}
                              ></EmptyView>
                            </td>
                          </tr>
                        )}
                        {refund?.map((item, index) => (
                          <GetRefundList
                            data={item}
                            key={index}
                            index={index}
                            activePage={refundActivePage}
                            pageDataCount={refundTotalCount}
                            dataKey={configData}
                          />
                        ))}
                      </>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={refundActivePage}
                            pageDataCount={refundPageDataCount}
                            totalCount={refundTotalCount}
                            handlePageChange={(number) => setRefundActivePage(number)}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChangeCustomerRefund(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default CustomerDetails;

const GetDataList = (props) => {
  const { data, index } = props;
  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.project}</td>
        <td className="text-center">{data.unit}</td>
        <td className="text-center">{data.customer}</td>
        <td className="text-center">
          {numDifferentiation(
            !Number.isInteger(data.amount) && typeof data.amount === "number"
              ? data.amount.toFixed(2)
              : data.amount
          )}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{data.note}</td>
        <td className="text-center"></td>
      </tr>
    </>
  );
};

const GetRefundList = (props) => {
  const { data, index } = props;
  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.project}</td>
        <td className="text-center">{data.unit}</td>
        <td className="text-center">{data.customer}</td>
        <td className="text-center">
          {numDifferentiation(
            !Number.isInteger(data.amount) && typeof data.amount === "number"
              ? data.amount.toFixed(2)
              : data.amount
          )}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{data.note}</td>
        <td className="text-center"></td>
      </tr>
    </>
  );
};

import React from "react";
import { Row } from "reactstrap";
import RawMaterialExpense from "./RawMaterialExpense";

import { useState } from "react";
import PartyOverview from "./PartyOverview";
const RawMaterialOverview = (props) => {
  let params = window.location.search;
  let id = new URLSearchParams(params).get("project_id");
  let projectName = new URLSearchParams(params).get("project");

  const [display, setDisplay] = useState("Department");

  const handleChangeDisplay = (value) => {
    setDisplay(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">
                {" "}
                Raw Material Overview
              </h4>
              <div className="page-title-middle">
                <h3 className="page-title mb-0 font-size-18">
                  {" "}
                  {projectName}{" "}
                </h3>
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Raw Material Overview
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            {display === "Department" && (
              <RawMaterialExpense
                projectId={id}
                handleChangeDisplay={handleChangeDisplay}
              />
            )}
            {display === "Party" && (
              <PartyOverview
                projectId={id}
                handleChangeDisplay={handleChangeDisplay}
              />
            )}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default RawMaterialOverview;

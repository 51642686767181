import React from "react";
import TableDetails from "./TableDetails";

const InterestDetails = () => {
  return (
    <div>
      <TableDetails isLoanTable={true} loanTitle={"Loan (Rs Take)"} />
      <TableDetails loanTitle={"Borrow (Rs Give)"} />
    </div>
  );
};

export default InterestDetails;

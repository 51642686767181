import React, { useState } from "react";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import AddCustomerRevenue from "./CustomerRevenue";
import AddCustomerRefund from "./CustomerRefund";
const title = "Customer Revenue";

const AddRevenue = (props) => {
  const [page, setPage] = useState("revenue");

  const currentComponent = () => {
    switch (page) {
      case "revenue":
        return <AddCustomerRevenue />;

      case "refund":
        return <AddCustomerRefund />;

      default:
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="page-title mb-0 font-size-18">{title}</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  Daily Entries / {title}
                </li>
              </ol>
            </div>
          </div>
          <div className="card">
            <Col md={8} lg={12}>
              <Row>
                <Col lg={12}>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPage("revenue");
                        }}
                        to="#"
                        className={page === "revenue" ? "active" : ""}
                      >
                        <span className="d-none d-sm-block">
                          Customer Revenue
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setPage("refund");
                        }}
                        to="#"
                        className={page === "refund" ? "active" : ""}
                      >
                        <span className="d-none d-sm-block">
                          Customer Refund
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Col>
            {currentComponent()}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AddRevenue;

import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import { updateFormData } from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";

const form = "EMPLOYEE";
const UpdateEmployee = (props) => {
  const labels = props.labels;
  const defaultData = props.data;
  const [loading, setLoading] = useState(false);

  let validationObject = {
    name: Yup.string()
      .trim()
      .required(`Please Enter ${props.title} Name`)
      .max(25, "Maximum 25 Character Allowed"),
  };

  let initialValues = {
    name: defaultData.name,
  };
  labels.map((label) => {
    initialValues = {
      ...initialValues,
      [label.item_name]: defaultData[label.item_name]
        ? defaultData[label.item_name]
        : "",
    };
    if (label.is_required)
      return (validationObject = {
        ...validationObject,
        [label.item_name]:
          label.item_type === "Number"
            ? Yup.number()
                .required(`Please Enter ${label.item_name}`)
                .max(10000000000, "Number should be less than 10 digit")
            : Yup.string()
                .trim()
                .required(`Please enter ${label.item_name}`)
                .max(50, "Maximum 50 Character Allowed"),
      });
    else return null;
  });

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    setLoading(true);
    updateFormData(form, defaultData._id, values)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        notifySuccess("Employee Updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Update {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
            }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    {props.title} Name <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={`Enter ${props.title} Name`}
                    name="name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                {labels.map((label, index) => {
                  return (
                    <div style={{ height: "100px" }} key={index}>
                      <label>
                        {label.item_name} {label.is_required && <Required />}
                      </label>
                      <CustomInput
                        type={label.item_type}
                        values={values}
                        placeholder={"Enter " + label.item_name}
                        name={label.item_name}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                  );
                })}
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateEmployee;

import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Button, CardTitle, Table } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import {
  deleteUser,
  getAllUsersList,
  getFormFields,
  updateUserAccess,
} from "../../services/adminService";
import AddUser from "./AddUser";
// import { PaginationFunction } from "../../components/Custom/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import UpdateUser from "./UpdateUser";
import { notifySuccess } from "../../components/Custom/notification";
import { getFormData } from "../../services/userService";
import Loader from "../../components/Custom/Loader";

const form = "PROJECT-FORM";
const title = "Users";

const Users = () => {
  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const getAllProjects = () => {
    setLoading(true);
    getAllUsersList().then((res) => {
      let data = res && res.data && res.data.data;
      setUsers(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getAllLinks();
    getAllProjects();
  }, [toggle]);

  useEffect(() => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      setProjectsData(data);
    });
  }, []);
  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("----error----", err);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">All Users</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {title} / All Users
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title} List
                  <div className="d-flex">
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                  </div>
                  {addModal && (
                    <AddUser
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                      form={form}
                      projectsData={projectsData}
                      labels={configData}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>User Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Email</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Password</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>User Permisions</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && users?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Users Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {users?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        projectsData={projectsData}
                        dataKey={configData}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default Users;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [managementModal, setManagementModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);

  const managementAccess = {
    management: data.management === true ? false : true,
  };

  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const handleShowManagementModal = () => {
    setManagementModal(!managementModal);
  };
  const handleShowExpenseModal = () => {
    setExpenseModal(!expenseModal);
    props.toggle();
  };

  const handleOnDelete = () => {
    deleteUser(data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("User Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this User?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };
  const handleOnUpdate = (values, cancelModal) => {
    updateUserAccess(data._id, values).then((res) => {
      notifySuccess("User Permissions Updated");
      cancelModal();
      props.toggle();
    });
  };

  const userAccessFuction = (data, cancelModal) => {
    return (
      <SweetAlert
        title={`Are you sure you want to update?`}
        showCancel
        confirmBtnText="Update!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnUpdate(data, cancelModal);
        }}
        onCancel={cancelModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">{data.name}</td>
        <td className="text-center">{data.email}</td>
        <td className="text-center">{data.password}</td>
        <td className="text-center">
          {data.expenses === true ? (
            <i
              id="primary"
              className="mdi mdi-check"
              style={{ fontSize: 20, color: "green", cursor: "pointer" }}
              onClick={handleShowExpenseModal}
            ></i>
          ) : (
            <i
              id="primary"
              className="fa fa-info"
              style={{ fontSize: 20, color: "#00b3f4", cursor: "pointer" }}
              onClick={handleShowExpenseModal}
            ></i>
          )}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateUser
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              form={form}
              data={props.data}
              projectsData={props.projectsData}
              labels={props.dataKey}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
          {managementModal &&
            userAccessFuction(managementAccess, handleShowManagementModal)}
          {/* {expenseModal &&
            userAccessFuction(expenseAccess, handleShowExpenseModal)} */}
          {expenseModal && (
            <UpdateUser
              modal={expenseModal}
              title={title}
              handleCloseModal={handleShowExpenseModal}
              form={form}
              data={props.data}
              projectsData={props.projectsData}
              labels={props.dataKey}
              permissions={true}
            />
          )}
        </td>
      </tr>
    </>
  );
};

import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = ({ style }) => {
  return (
    <div className="loader" style={{ background: "none" }}>
      <Spinner animation="border" style={style} />
    </div>
  );
};

export default Loader;

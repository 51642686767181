import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { createUser } from "../../services/adminService.js";

const AddUser = (props) => {
  const [state, setState] = useState({
    management: false,
    customer_revenue: false,
    general_bills: false,
    party_payment: false,
    raw_material_bills: false,
    staff_salary: false,
    cancel_booking: false,
    dashboards: false,
    lands:false,
  });

  const [error, setError] = useState(true);

  let validationObject = {
    name: Yup.string().trim().required(`Please Enter User Name`),
    email: Yup.string()
      .trim()
      .email("Enter valid e-mail")
      .required("Please enter e-mail address")
      .max(25, "Maximum 25 Character Allowed"),
    password: Yup.string()
      .required("Please enter password")
      .min(8, "Password must be at least 8 character")
      .max(16, "Maximum Password Length is 16 character"),
    confirm_password: Yup.string()
      .required("Please enter confirm password")
      .min(8, "Password must be at least 8 character"),
  };
  let initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirm_password: "",
    management: false,
    customer_revenue: false,
    general_bills: false,
    party_payment: false,
    raw_material_bills: false,
    staff_salary: false,
    cancel_booking: false,
    dashboard_projects: [],
  };

  const handleRadioChange = (e) => {
    setError(false);
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };
  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    if (error) {
      return setFieldError("error", "Please Select Any Permissions To User");
    }
    const data = { ...values, ...state, role: "user" };
    if (values.password !== values.confirm_password) {
      return setFieldError(
        "confirm_password",
        "Password And Confirm Password Must Be Same"
      );
    }
    createUser(data)
      .then((res) => {
        props.handleCloseModal();
        notifySuccess("User Created Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "40%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>Create User</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setValues,
              resetForm,
            }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    {" "}
                    Name <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={`Enter User Name`}
                    name="name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    {" "}
                    Email <Required />
                  </label>
                  <CustomInput
                    type="email"
                    values={values}
                    placeholder={`Enter Email`}
                    name="email"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Password <Required />
                  </label>
                  <CustomInput
                    type="password"
                    values={values}
                    placeholder={`Enter Password`}
                    name="password"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Confirm Password <Required />
                  </label>
                  <CustomInput
                    type="password"
                    values={values}
                    placeholder={`Confirm Password Must Be Same As Password`}
                    name="confirm_password"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div className="">
                  <label style={{ fontWeight: "bold" }}>PERMISSIONS :</label>
                  <div>
                    <label>Management Permissions:</label>
                    <div>
                      <Input
                        className="form-check-input mx-2"
                        type="checkbox"
                        name="management"
                        value={state.management}
                        checked={state.management}
                        onChange={handleRadioChange}
                      />
                      <label style={{ width: "9rem" }}>Management</label>
                    </div>
                  </div>
                  <div>
                    <label>Expense Permissions:</label>
                    <div className="permissions-menu">
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="customer_revenue"
                          value={state.customer_revenue}
                          checked={state.customer_revenue}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Customer Revenue
                        </label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="general_bills"
                          value={state.general_bills}
                          checked={state.general_bills}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>General Bills</label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="raw_material_bills"
                          value={state.raw_material_bills}
                          checked={state.raw_material_bills}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Raw Material Bill
                        </label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="party_payment"
                          value={state.party_payment}
                          checked={state.party_payment}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Party Paid Bills
                        </label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="cancel_booking"
                          value={state.cancel_booking}
                          checked={state.cancel_booking}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>
                          Booking Cancelation
                        </label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="staff_salary"
                          value={state.staff_salary}
                          checked={state.staff_salary}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>Staff Salary</label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="dashboards"
                          value={state.dashboards}
                          checked={state.dashboards}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>Dashboards</label>
                      </div>
                      <div className="d-flex">
                        <Input
                          className="form-check-input mx-2"
                          type="checkbox"
                          name="lands"
                          value={state.lands}
                          checked={state.lands}
                          onChange={handleRadioChange}
                        />
                        <label style={{ width: "9rem" }}>Lands</label>
                      </div>
                    </div>
                  </div>
                  {errors.error && (
                    <div className="text-danger">{errors.error}</div>
                  )}
                  {state.dashboards && (
                    <div>
                      <label>Project Permissions:</label>
                      <div className="">
                        {props.projectsData &&
                          props.projectsData.length > 0 &&
                          props.projectsData.map((project, index) => (
                            <div key={index} className="d-flex">
                              <Input
                                className="form-check-input mx-2"
                                type="checkbox"
                                name="dashboard_projects"
                                value={project._id}
                                checked={values.dashboard_projects.includes(
                                  project._id
                                )}
                                onChange={(e) => {
                                  const projectId = e.target.value;
                                  const isChecked = e.target.checked;
                                  if (isChecked) {
                                    // add the projectId to the dashboard_projects array
                                    setValues((values) => ({
                                      ...values,
                                      dashboard_projects: [
                                        ...values.dashboard_projects,
                                        projectId,
                                      ],
                                    }));
                                  } else {
                                    // remove the projectId from the dashboard_projects array
                                    setValues((values) => ({
                                      ...values,
                                      dashboard_projects:
                                        values.dashboard_projects.filter(
                                          (id) => id !== projectId
                                        ),
                                    }));
                                  }
                                }}
                              />
                              <label>{project.name}</label>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddUser;

import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Card, CardBody, CardTitle, Row, Table } from "reactstrap";
import { numDifferentiation } from "../../helpers/amount_helper";
import Loader from "../../components/Custom/Loader";
import SweetAlert from "react-bootstrap-sweetalert";
import EmptyView from "../../components/Custom/EmptyView";
import { PaginationFunction } from "../../components/Custom/Pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  deleteBorrowPayment,
  deleteLoanPayment,
  getBorrowDetail,
  getBorrowPaymentDetail,
  getLoanDetail,
  getLoanPaymentDetail,
} from "../../services/userService";
import { useLocation } from "react-router-dom";
import AddPayment from "./AddPaymentModal";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";

const ViewDetails = () => {
  const route = useLocation();
  const DetailId = route.pathname.split("/")[3];
  const detailsPage = route.pathname.split("/")[2];
  const title = `${detailsPage === "loan" ? "Loan" : "Borrow"} Detail`;
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loanData, setLoanData] = useState([]);
  const [loanPaymentData, setLoanPaymentData] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [addPaymentModal, setAddModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [showRemainingAmount, setShowRemainingAmount] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const handleOnCancel = () => {
    setLoading(true);
    handleGetLoanPaymentDetail();
  };

  useEffect(() => {
    detailsPage === "loan" ? handleGetLoanDetail() : handleGetBorrowDetail();
  }, [loanPaymentData]);

  const handleGetLoanDetail = () => {
    getLoanDetail(DetailId)
      .then((res) => {
        let data = res?.data?.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(data.length);
        setLoanData(data);
        if (data?.data[0]?.remaning_intrest > 0) {
          setShowRemainingAmount(true);
        } else {
          setShowRemainingAmount(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleGetLoanPaymentDetail = () => {
    getLoanPaymentDetail(DetailId)
      .then((res) => {
        let data = res?.data?.data?.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(data.length);
        setLoanPaymentData(data);
      })
      .catch((err) => {
        setLoanPaymentData(false);
      });
  };

  const handleGetBorrowPaymentDetail = () => {
    getBorrowPaymentDetail(DetailId)
      .then((res) => {
        let data = res?.data?.data?.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(data.length);
        setLoanPaymentData(data);
      })
      .catch((err) => {
        setLoanPaymentData(false);
      });
  };
  const handleGetBorrowDetail = () => {
    getBorrowDetail(DetailId)
      .then((res) => {
        let data = res?.data?.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(data.length);
        setLoanData(data);
        if (data?.data[0]?.remaning_intrest > 0) {
          setShowRemainingAmount(true);
        } else {
          setShowRemainingAmount(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (detailsPage === "loan") {
      handleGetLoanPaymentDetail();
    } else {
      handleGetBorrowPaymentDetail();
    }
  }, [activePage, pageDataCount, search, toggle]);
  useEffect(() => {
    if (detailsPage === "loan") {
      handleGetLoanDetail();
    } else {
      handleGetBorrowDetail();
    }
  }, []);

  const showAddConfigModal = () => {
    setAddModal(!addPaymentModal);
    callToggle();
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title="Are you sure you want to cancel booking?"
        danger
        showCancel
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="dark"
        onConfirm={handleOnCancel}
        onCancel={() => {
          setSearch(false);
          setCancel(!cancel);
        }}
        disabled={loading}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Current {title}
                  <br />
                  <br />
                </CardTitle>
                <Table>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: "10%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Party Name</h6>
                      </th>
                      <th className="text-center" style={{ width: "8%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Mobile Number</h6>
                      </th>
                      <th className="text-center" style={{ width: "10%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Loan Amount</h6>
                      </th>
                      <th className="text-center" style={{ width: "10%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount ( Int. )</h6>
                      </th>
                      <th className="text-center" style={{ width: "12%" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Interest Rate ( % )
                        </h6>
                      </th>
                      {showRemainingAmount && (
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>
                            Remaining Interest Amount
                          </h6>
                        </th>
                      )}
                      <th className="text-center" style={{ width: "8%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Interest Days</h6>
                      </th>
                      <th className="text-center" style={{ width: "8%" }}>
                        <h6 style={{ fontWeight: "bold" }}>Interest Amount</h6>
                      </th>
                      <th className="text-center" style={{ width: "10%" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Outstanding Payment Amount
                        </h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && loanData.length === 0 ? (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No loan Data Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    ) : (
                      <RenderLoanDetail
                        data={loanData}
                        title={title}
                        toggle={callToggle}
                        showRemainingAmount={showRemainingAmount}
                      />
                    )}
                  </tbody>
                  <tfoot>
                    <tr key="">
                      <td colSpan={55}>{loading && <Loader />}</td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
            <>
              <Card>
                <CardBody>
                  <CardTitle
                    className="h3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Party Payment List
                    <br />
                    <br />
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        name="btn"
                        className="custombtn mr-2"
                        style={{
                          marginRight: 10,
                        }}
                        color="primary"
                        onClick={showAddConfigModal}
                      >
                        Add Payment
                      </Button>
                      {addPaymentModal && (
                        <AddPayment
                          modal={addPaymentModal}
                          title={"Add Payment"}
                          handleCloseModal={showAddConfigModal}
                          detailsPage={detailsPage}
                        />
                      )}
                    </div>
                  </CardTitle>
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Date</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Pending Amount</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Interest Days</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Paid Amount</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Note</h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>
                            Outstanding Amount
                          </h6>
                        </th>
                        <th className="text-center" style={{ width: "10%" }}>
                          <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanPaymentData.length > 0 ? (
                        loanPaymentData.map((item, index) => (
                          <RenderLoanPaymentDetail
                            data={item}
                            key={index}
                            index={index}
                            title={title}
                            toggle={callToggle}
                            detailsPage={detailsPage}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <EmptyView
                              title={"Sorry!"}
                              discription={"No Data Found"}
                              bgcolor={"white"}
                            ></EmptyView>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    <tfoot>
                      <tr key="">
                        <td colSpan={55}>{loading && <Loader />}</td>
                      </tr>
                    </tfoot>
                  </Table>

                  {cancel && sweetAlertFuction()}
                </CardBody>
              </Card>
            </>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ViewDetails;

const RenderLoanDetail = ({ data, showRemainingAmount }) => {
  return (
    <>
      <tr>
        <td className="text-center">{data.data[0].party_name}</td>
        <td className="text-center">{data.data[0].mobile_number}</td>
        <td className="text-center">
          {numDifferentiation(data.data[0].total_amount.toFixed(2) || "0")}
        </td>
        <td className="text-center">
          {numDifferentiation(data.data[0].amount.toFixed(2) || "0")}
        </td>
        <td className="text-center">
          {data.data[0].monthly_interest_rate || "0"}
        </td>
        {showRemainingAmount && (
          <td className="text-center">
            {numDifferentiation(data.data[0]?.remaning_intrest.toFixed(2))}
          </td>
        )}
        <td className="text-center">{data?.intrest_days}</td>
        <td className="text-center">
          {numDifferentiation(data?.intrest_ammount.toFixed(2))}
        </td>
        <td className="text-center">
          {numDifferentiation(data?.Total_calculated_amount.toFixed(2))}
        </td>
      </tr>
    </>
  );
};

const RenderLoanPaymentDetail = ({ data, index, toggle, detailsPage }) => {
  // const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // const handleShowUpdateModal = () => {
  //   setUpdateModal(!updateModal);
  //   toggle();
  // };

  const handleShowDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = (data) => {
    if (detailsPage === "loan") {
      deleteLoanPayment(data._id)
        .then((res) => {
          handleShowDeleteModal();
          toggle();
          notifySuccess("Field Deleted Successfully");
        })
        .catch((err) => {
          notifyError(err.data.error.message);
        });
      setDeleteModal(false);
    } else {
      deleteBorrowPayment(data._id)
        .then((res) => {
          handleShowDeleteModal();
          toggle();
          notifySuccess("Field Deleted Successfully");
        })
        .catch((err) => {
          notifyError(err.data.error.message);
        });
      setDeleteModal(false);
    }
  };

  const sweetAlert = (data) => {
    return (
      <SweetAlert
        title="Are you sure you want to delete this field?"
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => handleOnDelete(data)}
        onCancel={() => handleShowDeleteModal(data)}
      />
    );
  };
  return (
    <>
      <tr>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">{data?.payment_date.split("T")[0]}</td>
        <td className="text-center">
          {numDifferentiation(data?.pending_amount.toFixed(2))}
        </td>
        <td className="text-center">{data?.interest_days}</td>
        <td className="text-center">{numDifferentiation(data?.paid_amount)}</td>
        <td className="text-center">{data?.payment_mode}</td>
        <td className="text-center">{data?.note || "-"}</td>
        <td className="text-center">
          {numDifferentiation(
            (data?.pending_amount - data?.paid_amount).toFixed(2)
          )}
        </td>
        <td className="text-center">
          {data.status === 0 ? (
            <>
              {/* <i
                style={{
                  color: "#134a87",
                  fontSize: 20,
                  cursor: `${data.status ? "not-allowed" : "pointer"}`,
                }}
                id="edit-btn"
                className="mdi mdi-pencil"
                onClick={handleShowUpdateModal}
              />
              {updateModal && (
                <AddPayment
                  modal={updateModal}
                  title={"Update Payment"}
                  handleCloseModal={handleShowUpdateModal}
                  data={data}
                  detailsPage={detailsPage}
                />
              )} */}
              <i
                className="mdi mdi-trash-can"
                id="delete-btn"
                type="submit"
                onClick={handleShowDeleteModal}
                style={{
                  fontSize: 24,
                  color: "rgb(223, 71, 89)",
                  cursor: `${data.status ? "not-allowed" : "pointer"}`,
                }}
              />
              {deleteModal && sweetAlert(data)}{" "}
            </>
          ) : (
            "-"
          )}
        </td>
      </tr>
    </>
  );
};

import React from "react";
import { useState } from "react";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { numDifferentiation } from "../../helpers/amount_helper";

const PartyDetails = (props) => {
  const { party, totalBilledAmount, totalPaidAmount } = props;
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  return (
    <>
      <div>
        <Card>
          <CardBody>
            <CardTitle>Party Details</CardTitle>
            <Table hover>
              <thead>
                <tr>
                  <th className="text-center">
                    <h6 style={{ fontWeight: "bold" }}>Party Name</h6>
                  </th>
                  <th className="text-center">
                    <h6 style={{ fontWeight: "bold" }}>
                      Material Received Amount
                    </h6>
                  </th>
                  <th className="text-center">
                    <h6 style={{ fontWeight: "bold" }}>Material Paid Amount</h6>
                  </th>
                  <th className="text-center">
                    <h6 style={{ fontWeight: "bold" }}>Outstanding Amount</h6>
                  </th>
                  <th className="text-center">
                    <h6 style={{ fontWeight: "bold" }}>View Party Details</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">{party?.name}</td>
                  <td className="text-center">
                    {numDifferentiation(totalBilledAmount)}
                  </td>
                  <td className="text-center">
                    {numDifferentiation(totalPaidAmount)}
                  </td>
                  <td className="text-center">
                    {numDifferentiation(totalBilledAmount - totalPaidAmount)}
                  </td>
                  <td className="text-center">
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-info"
                      onClick={toggleModal}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
      {modal && (
        <Details
          modal={modal}
          handleCloseModal={toggleModal}
          data={party}
          configData={props.configData}
        ></Details>
      )}
    </>
  );
};

export default PartyDetails;

const Details = (props) => {
  const data = props.data;
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>Party Details</ModalHeader>
        <ModalBody>
          <Table hover className="border">
            <tbody>
              <tr>
                <td style={{ fontWeight: "600" }}>Party Name</td>
                <td>{data.name ? data.name : ""}</td>
              </tr>
              {props.configData.map((label, index) => {
                return (
                  <tr key={index}>
                    <td style={{ fontWeight: "600" }}>{label}</td>
                    <td>{data[label]}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

import React, { useState } from "react";
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import UnitIncomeLedger from "./CustomerRevenue";
import RawMaterialExpsLedger from "./RawMaterialExps";
import GeneralExpsLedger from "./GeneralExps";
import PaymentLedger from "./PartyPayments";
import StaffSalaryLedger from "./StaffSalary";
import BookingCancelLedger from "./BookingCancel";

const title = "Daily Ledger";
const DailyLedger = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle
                  className="h3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {title}
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          id="date-picker-inline"
                          value={date}
                          onChange={(date) => {
                            const formDate = moment(date).format("YYYY-MM-DD");
                            if (
                              moment(formDate, "YYYY-MM-DD", true).isValid()
                            ) {
                              setDate(formDate);
                            }
                          }}
                          autoOk={true}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                </CardTitle>
              </CardBody>
            </Card>
            <UnitIncomeLedger date={date} />
            <RawMaterialExpsLedger date={date} />
            <GeneralExpsLedger date={date} />
            <PaymentLedger date={date} />
            <StaffSalaryLedger date={date} />
            <BookingCancelLedger date={date} />
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default DailyLedger;

import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { useState } from "react";

const SidebarContent = (props) => {
  const [toggle, setToggle] = useState(false);

  const user = useSelector((state) => state.Login.user);
  const role = user ? user.role : "user";

  const management = user ? user.management : false;
  const customer_revenue = user ? user.customer_revenue : false;
  const customer_refund = user ? user.customer_refund : false;
  const general_bills = user ? user.general_bills : false;
  const raw_material_bills = user ? user.raw_material_bills : false;
  const party_payment = user ? user.party_payment : false;
  const staff_salary = user ? user.staff_salary : false;
  const cancel_booking = user ? user.cancel_booking : false;
  const dashboards = user ? user.dashboards : false;
  const lands = user ? user.lands : false;

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = props.location.pathname;
    if (pathName === "/unit-list") {
      pathName = "/project-list";
    }
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      let items = ul.getElementsByTagName("a");
      if (items.length === 0) {
        setToggle(!toggle);
      }
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [activateParentDropdown, toggle]);
  useEffect(() => {
    ref.current.recalculate();
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Dashboard")} </li>
            {(role === "superAdmin" || lands) && (
              <li>
                <Link to="/lands">
                  <i className="fas fa-box"></i>
                  <span>{props.t("Land Detail")}</span>
                </Link>
              </li>
            )}

            {(role === "superAdmin" || dashboards) && (
              <li>
                <Link to="/dashboard">
                  <i className="mdi mdi-view-dashboard"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
            )}
            {(role === "superAdmin" ||
              customer_revenue ||
              party_payment ||
              raw_material_bills ||
              general_bills) && (
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="fas fa-folder-open"></i>
                  <span>{props.t("Daily Entries")}</span>
                </Link>
                <ul className="sub-menu">
                  {(customer_revenue || role === "superAdmin") && (
                    <li>
                      <Link to="/customer-revenue">{props.t("Revenue / Refund")}</Link>
                    </li>
                  )}
                  {(raw_material_bills ||
                    party_payment ||
                    general_bills ||
                    role === "superAdmin") && (
                    <li>
                      <Link to="/project-expenses">{props.t("Expenses")}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {role === "superAdmin" && (
              <li>
                <Link to="/daily-ledger" className="waves-effect">
                  <i class="fas fa-book"></i>
                  <span>{props.t("Daily Ledger")}</span>
                </Link>
              </li>
            )}
            {role === "superAdmin" && (
              <li>
                <Link to="/interest-details" className="waves-effect">
                  <i class="fas fa-hand-holding-usd"></i>
                  <span>{props.t("Interest Detail")}</span>
                </Link>
              </li>
            )}
            <li className="menu-title">{props.t("Admin")} </li>
            {(role === "superAdmin" ||
              customer_revenue ||
              customer_refund||
              staff_salary ||
              party_payment ||
              cancel_booking ||
              raw_material_bills ||
              general_bills) && (
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="fas fa-file-invoice-dollar"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">2</span> */}
                  <span>{props.t("P&L Entries")}</span>
                </Link>
                <ul className="sub-menu">
                  {(customer_revenue || role === "superAdmin") && (
                    <li>
                      <Link to="/unit-incomes">
                        {props.t("Customer Revenue")}
                      </Link>
                    </li>
                  )}
                   {(customer_refund || role === "superAdmin") && (
                    <li>
                      <Link to="/customer-refund">
                        {props.t("Customer Refund")}
                      </Link>
                    </li>
                  )}
                  {(raw_material_bills || role === "superAdmin") && (
                    <li>
                      <Link to="/material-expenses">
                        {props.t("Raw Material Bills")}
                      </Link>
                    </li>
                  )}
                  {(general_bills || role === "superAdmin") && (
                    <li>
                      <Link to="/general-expenses">
                        {props.t("General Bills")}
                      </Link>
                    </li>
                  )}
                  {(party_payment || role === "superAdmin") && (
                    <li>
                      <Link to="/party-expenses">
                        {props.t("Party Paid Bills")}
                      </Link>
                    </li>
                  )}
                  {(cancel_booking || role === "superAdmin") && (
                    <li>
                      <Link to="/cancel-booking">
                        {props.t("Booking Cancelation")}
                      </Link>
                    </li>
                  )}
                  {(staff_salary || role === "superAdmin") && (
                    <li>
                      <Link to="/staff-salary">{props.t(" Staff Salary")}</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {role === "superAdmin" && (
              <>
                <li>
                  <Link to="" className="has-arrow waves-effect">
                    <i className="fas fa-user-friends"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users-list">{props.t("All Users")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {(role === "superAdmin" || management) && (
              <li className="menu-title">{props.t("Setting")} </li>
            )}

            {(role === "superAdmin" || management) && (
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="fas fa-book-reader"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">2</span> */}
                  <span>{props.t("Project Administration")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/project-list">
                      {/* <i className="mdi mdi-office-building"></i> */}
                      <span>{props.t("Projects")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/department-list">{props.t("Departments")}</Link>
                  </li>
                  <li>
                    <Link to="/party-list">{props.t("Parties")}</Link>
                  </li>
                  <li>
                    <Link to="/customer-list">{props.t("Customers")}</Link>
                  </li>
                  <li>
                    <Link to="/employee-list">{props.t("Employees")}</Link>
                  </li>
                  <li>
                    <Link to="/archived-project">{props.t("Archived Projects")}</Link>
                  </li>
                </ul>
              </li>
            )}
            {role === "superAdmin" && (
              <>
                <li>
                  <Link to="" className="has-arrow waves-effect">
                    <i className="mdi mdi-table-large-plus"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/project-configuration">
                        <span>{props.t("Project Settings")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/unit-configuration">
                        <span>{props.t("Project Unit Settings")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/department-configuration">
                        <span>{props.t("Department Settings")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/subdepartment-configuration">
                        <span>{props.t("Sub Department Settings")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/party-configuration">
                        {props.t("Party Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/customer-configuration">
                        {props.t("Customer Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/employee-configuration">
                        {props.t("Employee Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/general-expense-configuration">
                        {props.t("General Expense Settings")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  updateRefund,
  getFormData,
  getUnitDetails,
} from "../../services/userService.js";
import Select from "react-select";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { useState } from "react";
import { useCallback } from "react";
import moment from "moment";

const UpdateRefund = (props) => {
  const defaultData = props.data;

  const [projectOptions, setProjectOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [project, setProject] = useState({
    label: defaultData.project ? defaultData.project : "",
    value: defaultData.project_id ? defaultData.project_id : "",
  });
  const [customer, setCustomer] = useState({
    label: defaultData.customer ? defaultData.customer : "",
    value: defaultData.customer_id ? defaultData.customer_id : "",
  });
  const [unit, setUnit] = useState({
    label: defaultData.unit ? defaultData.unit : "",
    value: defaultData.unit_id ? defaultData.unit_id : "",
  });
  const [paymentMode, setPaymentMode] = useState({
    value: defaultData.payment_mode ? defaultData.payment_mode : "",
  });
  const paymentModeOptions = [{ value: "Cash" }, { value: "Cheque" }];

  const getAllProjects = () => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((project) => {
        return { label: project.name, value: project._id };
      });
      setProjectOptions(options);
    });
  };
  useEffect(() => {
    getAllProjects();
    getAllUnits(project);
  }, [project]);

  const getAllUnits = (value) => {
    getFormData("UNIT&project_id=" + value.value).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((unit) => {
        return { label: unit.name, value: unit._id };
      });
      setUnitOptions(options);
    });
  };

  const getCustomer = useCallback((unit, setFieldValue) => {
    if (unit === "") {
      return;
    }
    getUnitDetails(unit.value).then((res) => {
      let [data] = res.data && res.data.data && res.data.data.data;
      let customer = data && data.customer_id;
      customer && setCustomer({ label: customer.name, value: customer._id });
      customer && setFieldValue("customer_name", customer.name);
      !customer && setFieldValue("customer_name", "");
    });
  }, []);

  let validationObject = {
    payment_mode: Yup.string().trim().required(`Please Select Payment Mode`),
    payment_date: Yup.string().trim().required(`Please Select Payment Date`),
    amount: Yup.number().required(`Please Enter amount`),
    note: Yup.string().max(100, "notes must be less than 100 characters"),
  };
  const validateFunction = () => {
    let errors = {};
    if (project === "") {
      errors.project_id = "Please Select a Project";
    }
    if (unit === "") {
      errors.unit_id = "Please Select a Unit";
    }
    if (customer === "") {
      errors.customer_name = "Unit Has No Customer";
    }
    return errors;
  };

  let initialValues = {
    project_id: project.value,
    unit_id: unit.value,
    customer_name: customer.label,
    customer_id: customer.value,
    payment_date: moment(defaultData.payment_date).format("YYYY-MM-DD"),
    payment_mode: defaultData.payment_mode,
    amount: defaultData.amount,
    note: defaultData.note,
  };

  let submitFormSchema = Yup.object().shape(validationObject);

  //? handles
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    let data = {
      ...values,
      project_id: project.value,
      customer_id: customer.value,
      unit_id: unit.value,
    };
    updateRefund(defaultData._id, data)
      .then((res) => {
        props.handleCloseModal();
        notifySuccess("Customer Refund Updated Successfully");
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };
  const handleProjectChange = (value) => {
    setProject(value);
    setUnit("");
    setCustomer("");
  };
  const handleUnitChange = (value, setFieldValue) => {
    setUnit(value);
    setCustomer("");
    getCustomer(value, setFieldValue);
  };
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Update {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
            validate={validateFunction}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
              setFieldError,
            }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    Project <Required />
                  </label>
                  <Select
                    options={projectOptions}
                    name="project_id"
                    value={project}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldValue("customer_name", "");
                      setFieldValue("project_id", value.value);
                      handleProjectChange(value);
                    }}
                    placeholder={"Select Project"}
                  />
                  {errors.project_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.project_id}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Unit <Required />
                  </label>
                  <Select
                    options={unitOptions}
                    name="unit_id"
                    value={unit}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldError("unit_id", "");
                      handleUnitChange(value, setFieldValue, setFieldError);
                    }}
                    placeholder={"Select Unit"}
                  />
                  {errors.unit_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.unit_id}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Customer Name <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    readOnly={true}
                    name="customer_name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Amount <Required />
                  </label>
                  <CustomInput
                    type="number"
                    values={values}
                    name="amount"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Mode <Required />
                  </label>
                  <Select
                    options={paymentModeOptions}
                    name="payment_mode"
                    value={paymentMode}
                    getOptionLabel={(option) => option.value}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldValue("payment_mode", value.value);
                      handlePaymentModeChange(value);
                    }}
                    placeholder={"Select Payment Mode"}
                  />
                  {errors.payment_mode && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.payment_mode}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Date <Required />
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    name="payment_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>Note</label>
                  <CustomInput
                    type="text"
                    values={values}
                    name="note"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateRefund;

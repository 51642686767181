import React from "react";
import { Redirect } from "react-router-dom";
import { STORAGEKEY } from "../components/Constant";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesInvoice from "../pages/Utility/invoice";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Authentication related pages

import Logout from "../pages/Authentication/Logout";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

//user auth-flow
import Login from "../pages/UserAuthentication/Login";
import Register from "../pages/UserAuthentication/Register";
import ForgetPasswordPage from "../pages/UserAuthentication/ForgotPassword";
import ResetPassword from "../pages/UserAuthentication/ResetPassword";

//user dashboard
import UserDashboard from "../pages/UserDashboard/index";

import ProjectConfiguration from "../pages/Configurations/ProjectConfiguration";
import SubDepartmentConfiguration from "../pages/Configurations/SubDepartmentConfiguration";
import PartyConfiguration from "../pages/Configurations/PartyConfiguration";
import EmployeeConfiguration from "../pages/Configurations/EmployeeConfiguration";
import UnitConfiguration from "../pages/Configurations/UnitConfiguration";
import DepartmentConfiguration from "../pages/Configurations/DepartmentConfiguration";
import CustomerConfiguration from "../pages/Configurations/CustomerConfiguration";
import Projects from "../pages/Projects";
import CustomersProjectData from "../pages/Customer";
import ProjectUnits from "../pages/Units";
import Parties from "../pages/Party";
import Customers from "../pages/Customer/GetCustomer";
import Employees from "../pages/Employee";
import Departments from "../pages/Department";
import DepartmentExpenses from "../pages/Department/DepartmentExpenses";
import UnitIncome from "../pages/Units/UnitIncome";
import CustomerRefund from "../pages/Units/CustomerRefund";
import ProjectExpenses from "../pages/ProjectExpense";
import GeneralExpenses from "../pages/GeneralExpense";
import EmployeeSalary from "../pages/Employee/EmployeeSalary";
import CancelUnitBooking from "../pages/CancelUnitBooking";
import Users from "../pages/Users";
import PartyExpense from "../pages/PartyExpenses.js/index.js";
import PartyRate from "../pages/Party/PartyRate";
import GeneralExpenseConfiguration from "../pages/Configurations/GeneralExpenseConfiguration";
import Dashboard from "../pages/GeneralDashboard/Dashboard";
import ProjectDashboard from "../pages/Dashboards/ProjectDashboard";
import CustomerRevenue from "../pages/Dashboards/CustomerRevenue";
import DashboardGeneralExpanses from "../pages/Dashboards/GeneralExpanses";
import DashboardStaffSalary from "../pages/Dashboards/StaffSalary";
import RawMaterialOverview from "../pages/Dashboards/RawMaterialOverview";
import PartyExpenseDetails from "../pages/Dashboards/PartyExpenseDetails";
import BookedUnits from "../pages/Dashboards/BookedUnits";
import CustomerDetails from "../pages/Dashboards/CustomerDetails";
import MonthlyInterest from "../pages/Dashboards/MonthlyInterests";
import UnpaidUnits from "../pages/Dashboards/UnpaidUnits";
import PartyByDepartment from "../pages/Dashboards/PartyByDepartment";
import PartyDetails from "../pages/Dashboards/PartyDetails";
import AddRevenue from "../pages/DailyEntry/Revenue/revenue";
import AddProjectExpenses from "../pages/DailyEntry/Expense/ProjectExpense";
import GeneralBills from "../pages/DailyEntry/Expense/GeneralBills";
import PartyBills from "../pages/DailyEntry/Expense/PartyBills";
import DailyLedger from "../pages/DailyLedger";
import EmployeeSalaryDetails from "../pages/Employee/EmployeeDetails";
import PartyPaidBills from "../pages/DailyEntry/Expense/PartyPaidBills";
import Lands from "../pages/land/index";
import InterestDetails from "../pages/InterestDetails/index";
import LandPayment from "../pages/land/LandPayment/index";
import EmployeesData from "../pages/StaffSalary/StaffSalary";
import EmployeeData from "../pages/StaffSalary/StaffDetail";
import ViewDetails from "../pages/InterestDetails/ViewDetails";
import ArchivedProject from "../pages/Projects/archivedProject";
let role = localStorage.getItem(STORAGEKEY.ROLE);

const userRoutes = [
  // { path: "/dashboard", component: Dashboard },
  { path: "/user-dashboard", component: UserDashboard },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/invoice", component: PagesInvoice },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Icons
  { path: "/icons-unicons", component: IconUnicons },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // {
  //   path: "/",
  //   exact: true,
  //   component: () => <Redirect to="/user-dashboard" />,
  // },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/user-login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/user-register", component: Register },
  { path: "/reset-password", component: ResetPassword },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },

  // { path: "/dashboard", component: Dashboard },
  // { path: "/user-dashboard", component: UserDashboard },
  // { path: "/links", component: Links },
];

const configurationRoutes = [
  { path: "/project-configuration", component: ProjectConfiguration },
  { path: "/customer-configuration", component: CustomerConfiguration },
  { path: "/department-configuration", component: DepartmentConfiguration },
  { path: "/unit-configuration", component: UnitConfiguration },
  { path: "/employee-configuration", component: EmployeeConfiguration },
  { path: "/party-configuration", component: PartyConfiguration },
  {
    path: "/general-expense-configuration",
    component: GeneralExpenseConfiguration,
  },
  { path: "/users-list", component: Users },
  {
    path: "/subdepartment-configuration",
    component: SubDepartmentConfiguration,
  },
];

const managementRoutes = [
  { path: "/project-list", component: Projects },
  { path: "/customer-list", component: CustomersProjectData },
  { path: "/department-list", component: Departments },
  { path: "/unit-list", component: ProjectUnits },
  { path: "/employee-list", component: Employees },
  { path: "/party-list", component: Parties },
  { path: "/department-expense", component: DepartmentExpenses },
  { path: "/party-rates", component: PartyRate },
  { path: "/project-unit-list", component: Customers },
  { path: "/archived-project", component: ArchivedProject },
];

const expenseRoutes = [
  { path: "/customer-revenue", component: AddRevenue },
  { path: "/project-expenses", component: AddProjectExpenses },
  { path: "/unit-incomes", component: UnitIncome },
  { path: "/customer-refund", component: CustomerRefund },
  { path: "/cancel-booking", component: CancelUnitBooking },
  { path: "/material-expenses", component: ProjectExpenses },
  { path: "/general-expenses", component: GeneralExpenses },
  { path: "/party-expenses", component: PartyExpense },
  { path: "/employee-salary", component: EmployeeSalary },
  { path: "/employee-details", component: EmployeeSalaryDetails },
  { path: "/general-bills", component: GeneralBills },
  { path: "/party-bills", component: PartyBills },
  { path: "/party-payments", component: PartyPaidBills },
  { path: "/lands", component: Lands },
  { path: "/land-details", component: LandPayment },
  { path: "/interest-details", component: InterestDetails },
  { path: "/interest-details/loan/:_id", component: ViewDetails },
  { path: "/interest-details/borrow/:_id", component: ViewDetails },
  { path: "/staff-salary", component: EmployeesData },
  { path: "/salary-details", component: EmployeeData },
];

const dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/project-dashboard", component: ProjectDashboard },
  { path: "/dashboard-customer-revenue", component: CustomerRevenue },
  { path: "/dashboard-general-expanses", component: DashboardGeneralExpanses },
  { path: "/dashboard-staff-salary", component: DashboardStaffSalary },
  { path: "/raw-material-overview", component: RawMaterialOverview },
  { path: "/party-expense-details", component: PartyExpenseDetails },
  { path: "/booked-units", component: BookedUnits },
  { path: "/dashboard/department-expense", component: PartyByDepartment },
  { path: "/customer-details", component: CustomerDetails },
  { path: "/party-details", component: PartyDetails },
  { path: "/monthly-interest", component: MonthlyInterest },
  { path: "/unpaid-units", component: UnpaidUnits },
  { path: "/daily-ledger", component: DailyLedger },
];

const undefinedRoutes = [
  {
    path: "/*",
    component: () => (
      <Redirect
        to={
          role === "superAdmin"
            ? { pathname: "/dashboard" }
            : { pathname: "/project-list" }
        }
      />
    ),
  },
];

export {
  userRoutes,
  authRoutes,
  configurationRoutes,
  managementRoutes,
  undefinedRoutes,
  expenseRoutes,
  dashboardRoutes,
};

import { Form, Formik } from "formik";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import { updatePartyRates } from "../../services/userService.js";
import { notifySuccess } from "../../components/Custom/notification.js";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const UpdatePartyRate = (props) => {
  const headers = props.headers;
  const party_id = props.party;
  const defaultData = props.data;
  const [dates, setDates] = useState([
    defaultData.start_date,
    defaultData.end_date,
  ]);
  const acquiredDates = props.dates
    .filter((e) => e._id !== defaultData._id)
    .map((date) => {
      let setDate = moment(date.start_date)
        // .subtract(1, "days")
        .hours(0, 0, 0, 0)
        .format("YYYY-MM-DD");
      return { from: setDate, to: date.end_date };
    });
  let date = new Date();
  date.setHours(0, 0, 0, 0);

  let initialValues = {
    start_date: moment(defaultData.start_date).format("YYYY-MM-DD"),
    end_date: moment(defaultData.end_date).format("YYYY-MM-DD"),
  };
  let validationObject = {
    // start_date: Yup.date()
    //   .required(`Please Enter Date`)
    //   .min(date, "Date Must Be Today Or Later"),
    // end_date: Yup.date()
    //   .required(`Please Enter Date`)
    //   .min(date, "Date Must Be Today Or Later"),
  };

  headers.map((header, index) => {
    initialValues = {
      ...initialValues,
      [header]: defaultData[header] ? defaultData[header] : "",
    };
    validationObject = {
      ...validationObject,
      [header]: Yup.number()
        .required("Please Enter A Value")
        .min(0, "Please Enter Valid Value"),
    };
  });
  let submitFormSchema = Yup.object().shape(validationObject);

  const handleSubmit = (values, { setFieldError }) => {
    values.party_id = party_id;
    if (dates.length === 0) {
      return setFieldError("range_date", "Please Select Date Range");
    }
    if (dates.length === 1) {
      values.start_date = moment(dates[0]).format("YYYY-MM-DD");
      values.end_date = moment(dates[0]).format("YYYY-MM-DD");
    }
    if (dates.length === 2) {
      values.start_date = moment(dates[0]).format("YYYY-MM-DD");
      values.end_date = moment(dates[1]).format("YYYY-MM-DD");
    }
    if (values.start_date >= values.end_date) {
      return setFieldError(
        "range_date",
        "From Date Must Be Earlier than To Date"
      );
    }
    updatePartyRates(defaultData._id, values).then((res) => {
      notifySuccess("Record Updated Successfully");
      props.handleCloseModal();
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>Update</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
            }) => (
              <Form>
                {headers.map((header, index) => {
                  return (
                    <div key={index} style={{ height: "100px" }}>
                      <label>
                        {header} Price <Required />
                      </label>
                      <CustomInput
                        type="number"
                        values={values}
                        placeholder={`Enter ${header} Price`}
                        name={header}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        min={0}
                        style={{ color: "black" }}
                      />
                    </div>
                  );
                })}
                {/* <div style={{ height: "100px" }}>
                  <label>
                    From Date <Required />
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    name="start_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    To Date <Required />
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    name="end_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div> */}
                <div style={{ height: "100px" }}>
                  <label>
                    Select Date's Range <Required />
                  </label>
                  <Flatpickr
                    name="range_date"
                    // defaultValue={dates}
                    value={dates}
                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      disable: acquiredDates,
                    }}
                    onChange={(value) => {
                      setDates(value);
                    }}
                  />
                </div>

                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdatePartyRate;

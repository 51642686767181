import React from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const UnitDetails = (props) => {
  const data = props.data;
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>Unit Details</ModalHeader>
        <ModalBody>
          <Table hover className="border">
            <tbody>
              <tr>
                <td style={{ fontWeight: "600" }}>Project Name</td>
                <td>{data.project_id ? data.project_id.name : ""}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "600" }}>Unit Name</td>
                <td>{data.name}</td>
              </tr>

              <tr>
                <td style={{ fontWeight: "600" }}>Customer Name</td>
                <td>{data.customer_id ? data.customer_id.name : ""}</td>
              </tr>

              {props.configData.map((label, index) => {
                return (
                  <tr key={index}>
                    <td style={{ fontWeight: "600" }}>{label}</td>
                    <td>{data[label]}</td>
                  </tr>
                );
              })}
              {data.customer_id && (
                <tr>
                  <td style={{ fontWeight: "600" }}>Booking Amount</td>
                  <td>{data.booking_amount ? data.booking_amount : ""}</td>
                </tr>
              )}
              {data.customer_id && (
                <tr>
                  <td style={{ fontWeight: "600" }}>Total Paid Amount</td>
                  <td>{data.paid_amount ? data.paid_amount : ""}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UnitDetails;

import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Row, Table } from "reactstrap";
import { getFormFields } from "../../services/adminService";
import AddPartyRate from "./AddPartyRate";
import { deletePartyRates, getPartyRates } from "../../services/userService";
import EmptyView from "../../components/Custom/EmptyView";
import moment from "moment";
import UpdatePartyRate from "./UpdatePartyRate";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";

const PartyRate = () => {
  let params = window.location.search;
  let departmentId = new URLSearchParams(params).get("department_id");
  let partyId = new URLSearchParams(params).get("party_id");
  let partyName = new URLSearchParams(params).get("party");
  const [headers, setHeaders] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [partyRateList, setPartyRateList] = useState([]);
  const callToggle = () => setToggle(!toggle);
  const addToggle = () => {
    if (addModal === true) {
      callToggle();
    }
    setAddModal(!addModal);
  };

  useEffect(() => {
    const getPaymentCalculationFields = () => {
      getFormFields("SUBDEPARTMENT-FORM&dept_id=" + departmentId).then(
        (res) => {
          let data = res.data && res.data.data;
          let filterdData = data
            .filter((d) => {
              return d.item_type === "Number" && d.countable === true;
            })
            .map((e) => e.item_name);
          setHeaders(filterdData);
        }
      );
    };

    const getPartiesAllRates = () => {
      getPartyRates(partyId).then((res) => {
        let data = res.data && res.data.data;
        setPartyRateList(data);
      });
    };
    getPaymentCalculationFields();
    getPartiesAllRates();
  }, [departmentId, partyId, toggle]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Party Rates </h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Party Rate</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {partyName}'s Rate
                  <Button
                    type="submit"
                    name="btn"
                    className="custombtn mr-2"
                    style={{
                      marginRight: 10,
                    }}
                    color="primary"
                    onClick={addToggle}
                  >
                    Add
                  </Button>
                  {addModal && (
                    <AddPartyRate
                      modal={addModal}
                      handleCloseModal={addToggle}
                      headers={headers}
                      data={partyRateList}
                      party={partyId}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr No.</h6>
                      </th>
                      {headers.map((header, index) => {
                        return (
                          <th
                            className="text-center"
                            key={index}
                            style={{ minWidth: "5rem" }}
                          >
                            <h6 style={{ fontWeight: "bold" }}>{header}</h6>
                          </th>
                        );
                      })}
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>From Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>To Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {partyRateList.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Rates Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {partyRateList.map((data, index) => {
                      return (
                        <PartyRateList
                          data={data}
                          key={index}
                          index={index}
                          headers={headers}
                          party={partyId}
                          partyRateList={partyRateList}
                          toggle={callToggle}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default PartyRate;

const PartyRateList = (props) => {
  const { data, index, headers } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const dates = props.partyRateList;

  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
    if (deleteModal) {
      props.toggle();
    }
  };
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    if (updateModal) {
      props.toggle();
    }
  };

  const handleOnDelete = () => {
    deletePartyRates(data._id)
      .then((res) => {
        handleShowDeleteModal();
        notifySuccess("Deleted Successfully");
      })
      .catch((error) => {
        notifyError("Something Went Wrong");
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete ?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <tr>
      <td className="text-center">{index + 1}</td>
      {headers.map((header, index) => {
        return (
          <td key={index} className="text-center">
            {data[header]}
          </td>
        );
      })}
      <td className="text-center">
        {moment(data.start_date).format("DD-MM-YYYY")}
      </td>
      <td className="text-center">
        {moment(data.end_date).format("DD-MM-YYYY")}
      </td>
      <td className="text-center">
        <i
          style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
          id="edit-btn"
          className="mdi mdi-pencil"
          onClick={handleShowUpdateModal}
        />
        {updateModal && (
          <UpdatePartyRate
            modal={updateModal}
            handleCloseModal={handleShowUpdateModal}
            data={props.data}
            headers={headers}
            party={props.party}
            dates={dates}
          />
        )}
        <i
          className="mdi mdi-trash-can"
          id="delete-btn"
          type="submit"
          onClick={handleShowDeleteModal}
          style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
        ></i>
        {deleteModal && sweetAlertFuction()}
      </td>
    </tr>
  );
};

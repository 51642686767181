import moment from "moment";
import { Spinner, Table } from "reactstrap";
import { DownloadStatement } from "../../services/userService";
import { useState } from "react";
import { numDifferentiation } from "../../helpers/amount_helper";

const StatementTable = (props) => {
  const statementData = props.data;
  const [limit, setLimit] = useState(5);
  const [showMore, setShowMore] = useState(false);
  const [statementLoading, setStatementLoading] = useState();

  const statementDownload = (date, index) => {
    let query = `?project_id=${props.projectId}&date=${date}`;
    if (!statementLoading) {
      setStatementLoading(index);
      DownloadStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading();
        })
        .catch((error) => {});
    }
  };

  const toggleShowMore = () => {
    if (!showMore) {
      setLimit(statementData.length);
    } else {
      setLimit(5);
    }
    setShowMore(!showMore);
  };
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th className="fw-bold">Month-year</th>
            <th className="text-end fw-bold">Customer Revenue</th>
            <th className="text-end fw-bold">Raw Material Expense</th>
            <th className="text-end fw-bold">General Expense</th>
            <th className="text-end fw-bold">Staff Salary Expense</th>
            <th className="text-end fw-bold">Outstanding Amount</th>
            <th className="text-center fw-bold"> Download Statement</th>
          </tr>
        </thead>
        <tbody>
          {statementData.map((statement, index) => {
            const outstanding = (
              Object.values(statement)[0].rawMaterial +
              Object.values(statement)[0].generalExpenses +
              Object.values(statement)[0].staffSalaries +
              Object.values(statement)[0].customerRevenue
            ).toFixed(2);
            if (index < limit) {
              return (
                <tr key={index}>
                  <td>
                    {moment(Object.keys(statement)[0]).format("MMM-YYYY")}{" "}
                  </td>
                  <td className=" text-end">
                    {numDifferentiation(
                      (
                        Object.values(statement)[0].customerRevenue * -1
                      ).toFixed(2)
                    )}
                  </td>
                  <td className="text-end">
                    {numDifferentiation(
                      Object.values(statement)[0].rawMaterial.toFixed(2)
                    )}
                  </td>
                  <td className="text-end">
                    {numDifferentiation(
                      Object.values(statement)[0].generalExpenses.toFixed(2)
                    )}
                  </td>
                  <td className="text-end">
                    {numDifferentiation(
                      Object.values(statement)[0].staffSalaries.toFixed(2)
                    )}
                  </td>

                  <td
                    className="text-end"
                    style={{ color: outstanding > 0 ? "red" : "green" }}
                  >
                    {numDifferentiation(outstanding * -1)}
                  </td>
                  <td className="text-center">
                    <span>
                      {statementLoading === index + 1 && (
                        <span style={{ height: "1rem" }}>
                          {" "}
                          <Spinner
                            style={{ height: "1.3rem", width: "1.3rem" }}
                          />
                        </span>
                      )}
                      {statementLoading !== index + 1 && (
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "1rem",
                            height: "1rem",
                          }}
                          onClick={() => {
                            statementDownload(
                              moment(Object.keys(statement)[0]).format(
                                "MMM-YYYY"
                              ),
                              index + 1
                            );
                          }}
                        >
                          <i
                            className="mdi mdi-download"
                            style={{ height: "1rem" }}
                          ></i>
                        </span>
                      )}
                    </span>
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="text-end" colSpan={7}>
              <span
                onClick={toggleShowMore}
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-end"
              >
                {" "}
                {showMore && (
                  <>
                    <i className="dripicons-chevron-up" /> {"Show Less"}
                  </>
                )}
                {statementData.length > limit && !showMore && (
                  <>
                    <i className="dripicons-chevron-down" /> {"Show More"}
                  </>
                )}
              </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default StatementTable;

// export const DevURL = "http://localhost:7000/api";
// export const DevURL = "http://192.168.0.164:7001/api";
export const DevURL = "https://api.bluecpm.co.in/api";

// ? Authentication URL
export const LOGIN_URL = "/auth/login";
export const REGISTER_URL = "signup";
export const FORGOT_PASSWORD = "/auth/forgot_password";
export const RESET_PASSWORD = "/auth/reset_password";
export const DELETE_USER_ACCOUNT = "me";
export const GET_ALL_USER = "/admin/listUsers";
export const UPDATE_USER_ACCESS = "/admin/updateUserData";
export const CREATE_USER = "/admin/createUser";
export const DELETE_USER = "/admin/deleteUser";
export const UPDATE_USER = "/admin/updateUsers";

export const UPDATE_USER_ACCOUNT = "me";
export const GET_USER_ACCOUNT = "/user/me";
export const VERIFICATION = "verification";
export const RESEND_CODE = "resend_verification";
export const GET_SETTING_FORM = "/admin/get_form?form=project";
export const ADD_FORM = "/admin/createForm";
export const REMOVE_SETTING_FORM = "/admin/removeFormItem/";
export const UPDATE_SETTING_FORM = "/admin/updateForm/";

export const FORMS = "/admin/forms";
export const USER_FORMS = "/user/forms";
export const FORM_DATA = "/user/get_formdata";
export const BULK_UNITS = "/unit/bulk_unit";
export const DEMO_UNITS_EXCEL = "/unit/demo_unit_sheet";
export const DELETE_ALL_UNITS = "/unit/delete_all_units";

export const GET_ALL_CUSTOMERS = "/customer/get_projects_customer";
export const CUSTOMER = "/customer";
export const GET_ALL_UNIT_INCOMES = "/unit/all_units_income";
export const UNIT_INCOME = "/unit/unit_income";
export const CUSTOMER_REFUND = "/unit/customer_refund";
export const GET_ALL_CUSTOMER_REFUND = "/unit/all_customers_refund";
export const ALL_BOOKED_UNIT_DATA = "/unit/customer_revenue";
export const GET_UNIT_DETAILS = "/unit/unit_details?unit_id=";
export const CANCEL_UNIT_BOOKING = "/unit/cancel_booking?unit_id=";
export const GET_CANCELLED_BOOKING = "/unit/cancel_booking";

export const GET_DEPT_SUBDEPT = "/user/get_dep_subdep";
export const GET_DEP_WITH_PARTY = "/user/dept_with_parties";

export const ADD_PROJECT_EXPENSE = "/project_expense";
export const PROJECT_GENERAL_EXPENSE = "/project_expense/general";
export const GET_PROJECT_WISE_GENERAL_EXPENSE =
  "/project_expense/project_expenses_unique_data";
export const GET_STAFF_SALARY = "/employee/staff_salary";
export const DEPARTMENT_EXPENSE = "/project_expense/department";
export const GET_PROJECT_MATERIAL = "/project_expense/project_expenses_data";
export const GET_ALL_EMPLOYEE_SALARY = "/employee/all_salary_payment";
export const EMPLOYEE_SALARY = "/employee/salary_payment";
export const PROJECT_STATUS = "/admin/update_project_status";
export const SALARY_PAYROLL = "/employee/salary_payroll";
export const GET_ALL_ARCHIVE = "/user/archived_project";
export const GET_ALL_PARTY = "/party";
export const GET_PARTY_DETAIL = "/party/party_detail";
export const PARTY_PAYMENTS = "/party/payment";
export const PARTY_RATE = "/party/rate";
export const GET_PARTY_PAYMENT = "/party/payment_data";
export const GET_ALL_LAND = "/land/add_land";
export const GET_LAND_PAYMENT = "/land/add_land_payment";
export const GET_LAND = "/land/get_land";

//BORROW & Payment DETAIL
export const GET_BORROW_DETAIL = "/borrow_calculate/get_borrow_data";
export const GET_BORROW_PAYMENT_DETAIL = "/borrow_calculate/get_borrow_payment";
export const ADD_BORROW_PAYMENT_DETAIL = "/borrow_calculate/add_borrow_payment";
export const EDIT_BORROW_PAYMENT_DETAIL =
  "/borrow_calculate/edit_borrow_payment";
export const REMOVE_BORROW_PAYMENT_DETAIL =
  "/borrow_calculate/delete_borrow_payment";
// ALL BORROW
export const GET_ALL_BORROW = "/borrow_calculate/get_borrow";
export const ADD_BORROW = "/borrow_calculate/add_borrow_details";
export const UPDATE_BORROW = "/borrow_calculate/edit_borrow_cal";
export const REMOVE_BORROW = "/borrow_calculate/delete_borrow_cal";

//LOAN & Payment DETAIL
export const GET_LOAN_DETAIL = "/loan_calculate/get_loan_data";
export const GET_LOAN_PAYMENT_DETAIL = "/loan_calculate/get_loan_payment";
export const ADD_LOAN_PAYMENT_DETAIL = "/loan_calculate/add_loan_payment";
export const EDIT_LOAN_PAYMENT_DETAIL = "/loan_calculate/edit_loan_payment";
export const REMOVE_LOAN_PAYMENT_DETAIL = "/loan_calculate/delete_loan_payment";
// ALL LOAN
export const GET_ALL_LOAN = "/loan_calculate/get_loan";
export const ADD_LOAN = "/loan_calculate/add_loan_details";
export const UPDATE_LOAN = "/loan_calculate/edit_loan_cal";
export const REMOVE_LOAN = "/loan_calculate/delete_loan_cal";

export const GET_PROJECT_DATA = "/land/get_project_data";
export const GET_EMPLOYEE_CHART_DATA = "/employee/get_employee_chart_data";
export const GET_EMPLOYEE_PAYMENT_DATA = "/employee/get_employee_payments";
export const GET_EMPLOYEE = "/employee/get_employee";
export const GET_PARTYLISTDATA = "/project_expense/department_expenses";

//? Dashboard
export const GET_LATEST_INCOME = "/general_dashboard/get_all_incomes";
export const GET_LATEST_EXPENSE = "/general_dashboard/get_all_exp";
export const GET_UNITBOOKINGS =
  "/general_dashboard/get_unit_details?project_id=";
export const GET_DEPARTMENT_EXPENSE = "/general_dashboard/get_all_department";
export const GET_LATEST_PROJECT_UNIT_INCOME =
  "/general_dashboard/get_unit_latest_details?project_id=";
export const GET_LATEST_PROJECT_EXPENSE =
  "/general_dashboard/get_latest_exp_details?project_id=";
export const GET_DASHBOARD_COUNT =
  "/general_dashboard/get_project_dashboard_count?project_id=";

export const GET_DASHBOARD_PROJECT_COUNT =
  "/general_dashboard/get_all_todays_bookings";

export const GET_CHART_DATA = "/general_dashboard/get_chart_data";
export const DEPARTMENT_OVERVIEW = "/general_dashboard/department_overview";
export const PARTY_OEVRVIEW = "/general_dashboard/party_overview";

export const PROJECT_OUTSTANDINGS =
  "/general_dashboard/get_all_projects_overview";
export const PROJECT_BOOKING_OVERVIEW =
  "/general_dashboard/get_booking_project_overview";

export const GET_INTEREST_RATE = "/general_dashboard/get_interest_rates";
export const ADD_INTEREST_RATE = "/general_dashboard/add_interest_rate";
export const UPDATE_INTEREST_RATE = "/general_dashboard/update_interest_rate";
export const GET_MONTHLY_INTEREST = "/general_dashboard/get_interest_amounts";

export const DOWNLOAD_STATEMENT = "/general_dashboard/get_statement";
export const GET_STATEMENT_OVERVIEW =
  "/general_dashboard/get_statement_overview";
export const STAFF_SALARY_STATEMENT = "/employee/staff_salary_statement";
export const BOOKED_UNIT_STATEMENT = "/unit/booked_unit_statement";
export const DEPARTMENT_STATEMENT =
  "/general_dashboard/get_department_overview_statement";
export const PARTY_STATEMENT =
  "/general_dashboard/get_party_overview_statement";
export const GENERAL_BILL_STATEMENT = "/project_expense/general_bill_statement";
export const URL = DevURL;

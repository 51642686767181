import PropTypes from "prop-types";
import React from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";

import { Link, Redirect } from "react-router-dom";

// Reactstrap
import { Dropdown } from "reactstrap";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoSm from "../../assets/images/logo-sm.png";
import logo from "../../assets/images/CMPlogo.png";
import logoDark from "../../assets/images/logo-dark.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import * as actions from "../../store/actions";
import { getUserProfile } from "../../services/authService";
import { useEffect } from "react";
import { STORAGEKEY } from "../Constant";

const Header = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Login);
  const homeRoute = "/dashboard";

  useEffect(() => {
    const getUserData = () => {
      getUserProfile()
        .then((res) => {
          let data = res.data && res.data.data;
          dispatch(actions.loginSuccess(data));
        })
        .catch((err) => {
          localStorage.clear();
        });
    };
    getUserData();
    if (user.user) {
      localStorage.setItem(STORAGEKEY.ROLE, user.user.role);
      localStorage.setItem(STORAGEKEY.MANAGEMENT, user.user.management);
      localStorage.setItem(STORAGEKEY.CANCEL_BOOKING, user.user.cancel_booking);
      localStorage.setItem(STORAGEKEY.PARTY_PAYMENT, user.user.party_payment);
      localStorage.setItem(
        STORAGEKEY.RAW_MATERIAL_BILL,
        user.user.raw_material_bills
      );
      localStorage.setItem(STORAGEKEY.STAFF_SALARY, user.user.staff_salary);
      localStorage.setItem(
        STORAGEKEY.CUSTOMER_REVENUE,
        user.user.customer_revenue
      );
      localStorage.setItem(STORAGEKEY.GENERAL_BILL, user.user.general_bills);
    }
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 768) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <Container fluid>
            <div className="float-end">
              <Dropdown className="d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  className="btn header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen"></i>
                </button>
              </Dropdown>{" "}
              <ProfileMenu />{" "}
            </div>
            <div>
              <div className="navbar-brand-box">
                <Link to={homeRoute} className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="20" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="19" />
                  </span>
                </Link>

                <Link to={homeRoute} className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="50" />
                  </span>
                  <span className="logo-lg">
                    <img src={logo} alt="" height="50" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  tToggle();
                }}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
          </Container>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));

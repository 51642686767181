import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import Select from "react-select";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import {
  getPartyRates,
  updateProjectExpense,
} from "../../services/userService.js";
import { getFormFields } from "../../services/adminService.js";
import moment from "moment";
import { Link } from "react-router-dom";

const UpdateRatedBill = (props) => {
  const ref = useRef(null);
  const defaultData = props.data;
  const [project, setProject] = useState({
    label: defaultData.project_id ? defaultData.project_id.name : "",
    value: defaultData.project_id ? defaultData.project_id._id : "",
  });
  const [department, setDepartment] = useState({
    label: defaultData.department_id ? defaultData.department_id.name : "",
    value: defaultData.department_id ? defaultData.department_id._id : "",
  });
  const [party, setParty] = useState({
    label: defaultData.party_id ? defaultData.party_id.name : "",
    value: defaultData.party_id ? defaultData.party_id._id : "",
  });
  const [paymentMode, setPaymentMode] = useState({
    value: defaultData.payment_mode,
    label: defaultData.payment_mode,
  });
  const [configData, setConfigData] = useState([]);
  const [partyRate, setPartyRate] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(defaultData.material_received_date);
  const countable = [];
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
  ];
  const [calcAmt, setCalcAmt] = useState(0);
  let partyRateError = false;

  // const getPartyCurrentRates = () => {
  // let today = moment(date).format("YYYY-MM-DD");
  // getPartyRates(`${party.value}&date=${today}`).then((res) => {
  //   let [data] = res.data && res.data.data;
  //   setPartyRate(data);
  // });
  // };
  //?Initial Values For form
  let initialValues = {
    amount:
      !Number.isInteger(defaultData.amount) &&
      typeof defaultData.amount === "number"
        ? defaultData.amount.toFixed(2)
        : defaultData.amount,
    material_received_date: moment(defaultData.material_received_date).format(
      "YYYY-MM-DD"
    ),
    note: defaultData.note,
    challan_no: defaultData.challan_no,
    material_receiver_name: defaultData.material_receiver_name,
    work_details: defaultData.work_details,
    other_exps: defaultData.other_exps,
  };

  //? Validations For Form
  let validationObject = {
    material_received_date: Yup.date().required("Please Enter Date"),
    note: Yup.string().max(100, "notes must be less than 100 characters"),
    challan_no: Yup.string().required("Please Enter Challan No"),
    material_receiver_name: Yup.string().required("Please Enter Receiver Name"),
    work_details: Yup.string().max(
      100,
      "details must be less than 100 characters"
    ),
  };

  configData.map((data) => {
    initialValues = {
      ...initialValues,
      [data.item_name]:
        defaultData[data.item_name] && data.countable
          ? defaultData[data.item_name].quantity
          : defaultData[data.item_name],
    };
    if (data.is_required) {
      validationObject = {
        ...validationObject,
        [data.item_name]: Yup.string().required(
          `Please Enter ${data.item_name}`
        ),
      };
    }
  });

  configData.filter((data) => {
    data.countable && countable.push(data.item_name);
  });
  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    return errors;
  };

  const getSubDepartmentFields = () => {
    getFormFields("SUBDEPARTMENT-FORM&dept_id=" + department.value).then(
      async (res) => {
        let data = res.data && res.data.data;
        setConfigData(data);
        let rateData;
        for (let rate of data) {
          if (rate.countable && defaultData[rate.item_name]) {
            rateData = {
              ...rateData,
              [rate.item_name]: Number(defaultData[rate.item_name].rate),
            };
          }
        }
        setPartyRate(rateData);
      }
    );
  };

  useEffect(() => {
    getSubDepartmentFields();
  }, []);

  // useEffect(() => {
  //   getPartyCurrentRates();
  // }, [configData]);

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = async (values) => {
    if (partyRateError) {
      return;
    }
    let newObj = {};
    for (let value of countable) {
      newObj[value] = {
        ...newObj[value],
        quantity: values[value],
      };
    }
    for (let key in partyRate) {
      newObj[key] = {
        ...newObj[key],
        rate: partyRate[key],
      };
    }
    const nonCountable = configData
      .filter((label) => !label.countable)
      .map((label) => label.item_name);
    for (let value of nonCountable) {
      newObj[value] = values[value];
    }
    let data = {
      project_id: project ? project.value : "",
      department_id: department ? department.value : "",
      party_id: party ? party.value : "",
      payment_mode: paymentMode.value,
      amount: values.amount,
      material_received_date: values.material_received_date,
      note: values.note,
      challan_no: values.challan_no,
      material_receiver_name: values.material_receiver_name,
      work_details: values.work_details,
      other_exps: values.other_exps,
      ...newObj,
    };
    setLoading(true);
    updateProjectExpense(defaultData._id, data)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        props.getMaterialList();
        notifySuccess("Raw Material Bill Updated Successfully");
      })
      .catch((error) => {
        setLoading(false);
        notifyError(error.data.error.message);
      });
  };
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };
  const redirectUrlForAddMaterialRate = `/party-rates?party=${party.label}&party_id=${party.value}&department=${department.label}&department_id=${department.value}`;

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "50%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Update Raw Material Bill
        </ModalHeader>
        <ModalBody>
          <Formik
            innerRef={ref}
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            validate={validationFunction}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
            }) => (
              <Form>
                <div className="d-flex flex-column gap-3 mb-3">
                  <div>
                    <label>Project</label>
                    <Input
                      type="text"
                      value={project.label}
                      readOnly
                      touched={touched}
                      errors={errors}
                      name="project_id"
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Department</label>
                    <Input
                      type="text"
                      value={department.label}
                      readOnly
                      name="department_id"
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Party</label>
                    <Input
                      type="text"
                      value={party?.label}
                      readOnly
                      name="party_id"
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>

                  <div>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Project"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Material Received Date <Required />
                    </label>
                    <Input
                      type="date"
                      value={values.material_received_date}
                      placeholder={"Enter Material Received Date"}
                      max={moment().format("YYYY-MM-DD")}
                      name="material_received_date"
                      onChange={(value) => {
                        setDate(value.target.value);
                        handleChange(value);
                      }}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Challan No./Bill No. <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Challan No"}
                      name="challan_no"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Material Receiver Name <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Material Receiver Name"}
                      name="material_receiver_name"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Work Details</label>
                    <CustomInput
                      type="textarea"
                      values={values}
                      placeholder={"Enter Work Details"}
                      name="work_details"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>

                  {configData.length > 0 &&
                    configData.map((item, index) => {
                      return (
                        <div key={index}>
                          <label>
                            {item.item_name}
                            {item.is_required && <Required />}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </label>
                          <Input
                            type={item.item_type.toLowerCase()}
                            value={values[item.item_name]}
                            placeholder={`Enter ${item.item_name}`}
                            className={
                              errors[item.item_name] ? "border-danger" : ""
                            }
                            name={item.item_name}
                            onChange={handleChange}
                            touched={touched}
                            errors={errors}
                            style={{ color: "black" }}
                          />
                          {errors[item.item_name] && (
                            <div
                              style={{
                                fontSize: 14,
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="text-left mt-1 text-danger"
                            >
                              {errors[item.item_name]}
                            </div>
                          )}
                          {item.countable && (
                            <div className="d-flex flex-row align-items-center">
                              <Input
                                type="number"
                                value={partyRate && partyRate[item.item_name]}
                                onChange={(e) =>
                                  setPartyRate({
                                    ...partyRate,
                                    [item.item_name]: e.target.value,
                                  })
                                }
                                placeholder="Enter Rate"
                              />
                            </div>
                          )}

                          {/* {item.countable && (
                            <div className="">
                              <label>Party Rate </label>{" "}
                              {partyRate && partyRate[item.item_name]}
                            </div>
                          )} */}
                        </div>
                      );
                    })}
                  <div>
                    <label>Other Expense & Tax</label>
                    <Input
                      type="number"
                      value={values.other_exps}
                      name="other_exps"
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Total Amount</label>
                    <label className="mx-4">
                      {"Calculated Amount "}
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          setFieldValue("amount", calcAmt.toFixed(2));
                        }}
                      >
                        {(() => {
                          let total = 0;
                          countable.map((e) => {
                            if (values && partyRate) {
                              return (total +=
                                values[e] * (partyRate[e] ? partyRate[e] : 0));
                            } else return total;
                          });
                          values.other_exps
                            ? setCalcAmt(total + Number(values.other_exps))
                            : setCalcAmt(total);
                          return Number.isInteger(
                            total + Number(values.other_exps)
                          ) && typeof total === "number"
                            ? total + Number(values.other_exps)
                            : (total + Number(values.other_exps)).toFixed(2);
                        })()}
                      </Button>
                    </label>
                    <Input
                      type="number"
                      value={values.amount}
                      name="amount"
                      readOnly
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Note</label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Note"}
                      name="note"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                </div>

                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateRatedBill;
const RateError = (props) => {
  props.error();
  return (
    <>
      <Link to={props.url}>
        <span className="text-danger"> Party Has To Decide Rate First </span>
      </Link>
    </>
  );
};

import { Form, Formik } from "formik";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import { submitForm } from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import Select from "react-select";
import { useState } from "react";

const form = "DEPARTMENT";
const AddDepartment = (props) => {
  const labels = props.labels;
  const departmentTypeOptions = [
    { label: "Fixed Bill", value: "fixed_bill" },
    { label: "Rated Bill", value: "rated_bill" },
  ];

  const [departmentType, setDepartmentType] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const style = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ff715b",
    }),
  };
  //? add validations and intialValues for form
  let validationObject = {
    name: Yup.string()
      .trim()
      .required(`Please Enter ${props.title} Name`)
      .max(25, "Maximum 25 Character Allowed"),
  };
  let initialValues = { name: "", department_id: "" };
  labels.map((label) => {
    initialValues = { ...initialValues, [label.item_name]: "" };
    if (label.is_required)
      return (validationObject = {
        ...validationObject,
        [label.item_name]: Yup.string()
          .required(`Please enter ${label.item_name}`)
          .max(50, "Maximum 50 Character Allowed"),
      });
    else return null;
  });

  let submitFormSchema = Yup.object().shape(validationObject);

  const handleDepartmentTypeChange = (value) => {
    setIsError(false);
    setDepartmentType(value);
  };

  const validatorFunction = () => {
    let errors = {};
    if (departmentType === "") {
      setIsError(true);
      errors.department_type = "Please Select Department Type";
    }
    return errors;
  };

  const handleSubmit = (values, { setFieldError, resetForm }) => {
    let error = false;
    setLoading(true);
    values.department_type = departmentType.value;
    submitForm(form, values)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        notifySuccess("Department Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
            validate={validatorFunction}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldError,
            }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    {props.title} Name <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={`Enter ${props.title} Name`}
                    name="name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>Department Type</label>
                  <Select
                    options={departmentTypeOptions}
                    name="department_type"
                    styles={isError ? style : ""}
                    onChange={(value) => {
                      setFieldError("department_type", "");
                      handleDepartmentTypeChange(value);
                    }}
                  ></Select>
                  {errors.department_type && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.department_type}
                    </div>
                  )}
                </div>
                {labels.map((label, index) => {
                  return (
                    <div style={{ height: "100px" }} key={index}>
                      <label>
                        {label.item_name} {label.is_required && <Required />}
                      </label>
                      <CustomInput
                        type={label.item_type}
                        values={values}
                        // required={label.is_required}
                        placeholder={"Enter " + label.item_name}
                        name={label.item_name}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        style={{ color: "black" }}
                      />
                    </div>
                  );
                })}

                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddDepartment;

import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, CardTitle, Table, Input } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import {
  getProjectGeneralExpense,
  getProjectWiseGeneralExpense,
} from "../../services/userService";
import { PaginationFunction } from "../../components/Custom/Pagination";

import moment from "moment";
import { getFormFields } from "../../services/adminService";
import { numDifferentiation } from "../../helpers/amount_helper";
import Loader from "../../components/Custom/Loader";

const title = "General Bills";
const form = "GENERAL-EXPENSE-FORM";
const GeneralExpenses = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [search, setSearch] = useState("");
  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = window.location.search;
  let projectId = new URLSearchParams(params).get("project_id");
  let projectName = new URLSearchParams(params).get("project");

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const getAllFields = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("----error----", err);
      });
  };
  const getAllGeneralExpenses = () => {
    const pageAndLimit = `?project_id=${projectId}&page=${activePage}&limit=${pageDataCount}&search=${encodeURIComponent(
      search
    )}`;
    setLoading(true);
    getProjectWiseGeneralExpense(pageAndLimit).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      setActivePage(data.length === 0 ? 1 : activePage);
      setTotalCount(res.data.data.totalCount);
      setProjectsData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllGeneralExpenses();
  }, [toggle, activePage, pageDataCount, search]);

  useEffect(() => {
    getAllFields();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>
              <div className="page-title-middle">
                <h3 className="page-title mb-0 font-size-18">
                  {" "}
                  {projectName}{" "}
                </h3>
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by exp paid by`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                  </div>
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Details</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Total</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      {configData.map((config, index) => {
                        return (
                          <th
                            className="text-center"
                            style={{ minWidth: "5rem" }}
                            key={index}
                          >
                            <h6 style={{ fontWeight: "bold" }}>
                              {config.item_name}
                            </h6>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Bills Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        configData={configData}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default GeneralExpenses;

const GetDataList = (props) => {
  const { data, index } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.details}</td>
        <td className="text-center">
          {numDifferentiation(
            !Number.isInteger(data.total) && typeof data.total === "number"
              ? data.total.toFixed(2)
              : data.total
          )}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        {props.configData.map((config, index) => {
          return (
            <td className="text-center" key={index}>
              {data[config.item_name] ? data[config.item_name] : "-"}
            </td>
          );
        })}
      </tr>
    </>
  );
};

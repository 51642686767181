import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  addBorrowPayment,
  addLoanPayment,
  editBorrowPayment,
  editLoanPayment,
} from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import moment from "moment";
import Select from "react-select";
import { useLocation } from "react-router-dom";

const AddPayment = ({
  modal,
  handleCloseModal,
  title,
  data = {},
  detailsPage,
}) => {
  const { pathname } = useLocation();
  const loanId = pathname.split("/")[3];
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState({
    value: "Cash",
    label: "Cash",
  });
  const paymentModeOptions = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online", label: "Online" },
  ];

  let initialValues = {
    paid_amount: data?.paid_amount || "",
    payment_mode: data?.payment_mode || paymentMode.value,
    payment_date:
      data?.payment_date?.split("T")[0] ||
      moment().format("YYYY-MM-DD").toString(),
    note: data?.note || "",
  };

  let validationObject = {
    paid_amount: Yup.number()
      .required(`Please Enter amount`)
      .min(0, "Amount must be greater than 0"),

    payment_mode: Yup.string().required(`Please Select Payment Mode`),
    payment_date: Yup.string().required(`Please Select Payment Date`),
    note: Yup.string().max(100, "notes must be less than 100 characters"),
  };

  let loanSchema = Yup.object().shape(validationObject);

  const handleSubmit = (values) => {
    setLoading(true);
    if (detailsPage === "loan") {
      // if (data?._id) {
      //   const updatedData = {
      //     ...values,
      //     loan_id: loanId,
      //   };
      //   editLoanPayment(updatedData, data._id)
      //     .then((res) => {
      //       handleCloseModal();
      //       setLoading(false);
      //       notifySuccess("Update Loan Payment Successfully");
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //       notifyError(err.data.error.message);
      //     });
      // } else {
      const updatedData = {
        ...values,
        loan_id: loanId,
      };
      addLoanPayment(updatedData, loanId)
        .then((res) => {
          handleCloseModal();
          setLoading(false);
          notifySuccess("Add Loan Payment Successfully");
        })
        .catch((err) => {
          setLoading(false);
          notifyError(err.data.error.message);
        });
      // }
    } else {
      // if (data?._id) {
      //   const updatedData = {
      //     ...values,
      //     borrow_id: loanId,
      //   };
      //   editBorrowPayment(updatedData, data._id)
      //     .then((res) => {
      //       handleCloseModal();
      //       setLoading(false);
      //       notifySuccess("Update Borrow Payment Successfully");
      //     })
      //     .catch((err) => {
      //       setLoading(false);
      //       notifyError(err.data.error.message);
      //     });
      // } else {
      const updatedData = {
        ...values,
        borrow_id: loanId,
      };
      addBorrowPayment(updatedData, loanId)
        .then((res) => {
          handleCloseModal();
          setLoading(false);
          notifySuccess("Add Borrow Payment Successfully");
        })
        .catch((err) => {
          setLoading(false);
          notifyError(err.data.error.message);
        });
      // }
    }
  };

  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={handleCloseModal}>{title}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={loanSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div style={{ height: "100px" }}>
                    <label>
                      Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder="Enter Amount"
                      name="paid_amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                      min={0}
                      step={"any"}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      getOptionLabel={(option) => option.value}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldValue("payment_mode", value.value);
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Payment Mode"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Payment Date <Required />
                    </label>
                    <CustomInput
                      type="date"
                      values={values}
                      placeholder={"Select Date"}
                      name="payment_date"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>Note</label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Note"}
                      name="note"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <ModalFooter className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      disabled={loading}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {title === "Update Payment" ? "Update" : "Create"}
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddPayment;

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Input, Row, Table } from "reactstrap";
import { PaginationFunction } from "../../components/Custom/Pagination";
import {
  getFormData,
  getCustomerRefundRevenue,
} from "../../services/userService";
import EmptyView from "../../components/Custom/EmptyView";
import { useHistory } from "react-router-dom";
import { numDifferentiation } from "../../helpers/amount_helper";
import Loader from "../../components/Custom/Loader";

const BookedUnits = () => {
  let params = window.location.search;
  let id = new URLSearchParams(params).get("project_id");
  let projectName = new URLSearchParams(params).get("project");
  const history = useHistory();

  const [bookedUnits, setBookedUnits] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [allUnits, setAllUnits] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   getFormData("UNIT&project_id=" + id).then((res) => {
  //     let data = res && res.data && res.data.data && res.data.data.data;
  //     data = data.filter((unit) => unit.customer_id);
  //     setAllUnits(data);
  //     setTotalCount(data.length);
  //     setLoading(false);
  //   });
  // }, [id, search]);
  useEffect(() => {
    setLoading(true);
    getCustomerRefundRevenue(id).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      setAllUnits(data);
      setTotalCount(data.length);
      setLoading(false);
    });
  }, [id, search]);

  useEffect(() => {
    let data = allUnits;
    if (search !== "") {
      data = data.filter((unit) => {
        return (
          unit.name.includes(search) || unit.customer_id.name.includes(search)
        );
      });
      setTotalCount(data.length);
    }
    let newArr = data.slice(
      (activePage - 1) * pageDataCount,
      (activePage - 1) * pageDataCount + pageDataCount
    );
    setBookedUnits(newArr);
  }, [activePage, allUnits, pageDataCount, search]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const searchFunction = (search) => {
    setSearch(search);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">
                {projectName} Booked Units
              </h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Booked Units</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  Booked Units
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search By Unit Or Customer Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                  </div>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Unit Name</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Unit Type</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Customer Name</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Booking Price</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Paid Amount</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}> Refund Amount</h6>
                      </th>
                      <th className="text-center">
                        <h6 style={{ fontWeight: "bold" }}>
                          {" "}
                          Outstanding Amount
                        </h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && bookedUnits?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Booked Unit Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {bookedUnits.map((unit, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">
                            {index + 1 + (activePage - 1) * pageDataCount}
                          </td>
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(
                                `/customer-details?id=${unit.customer_id._id}`
                              )
                            }
                          >
                            {unit.name}
                          </td>
                          <td className="text-center">{unit.unit_type}</td>{" "}
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(
                                `/customer-details?id=${unit.customer_id._id}`
                              )
                            }
                          >
                            {unit.customer_id.name}
                          </td>
                          <td className="text-center">
                            {numDifferentiation(unit.booking_amount)}
                          </td>
                          <td className="text-center">
                            {numDifferentiation(unit.paid_amount)}
                          </td>
                          <td className="text-center">
                            {numDifferentiation(unit.totalRefundAmount)}
                          </td>
                          <td className="text-center">
                            {numDifferentiation(
                              unit.booking_amount -
                                unit.paid_amount +
                                unit.totalRefundAmount
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default BookedUnits;

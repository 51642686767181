import React, { useEffect } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import staffSalary from "../../assets/Icons/salary.png";
import LandImage from "../../assets/Icons/land_img.jpg";
import refund from "../../assets/Icons/pay.png";
import customerRevenue from "../../assets/Icons/customer_revenue-2.png";
import rawMaterial from "../../assets/Icons/raw-materials-2.png";
import expense from "../../assets/Icons/expense.png";
import bookedUnit from "../../assets/Icons/agreement.png";
import totalUnits from "../../assets/Icons/building.png";
import cancelBooking from "../../assets/Icons/house.png";
import forSale from "../../assets/Icons/for-sale.png";
import add from "../../assets/Icons/add.png";
import remove from "../../assets/Icons/remove.png";
import {
  getDashboardDataCount,
  getDataForChart,
  getFormData,
  getFormDataWithId,
  statementOverview,
  updateFormData,
  staffSalaryStatement,
  bookedUnitsStatement,
  departmentStatement,
  partyStatement,
  generalBillStatement,
} from "../../services/userService";
import { useState } from "react";
import Chart from "./Chart";
import moment from "moment/moment";
import { Link, useHistory } from "react-router-dom";
import InterestTable from "./InterestTable";
import StatementTable from "./StatementTable";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import SweetAlert from "react-bootstrap-sweetalert";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { numDifferentiation } from "../../helpers/amount_helper";

const ProjectDashboard = () => {
  let params = window.location.search;
  let id = new URLSearchParams(params).get("id");
  let projectName = new URLSearchParams(params).get("project");

  const [bookedUnits, setBookedUnits] = useState([]);
  const [landid, setLandId] = useState("");
  const [counts, setCounts] = useState({
    bookedUnits: 0,
    cancelBooking: 0,
    remainingUnitsBooking: 0,
    totalEmployeeSalary: 0,
    totalLandValue: 0,
    totalExpenses: 0,
    totalGeneralExpense: 0,
    totalIncome: 0,
    totalUnits: 0,
    totalBookingAmount: 0,
  });
  const [month, setMonth] = useState(moment().format("YYYY-MM-DD"));
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statementData, setStatementData] = useState([]);
  const [project, setProject] = useState({});
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(true);
  const [toggle3, setToggle3] = useState(false);
  const [statementLoading, setStatementLoading] = useState(false);
  useEffect(() => {
    getFormData("UNIT&project_id=" + id).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      data = data.filter((unit) => unit.customer_id);
      setBookedUnits(data);
    });
    getFormDataWithId("PROJECT", id).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      if (data.interest === true) {
        setToggle2(false);
      }
      setProject(data);
    });
    getDashboardDataCount(id).then((res) => {
      let data = res && res.data && res.data.data;
      setCounts(data);
      setLandId(res.data.data.land_id);
    });
  }, [id, toggle]);

  useEffect(() => {
    let query = `?project_id=${id}`;
    statementOverview(query).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      setStatementData(data.reverse());
      data = data.filter((data) => data[moment().format("YYYY-MM")]);
    });
  }, [id]);

  useEffect(() => {
    setIsLoading(true);
    getDataForChart(`?project_id=${id}&date=${month}`).then((res) => {
      let data = res && res.data && res.data.data;
      let series = [
        {
          name: "General Expense",
          data: Object.values(data.generalExpenseData),
        },
        {
          name: "Raw Material Expense",
          data: Object.values(data.rawMaterialExpenseData),
        },
        {
          name: "Customer Revenue",
          data: Object.values(data.customerRevenueData),
        },
        {
          name: "Staff Salary",
          data: Object.values(data.staffSalaryData),
        },
        {
          name: "Customer Refund",
          data: Object.values(data.customerRefundData),
        },
      ];
      setSeries(series);
      setCategories(Object.keys(data.generalExpenseData));
      setIsLoading(false);
    });
  }, [id, month]);

  const staffSalaryStatementDownload = () => {
    let query = `?project_id=${id}&projectname=${projectName}`;
    if (!isLoading) {
      setStatementLoading(true);
      staffSalaryStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading(false);
        })
        .catch((error) => {});
    }
  };
  const bookedUnitStatementDownload = () => {
    let query = `?project_id=${id}&projectname=${projectName}`;
    if (!isLoading) {
      setStatementLoading(true);
      bookedUnitsStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading(false);
        })
        .catch((error) => {});
    }
  };
  const departmentStatementDownload = () => {
    let query = `?project_id=${id}&projectname=${projectName}`;
    if (!isLoading) {
      setStatementLoading(true);
      departmentStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading(false);
        })
        .catch((error) => {});
    }
  };
  const partyStatementDownload = () => {
    let query = `?project_id=${id}&projectname=${projectName}`;
    if (!isLoading) {
      setStatementLoading(true);
      partyStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading(false);
        })
        .catch((error) => {});
    }
  };
  const generalBillStatementDownload = () => {
    let query = `?project_id=${id}&projectname=${projectName}`;
    if (!isLoading) {
      setStatementLoading(true);
      generalBillStatement(query)
        .then((res) => {
          let data = res.data && res.data.data;
          window.open(data.path, "download");
          setStatementLoading(false);
        })
        .catch((error) => {});
    }
  };

  const handleShowInterest = () => {
    let data = { interest: true };
    updateFormData("PROJECT", id, data)
      .then((res) => {
        setToggle(!toggle);
        setToggle2(false);
        setToggle3(!toggle3);
        notifySuccess("Interest Is Now Applicable On This Project ");
      })
      .catch((err) => {
        notifyError("Server Error");
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title="Once you enable the interest on project after that you can not change it?"
        showCancel
        confirmBtnText="YES!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        style={{ width: "40%" }}
        onConfirm={() => {
          handleShowInterest();
        }}
        onCancel={() => {
          setToggle3(!toggle3);
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">
                {projectName} Dashboard
              </h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <Chart
                  series={series}
                  categories={categories}
                  handleMonthChange={(month) => {
                    setMonth(month);
                  }}
                  isLoading={isLoading}
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Project Expense Overview</CardTitle>
                <Row>
                  <OverviewCard
                    title={"Total Project Income"}
                    data={numDifferentiation(
                      (counts.totalIncome - counts.refundAmount).toFixed(2)
                    )}
                    image={[
                      { image: customerRevenue, name: "Customer Revenue" },
                      { image: remove },
                      { image: refund, name: "Customer Refund" },
                    ]}
                  />
                  <OverviewCard
                    title={"Total Project Expense"}
                    data={numDifferentiation(
                      (
                        counts.totalEmployeeSalary +
                        counts.totalGeneralExpense +
                        counts.totalExpenses
                      ).toFixed(2)
                    )}
                    image={[
                      { image: rawMaterial, name: "Raw Material Expense" },
                      { image: add },
                      { image: expense, name: "General Expense" },
                      { image: add },
                      { image: staffSalary, name: "Staff Salary" },
                    ]}
                  />
                  <OverviewCard
                    title={"Outstanding Amount"}
                    data={numDifferentiation(
                      (
                        counts.totalIncome -
                        counts.totalEmployeeSalary -
                        counts.totalGeneralExpense -
                        counts.totalExpenses -
                        counts.refundAmount
                      ).toFixed(2)
                    )}
                    image={[
                      { image: rawMaterial, name: "Raw Material Expense" },
                      { image: add },
                      { image: expense, name: "General Expense" },
                      { image: add },
                      { image: staffSalary, name: "Staff Salary" },
                      { image: remove },
                      { image: customerRevenue, name: "Customer Revenue" },
                    ]}
                    color={
                      counts.totalIncome -
                        counts.totalEmployeeSalary -
                        counts.totalGeneralExpense -
                        counts.totalExpenses >
                      0
                        ? "green"
                        : "red"
                    }
                    tooltipText="Outstanding Amount"
                  />
                  <OverviewCard
                    title={"Total Booking Amount"}
                    data={numDifferentiation(
                      counts.totalBookingAmount.toFixed(2)
                    )}
                    image={[
                      {
                        image: customerRevenue,
                        name: "Total Booking Amount",
                      },
                    ]}
                  />
                </Row>
              </CardBody>
            </Card>
            {landid ? (
              <Card>
                <CardBody>
                  <CardTitle>Project Expense Overview</CardTitle>
                  <Row>
                    <OverviewCard
                      title={"Total Project Income"}
                      data={numDifferentiation(
                        (counts.totalIncome - counts.refundAmount).toFixed(2)
                      )}
                      image={[
                        { image: customerRevenue, name: "Customer Revenue" },
                      ]}
                    />
                    <OverviewCard
                      title={"Total Project Expense"}
                      data={numDifferentiation(
                        (
                          counts.totalEmployeeSalary +
                          counts.totalGeneralExpense +
                          counts.totalExpenses +
                          counts.TotalLandPayment
                        ).toFixed(2)
                      )}
                      image={[
                        { image: rawMaterial, name: "Raw Material Expense" },
                        { image: add },
                        { image: expense, name: "General Expense" },
                        { image: add },
                        { image: staffSalary, name: "Staff Salary" },
                        ...(landid
                          ? [
                              { image: add },
                              { image: LandImage, name: "Land Expenses" },
                            ]
                          : []),
                      ]}
                    />
                    <OverviewCard
                      title={"Outstanding Amount"}
                      data={numDifferentiation(
                        (
                          counts.totalIncome -
                          counts.totalEmployeeSalary -
                          counts.totalGeneralExpense -
                          counts.totalExpenses -
                          counts.TotalLandPayment -
                          counts.refundAmount
                        ).toFixed(2)
                      )}
                      image={[
                        { image: rawMaterial, name: "Raw Material Expense" },
                        { image: add },
                        { image: expense, name: "General Expense" },
                        { image: add },
                        { image: staffSalary, name: "Staff Salary" },
                        ...(landid
                          ? [
                              { image: add },
                              { image: LandImage, name: "Land Expenses" },
                            ]
                          : []),
                        { image: remove },
                        { image: customerRevenue, name: "Customer Revenue" },
                      ]}
                      color={
                        counts.totalIncome -
                          counts.totalEmployeeSalary -
                          counts.totalGeneralExpense -
                          counts.totalExpenses -
                          counts.totalLandValue >
                        0
                          ? "green"
                          : "red"
                      }
                      tooltipText="Outstanding Amount"
                    />
                    <OverviewCard
                      title={"Total Booking Amount"}
                      data={numDifferentiation(
                        counts.totalBookingAmount.toFixed(2)
                      )}
                      image={[
                        {
                          image: customerRevenue,
                          name: "Total Booking Amount",
                        },
                      ]}
                    />
                  </Row>
                </CardBody>
              </Card>
            ) : null}
            <Card>
              <CardBody>
                <CardTitle className="h2 mt-0">Project Overview</CardTitle>
                <Row>
                  <CardForDetails
                    title={"Booked Units"}
                    data={bookedUnits.length}
                    image={bookedUnit}
                    redirectURL={`/booked-units?project_id=${id}`}
                    click={() => bookedUnitStatementDownload()}
                    showDownload={true}
                    tooltip={"book-unit"}
                    statementLoading={statementLoading}
                  />
                  <CardForDetails
                    title={"Remaining Units"}
                    data={counts.totalUnits - bookedUnits.length}
                    image={forSale}
                    redirectURL={`/unpaid-units?project_id=${id}`}
                    showDownload={false}
                  />
                  <CardForDetails
                    title={"Total Units"}
                    data={counts.totalUnits}
                    image={totalUnits}
                    showDownload={false}
                  />
                  <CardForDetails
                    title={"Cancel Booking"}
                    data={counts.cancelBooking}
                    image={cancelBooking}
                    showDownload={false}
                  />

                  <OverviewCardNew
                    title={"Customer Revenue / Refund"}
                    data={numDifferentiation(
                      counts.totalIncome - counts.refundAmount
                    )}
                    image={[
                      { image: customerRevenue, name: "customer-revenue" },
                      { image: remove },
                      { image: refund, name: "customer-refund" },
                    ]}
                    redirectURL={`/dashboard-customer-revenue?project_id=${id}&project=${projectName}`}
                    click={() => bookedUnitStatementDownload()}
                    showDownload={true}
                    tooltip={"custome-revenue/refund"}
                    statementLoading={statementLoading}
                  />
                  <CardForDetails
                    title={"Raw Material Expense"}
                    data={numDifferentiation(counts.totalExpenses.toFixed(2))}
                    image={rawMaterial}
                    redirectURL={`/raw-material-overview?project_id=${id}&project=${projectName}`}
                    click={() => departmentStatementDownload()}
                    showDownload={true}
                    partyMaterial={true}
                    partyTooltip={"party-overview"}
                    tooltip={"department-overview"}
                    partyClick={() => partyStatementDownload()}
                    statementLoading={statementLoading}
                  />
                  <CardForDetails
                    title={"General Expense"}
                    data={numDifferentiation(
                      counts.totalGeneralExpense.toFixed(2)
                    )}
                    image={expense}
                    redirectURL={`/dashboard-general-expanses?project_id=${id}&project=${projectName}`}
                    click={() => generalBillStatementDownload()}
                    showDownload={true}
                    tooltip={"general-expenses"}
                    statementLoading={statementLoading}
                  />
                  <CardForDetails
                    title={"Staff Salary"}
                    data={numDifferentiation(
                      counts.totalEmployeeSalary.toFixed(2)
                    )}
                    image={staffSalary}
                    redirectURL={`/dashboard-staff-salary?project_id=${id}&project=${projectName}`}
                    click={() => staffSalaryStatementDownload()}
                    showDownload={true}
                    tooltip={"staff-salary"}
                    statementLoading={statementLoading}
                  />
                  {landid ? (
                    <CardForDetails
                      title={"Land Expense"}
                      data={numDifferentiation(
                        counts.TotalLandPayment.toFixed(2)
                      )}
                      image={LandImage}
                      redirectURL={`/land-details?land_id=${landid}`}
                      showDownload={false}
                      statementLoading={statementLoading}
                    />
                  ) : null}
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <CardTitle> Statement Overview</CardTitle>
                <div>
                  <StatementTable data={statementData} projectId={id} />
                </div>
              </CardBody>
            </Card>
            {toggle2 && (
              <Card>
                <CardBody>
                  <div className="text-center" style={{ fontSize: "20px" }}>
                    This Projects Expenses and Incomes are not associated with
                    any type of interest. Do you want to enable the interest
                    calculation for this project?
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      className="m-2 my-3 btn-primary"
                      onClick={() => {
                        setToggle3(!toggle3);
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="m-2"
                      onClick={() => {
                        setToggle2(false);
                      }}
                    >
                      No
                    </Button>
                  </div>
                </CardBody>
              </Card>
            )}
            {project["interest"] && (
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <CardTitle>Interest Amount</CardTitle>
                    <Link
                      to={`/monthly-interest?project_id=${id}`}
                      style={{ color: "white" }}
                    >
                      <Button color="primary">Show Interest Rate</Button>
                    </Link>
                  </div>
                  <div className="my-1">
                    <InterestTable projectId={id} />
                  </div>
                </CardBody>
              </Card>
            )}
            {toggle3 && sweetAlertFuction()}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ProjectDashboard;

const CardForDetails = (props) => {
  const history = useHistory();
  return (
    <Col lg={3}>
      <Card
        className="d-flex justify-content-center align-items-center"
        style={{ height: "10rem" }}
      >
        {props.showDownload && (
          <OverlayTrigger
            key="tooltip"
            placement="top"
            overlay={<Tooltip>{props.tooltip}</Tooltip>}
          >
            <Button
              id="DataExport"
              color="gray"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: "0.5rem",
              }}
              onClick={props.click}
              disabled={props.statementLoading}
            >
              <i className="mdi mdi-download"></i>
            </Button>
          </OverlayTrigger>
        )}
        {props.partyMaterial && (
          <OverlayTrigger
            key="tooltip"
            placement="top"
            overlay={<Tooltip>{props.partyTooltip}</Tooltip>}
          >
            <Button
              id="DataExport"
              color="gray"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                margin: "0.5rem",
              }}
              onClick={props.partyClick}
              disabled={props.statementLoading}
            >
              <i className="mdi mdi-download"></i>
            </Button>
          </OverlayTrigger>
        )}

        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            width: 50,
            height: 50,
            borderRadius: "50%",
            border: "1px solid red",
            cursor: "pointer",
          }}
          onClick={() =>
            props.redirectURL ? history.push(props.redirectURL) : ""
          }
        >
          <img
            src={props.image}
            style={{ width: 35, height: 35 }}
            alt="CardImage"
          />
        </div>
        <CardTitle
          style={{ margin: "10px 20px 0px 20px", cursor: "pointer" }}
          className="h3"
          onClick={() =>
            props.redirectURL ? history.push(props.redirectURL) : ""
          }
        >
          {props.title}
        </CardTitle>

        <div className="d-flex justify-content-between">
          <div
            style={{ margin: "10px 20px 0px 20px" }}
            className="d-flex justify-content-between"
          >
            <h4>{props.data}</h4>
          </div>
        </div>
      </Card>
    </Col>
  );
};

const OverviewCard = (props) => {
  return (
    <Col lg={3} className="overview-card">
      <Card
        style={
          props.title === "Total Booking Amount"
            ? { backgroundColor: "bisque" }
            : null
        }
      >
        <CardTitle style={{ margin: "10px 20px 0px 20px" }} className="h3">
          <span className="d-flex justify-content-center">{props.title}</span>
        </CardTitle>
        <div>
          <div style={{ margin: "10px 20px 0px 20px" }}>
            <div className="my-2">
              <span className="avatar text-primary rounded d-flex justify-content-center w-100">
                {props.image.map(({ image, name }, index) => {
                  return (
                    <div
                      className="d-flex justify-content-center align-items-center mx-2"
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: "50%",
                        border:
                          image === add || image === remove
                            ? ""
                            : "1px solid red",
                      }}
                      key={index}
                    >
                      {name ? (
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id="tooltip-bottom"
                              className="font-family-class font-12 fw-normal line-height-17 text-white"
                            >
                              {name}
                            </Tooltip>
                          }
                        >
                          <img
                            src={image}
                            style={{ width: "75%", height: "75%" }}
                            alt="TotalLink"
                            className="mx-2"
                          />
                        </OverlayTrigger>
                      ) : (
                        <img
                          src={image}
                          style={{ width: "75%", height: "75%" }}
                          alt="TotalLink"
                          className="mx-2"
                        />
                      )}
                    </div>
                  );
                })}
              </span>
              <h2
                className="my-3 d-flex justify-content-center"
                style={{ color: props.color ? props.color : "" }}
              >
                {props.data}
              </h2>
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

const OverviewCardNew = (props) => {
  const history = useHistory();
  return (
    <Col lg={3} className="overview-card">
      <Card
        className="d-flex justify-content-center align-items-center"
        style={{ height: "10rem" }}
      >
        {props.showDownload && (
          <OverlayTrigger
            key="tooltip"
            placement="top"
            overlay={<Tooltip>{props.tooltip}</Tooltip>}
          >
            <Button
              id="DataExport"
              color="gray"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                margin: "0.5rem",
              }}
              onClick={props.click}
              disabled={props.statementLoading}
            >
              <i className="mdi mdi-download"></i>
            </Button>
          </OverlayTrigger>
        )}
        <div>
          <div style={{ margin: "10px 20px 0px 20px" }}>
            <div className="my-2">
              <span className="avatar text-primary rounded d-flex justify-content-center w-100">
                {props.image.map(({ image, name }, index) => {
                  return (
                    <div
                      className="d-flex justify-content-center align-items-center "
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        border: "1px solid red",
                        cursor: "pointer",
                        border:
                          image === add || image === remove
                            ? ""
                            : "1px solid red",
                      }}
                      onClick={() =>
                        props.redirectURL ? history.push(props.redirectURL) : ""
                      }
                      key={index}
                    >
                      {name ? (
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip
                              id="tooltip-bottom"
                              className="font-family-class font-12 fw-normal line-height-17 text-white"
                            >
                              {name}
                            </Tooltip>
                          }
                        >
                          <img
                            src={image}
                            style={{ width: "75%", height: "75%" }}
                            alt="TotalLink"
                            className="mx-2"
                          />
                        </OverlayTrigger>
                      ) : (
                        <img
                          src={image}
                          style={{ width: "35%", height: "35%" }}
                          alt="TotalLink"
                          className="mx-2"
                        />
                      )}
                    </div>
                  );
                })}
              </span>
              <CardTitle
                style={{ margin: "10px 20px 0px 20px", cursor: "pointer" }}
                className="h3"
                // onClick={() =>
                //   // props.redirectURL ? history.push(props.redirectURL) : ""
                // }
              >
                {props.title}
              </CardTitle>
              <h4
                className="my-3 d-flex justify-content-center"
                style={{ color: props.color ? props.color : "" }}
              >
                {props.data}
              </h4>
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

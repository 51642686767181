import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  addUnitIncome,
  getFormData,
  getUnitDetails,
} from "../../services/userService.js";
import Select from "react-select";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { useState } from "react";
import moment from "moment";

const AddUnitIncome = (props) => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [project, setProject] = useState("");
  const [unit, setUnit] = useState("");
  const [customer, setCustomer] = useState("");
  const [loading, setLoading] = useState(false);
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
  ];
  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);

  const getAllProjects = () => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((project) => {
        return { label: project.name, value: project._id };
      });
      setProjectOptions(options);
    });
  };
  useEffect(() => {
    getAllProjects();
  }, []);

  const getAllUnits = (value) => {
    getFormData("UNIT&project_id=" + value.value).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data.map((unit) => {
        return { label: unit.name, value: unit._id };
      });
      setUnitOptions(options);
    });
  };

  const getCustomer = (unit, setFieldValue) => {
    if (unit === "") {
      return;
    }
    getUnitDetails(unit.value).then((res) => {
      let [data] = res.data && res.data.data && res.data.data.data;
      let customer = data && data.customer_id;
      setUnit(unit);
      customer && setCustomer({ label: customer.name, value: customer._id });
      customer && setFieldValue("customer_name", customer.name);
      !customer && setFieldValue("customer_name", "");
    });
  };

  let validationObject = {
    payment_mode: Yup.string().trim().required(`Please Select Payment Mode`),
    payment_date: Yup.string().trim().required(`Please Select Payment Date`),
    amount: Yup.number()
      .required(`Please Enter amount`)
      .min(0, "Amount must be greater than 0"),
    note: Yup.string().max(100, "notes must be less than 100 characters"),
  };

  let initialValues = {
    project_id: "",
    unit_id: "",
    customer_name: customer ? customer.name : "",
    customer_id: "",
    payment_date: moment().format("YYYY-MM-DD").toString(),
    payment_mode: paymentModeOptions[0].value,
    amount: "",
    note: "",
  };

  let submitFormSchema = Yup.object().shape(validationObject);
  const validateFunction = () => {
    let errors = {};
    if (project === "") {
      errors.project_id = "Please Select a Project";
    }
    if (unit === "") {
      errors.unit_id = "Please Select a Unit";
    }
    if (customer === "") {
      errors.customer_name = "Unit Has No Customer";
    }
    return errors;
  };

  //? handles
  const handleSubmit = (values, { errors, setFieldError, resetForm }) => {
    let data = {
      ...values,
      project_id: project.value,
      customer_id: customer.value,
      unit_id: unit.value,
    };
    setLoading(true);
    addUnitIncome(data)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        notifySuccess("Customer Payment Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };
  const handleProjectChange = (value) => {
    getAllUnits(value);
    setProject(value);
    setUnit("");
    setCustomer("");
  };
  const handleUnitChange = (value, setFieldValue) => {
    setCustomer("");
    getCustomer(value, setFieldValue);
  };
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
            validate={validateFunction}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
              setFieldError,
            }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>
                    Project <Required />
                  </label>
                  <Select
                    options={projectOptions}
                    name="project_id"
                    value={project}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      resetForm();
                      handleProjectChange(value);
                    }}
                    placeholder={"Select Project"}
                  />
                  {errors.project_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.project_id}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Unit <Required />
                  </label>
                  <Select
                    options={unitOptions}
                    name="unit_id"
                    value={unit}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldError("unit_id", "");
                      handleUnitChange(value, setFieldValue);
                    }}
                    placeholder={"Select Unit"}
                  />
                  {errors.unit_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.unit_id}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Customer Name <Required />
                  </label>
                  <CustomInput
                    type="text"
                    name="customer_name"
                    values={values}
                    readOnly={true}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Amount <Required />
                  </label>
                  <CustomInput
                    type="number"
                    values={values}
                    name="amount"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Mode <Required />
                  </label>
                  <Select
                    options={paymentModeOptions}
                    name="payment_mode"
                    value={paymentMode}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldValue("payment_mode", value.value);
                      handlePaymentModeChange(value);
                    }}
                    placeholder={"Select Payment Mode"}
                  />
                  {errors.payment_mode && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.payment_mode}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Date <Required />
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    max={moment().format("YYYY-MM-DD")}
                    name="payment_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>Note</label>
                  <CustomInput
                    type="text"
                    values={values}
                    name="note"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddUnitIncome;

import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomInput, { Required } from "../../components/Custom/textinput.js";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";

import {
  getFormData,
  updatePartyPayments,
} from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import moment from "moment";

const departmentOptions = [];
const projectOptions = [];
const partyOptions = [];
const paymentModeOptions = [
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "Online", label: "Online" },
];
const UpdatePartyPayment = (props) => {
  const defaultData = props.data;

  const [department, setDepartment] = useState({
    label: defaultData.department_id ? defaultData.department_id.name : "",
    value: defaultData.department_id ? defaultData.department_id._id : "",
  });
  const [project, setProject] = useState({
    label: defaultData.project_id ? defaultData.project_id.name : "",
    value: defaultData.project_id ? defaultData.project_id._id : "",
  });
  const [party, setParty] = useState({
    label: defaultData.party_id ? defaultData.party_id.name : "",
    value: defaultData.party_id ? defaultData.party_id._id : "",
  });
  const [paymentMode, setPaymentMode] = useState({
    value: defaultData.payment_mode,
    label: defaultData.payment_mode,
  });

  const handleDepartmentChange = (value) => {
    setParty("");
    setDepartment(value);
    getAllParties(value.value);
  };
  const handleProjectChange = (value) => {
    setProject(value);
  };
  const handlePartyChange = (value) => {
    setParty(value);
  };
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  const handleSubmit = (values) => {
    let data = {
      ...values,
      project_id: project.value,
      department_id: department.value,
      party_id: party.value,
      payment_mode: paymentMode.value,
    };

    updatePartyPayments(defaultData._id, data)
      .then((res) => {
        props.toggle();
        notifySuccess("Payment Updated Successfully");
      })
      .catch((error) => {
        notifyError(error.data.error.message);
      });
  };

  const getAllProjects = () => {
    getFormData("PROJECT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      projectOptions.length = 0;
      data.map((project) => {
        return projectOptions.push({
          label: project.name,
          value: project._id,
        });
      });
    });
  };

  const getAllDepartments = () => {
    getFormData("DEPARTMENT").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      departmentOptions.length = 0;
      data.map((department) => {
        return departmentOptions.push({
          label: department.name,
          value: department._id,
        });
      });
    });
  };
  const getAllParties = (value) => {
    getFormData("PARTY").then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      partyOptions.length = 0;
      data.map((party) => {
        if (party.department_id._id === value) {
          return partyOptions.push({
            label: party.name,
            value: party._id,
          });
        } else {
          return null;
        }
      });
    });
  };

  useEffect(() => {
    getAllProjects();
    getAllDepartments();
  }, []);

  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    if (project === "") {
      errors.project_id = "Please Select Project";
    }
    if (department === "") {
      errors.department_id = "Please Select Department";
    }
    if (party === "") {
      errors.party_id = "Please Select Party";
    }
    return errors;
  };

  let validationObject = {
    payment_date: Yup.date().required("Please Enter Date"),
    note: Yup.string().required("Please Enter Note"),
    amount: Yup.number().required("Please Enter Amount"),
  };

  let submitFormSchema = Yup.object().shape(validationObject);
  const initialValues = {
    project_id: project.value,
    department_id: department.value,
    party_id: party.value,
    payment_mode: defaultData.payment_mode,
    amount: defaultData.amount,
    payment_date: moment(defaultData.payment_date).format("YYYY-MM-DD"),
    note: defaultData.note,
  };

  return (
    <>
      <Modal isOpen={props.modal} centered={true} style={{ width: "100%" }}>
        <ModalHeader toggle={props.toggle}>Update Party Payments</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={submitFormSchema}
            validate={validationFunction}
          >
            {({ errors, touched, values, handleChange, setFieldError }) => (
              <Form>
                {/* <div style={{ height: "100px" }}>
                  <label>
                    Project
                    <Required />{" "}
                  </label>
                  <Select
                    options={projectOptions}
                    value={project}
                    onChange={(value) => {
                      setFieldError("project_id", "");
                      handleProjectChange(value);
                    }}
                    placeholder={"Select Project"}
                  />
                  {errors.project_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.project_id}
                    </div>
                  )}
                </div> */}
                <div style={{ height: "100px" }}>
                  <label>
                    Department <Required />
                  </label>
                  <Select
                    options={departmentOptions}
                    name="department_id"
                    value={department}
                    onChange={(value) => {
                      setFieldError("department_id", "");

                      handleDepartmentChange(value);
                    }}
                    placeholder={"Select Department"}
                  />
                  {errors.department_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.department_id}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Party <Required />
                  </label>
                  <Select
                    options={partyOptions}
                    name="party_id"
                    value={party}
                    onChange={(value) => {
                      setFieldError("party_id", "");

                      handlePartyChange(value);
                    }}
                    placeholder={"Select Party"}
                  />
                  {errors.party_id && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.party_id}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Amount <Required />
                  </label>
                  <CustomInput
                    type="number"
                    values={values}
                    placeholder={"Enter Amount"}
                    name="amount"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Mode <Required />
                  </label>
                  <Select
                    options={paymentModeOptions}
                    name="payment_mode"
                    value={paymentMode}
                    errors={errors}
                    touched={touched}
                    onChange={(value) => {
                      setFieldError("payment_mode", "");
                      handlePaymentModeChange(value);
                    }}
                    placeholder={"Select Payment Mode"}
                  />
                  {errors.payment_mode && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.payment_mode}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Payment Date <Required />
                  </label>
                  <CustomInput
                    type="date"
                    values={values}
                    placeholder={"Select Date"}
                    name="payment_date"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>
                    Note <Required />
                  </label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder={"Enter Note"}
                    name="note"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdatePartyPayment;

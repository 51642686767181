export const STORAGEKEY = {
  NAME: "NAME",
  ACCESSTOKEN: "ACCESSTOKEN",
  ROLE: "ROLE",
  EMAIL: "EMAIL",
  MANAGEMENT: "MANAGEMENT",
  CUSTOMER_REVENUE: "CUSTOMER_REVENUE",
  PARTY_PAYMENT: "PARTY_PAYMENT",
  RAW_MATERIAL_BILL: "RAW_MATERIAL_BILL",
  GENERAL_BILL: "GENERAL_BILL",
  CANCEL_BOOKING: "CANCEL_BOOKING",
  STAFF_SALARY: "STAFF_SALARY",
  CUSTOMER_REFUND:"CUSTOMER_REFUND"
};

import React, { useState, useEffect } from "react";
import { Card, CardBody, Table, Button, CardTitle } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import {
  deleteSalaryPayRoll,
  getSalaryPayRoll,
} from "../../services/userService";
import SweetAlert from "react-bootstrap-sweetalert";
import { notifySuccess } from "../../components/Custom/notification";
import moment from "moment";
import { numDifferentiation } from "../../helpers/amount_helper";
import UpdateSalaryPayroll from "./UpdateSalaryPayroll";
import AddSalaryPayroll from "./AddSalaryPayroll";
import Loader from "../../components/Custom/Loader";

const title = "Salary PayRoll";
const EmployeePayroll = ({ employeeId, employeeDetails, setTotalAmount }) => {
  const [addModal, setAddModal] = useState(false);
  const [employeesPayRoll, setEmployeesPayRoll] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployeePayroll = () => {
    setLoading(true);
    getSalaryPayRoll(employeeId)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setEmployeesPayRoll(data);
        const totalAmount = data.reduce((acc, payroll) => {
          let a = moment(payroll.start_month);
          let b = moment(payroll.end_month);
          const month =
            (b.year() - a.year()) * 12 + (b.month() - a.month() + 1);
          return (acc += payroll.amount * month);
        }, 0);
        setTotalAmount(totalAmount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmployeePayroll();
  }, []);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
  };

  return (
    <React.Fragment>
      <div>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="d-flex justify-content-between">
                <span>Employee Salary Details</span>
                <Button
                  type="submit"
                  name="btn"
                  className="custombtn mr-2"
                  style={{
                    marginRight: 10,
                  }}
                  color="primary"
                  onClick={showAddConfigModal}
                >
                  Add
                </Button>
                {addModal && employeeDetails && (
                  <AddSalaryPayroll
                    modal={addModal}
                    title={title}
                    handleCloseModal={showAddConfigModal}
                    employeeDetails={employeeDetails}
                    getEmployeePayroll={getEmployeePayroll}
                  />
                )}
              </div>
            </CardTitle>
            <Table hover>
              <thead>
                <tr>
                  <th className="text-center" style={{ minWidth: "4rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "10rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Start Month</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>End Month</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Salary</h6>
                  </th>
                  <th className="text-center" style={{ minWidth: "5rem" }}>
                    <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && <Loader />}
                {!loading && employeesPayRoll?.length === 0 && (
                  <tr>
                    <td colSpan={12}>
                      <EmptyView
                        title={"Sorry!"}
                        discription={"No Data Found"}
                        bgcolor={"white"}
                      ></EmptyView>
                    </td>
                  </tr>
                )}
                {!loading &&
                  employeesPayRoll?.map((item, index) => (
                    <GetDataList
                      data={item}
                      key={index}
                      index={index}
                      getEmployeePayroll={getEmployeePayroll}
                    />
                  ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default EmployeePayroll;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteSalaryPayRoll(data._id).then((res) => {
      handleShowDeleteModal();
      props.getEmployeePayroll();
      notifySuccess("Salary Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">
          {moment(data.start_month).format("MMM YYYY")}
        </td>
        <td className="text-center">
          {moment(data.end_month).format("MMM YYYY")}
        </td>
        <td className="text-center">
          {numDifferentiation(data.amount.toFixed(2))}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateSalaryPayroll
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              data={props.data}
              getEmployeePayroll={props.getEmployeePayroll}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

import React, { useState } from "react";
import { PaginationFunction } from "../../components/Custom/Pagination";
import { Card, CardBody, CardTitle, Input, Table } from "reactstrap";
import {
  deletePartyPayments,
  getAllPartyPayments,
} from "../../services/userService";
import { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import EmptyView from "../../components/Custom/EmptyView";
import { numDifferentiation } from "../../helpers/amount_helper";
import UpdatePartyPayment from "../PartyExpenses.js/UpdatePartyPayment";
import Loader from "../../components/Custom/Loader";

const PaymentLedger = (props) => {
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [paymentList, setPaymentList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPaymentDetails = () => {
      const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&date=${props.date}`;
      setLoading(true);
      getAllPartyPayments("", pageAndLimit)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          setPaymentList(data);
          setTotalCount(res.data.data.totalCount);
          setLoading(false);
        })
        .catch((_err) => setLoading(false));
    };
    getPaymentDetails();
  }, [activePage, pageDataCount, props.date, toggle]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  return (
    <div>
      <Card>
        <CardBody style={{ overflowX: "scroll" }}>
          <CardTitle
            className="h3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Party Payment List
          </CardTitle>
          {loading && <Loader />}
          <Table hover>
            <thead>
              <tr>
                <th className="text-center" style={{ minWidth: "4rem" }}>
                  <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                </th>
                <th className="text-center" style={{ minWidth: "5rem" }}>
                  <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                </th>
                <th className="text-center" style={{ minWidth: "5rem" }}>
                  <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                </th>
                <th className="text-center" style={{ minWidth: "5rem" }}>
                  <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                </th>
                <th className="text-center" style={{ minWidth: "5rem" }}>
                  <h6 style={{ fontWeight: "bold" }}>Note</h6>
                </th>
                <th className="text-center" style={{ minWidth: "5rem" }}>
                  <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading && paymentList.length === 0 && (
                <tr>
                  <td colSpan={12}>
                    <EmptyView
                      title={"Sorry!"}
                      discription={"No Payments Found"}
                      bgcolor={"white"}
                    ></EmptyView>
                  </td>
                </tr>
              )}
              {!loading &&
                paymentList.length > 0 &&
                paymentList.map((item, index) => {
                  return (
                    <GetDataList
                      data={item}
                      key={index}
                      index={index}
                      activePage={activePage}
                      pageDataCount={pageDataCount}
                      toggle={() => {
                        setToggle(!toggle);
                      }}
                    ></GetDataList>
                  );
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={10}>
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      justifyItems: "flex-start",
                    }}
                  >
                    <PaginationFunction
                      activePage={activePage}
                      pageDataCount={pageDataCount}
                      totalCount={totalCount}
                      handlePageChange={handlePageChange}
                    />

                    <Input
                      style={{
                        width: "47px",
                        height: "45px",
                        marginLeft: "20px",
                        marginTop: "18px",
                        borderColor: "#00b3f4",
                        cursor: "pointer",
                      }}
                      name="pageid"
                      type="select"
                      onChange={(e) => handleChange(e)}
                    >
                      <option selected={pageDataCount === 5 ? 5 : ""}>
                        05
                      </option>
                      <option selected={pageDataCount === 10 ? 10 : ""}>
                        10
                      </option>
                      <option selected={pageDataCount === 20 ? 20 : ""}>
                        20
                      </option>
                      <option selected={pageDataCount === 30 ? 30 : ""}>
                        30
                      </option>
                    </Input>
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default PaymentLedger;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deletePartyPayments(data._id).then((res) => {
      props.toggle();
      handleShowDeleteModal();
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this payment ?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {data.amount ? numDifferentiation(data.amount.toFixed(2)) : "-"}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{data.note}</td>

        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdatePartyPayment
              modal={updateModal}
              toggle={handleShowUpdateModal}
              handleCloseModal={handleShowUpdateModal}
              data={props.data}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import Select from "react-select";
import { addSalaryPayRoll } from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddSalaryPayroll = (props) => {
  const [loading, setLoading] = useState(false);
  const [employee] = useState(props.employeeDetails);

  let validationObject = {
    amount: Yup.number()
      .required(`Please Enter Amount`)
      .min(0, "Amount Must be Greater Than 0")
      .max(1000000, "Amount is too big"),
    start_month: Yup.date(),
    end_month: Yup.date(),
  };

  const initialValues = {
    amount: "",
    start_month: new Date(),
    end_month: new Date(),
  };

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values) => {
    const employee_id = employee._id;
    let data = {
      ...values,
      employee_id,
    };
    setLoading(true);
    addSalaryPayRoll(data)
      .then((res) => {
        props.handleCloseModal();
        props.getEmployeePayroll();
        setLoading(false);
        notifySuccess("Salary Added Successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <div className="d-flex flex-column gap-3 mb-3 mt-3">
                  <div>
                    <label>
                      Employee <Required />
                    </label>
                    <Select
                      options={[]}
                      name="employee_id"
                      value={{ label: employee.name }}
                      placeholder={"Select Employee"}
                    />
                    {errors.employee_id && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.employee_id}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={`Enter Amount`}
                      name="amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label>
                      Start Month <Required />
                    </label>
                    <Field name="start_month">
                      {({ field }) => (
                        <DatePicker
                          {...field}
                          selected={values.start_month}
                          onChange={(date) => {
                            setFieldValue("start_month", date);
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          className="form-control"
                        />
                      )}
                    </Field>
                  </div>
                  <div className="d-flex flex-column">
                    <label>
                      End Month <Required />
                    </label>
                    <Field name="end_month">
                      {({ field }) => (
                        <DatePicker
                          {...field}
                          selected={values.end_month}
                          onChange={(date) => {
                            setFieldValue("end_month", date);
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          className="form-control"
                        />
                      )}
                    </Field>
                  </div>
                  <ModalFooter className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      disabled={loading}
                      onSubmit={(values) => {
                        handleSubmit(values);
                      }}
                    >
                      {" "}
                      Add
                    </Button>
                  </ModalFooter>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddSalaryPayroll;

import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, CardTitle, Table, Input } from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import { getDepartmentExpenses } from "../../services/userService";
import { PaginationFunction } from "../../components/Custom/Pagination";
import moment from "moment";
import Loader from "../../components/Custom/Loader";
import { numDifferentiation } from "../../helpers/amount_helper";

const form = "DEPARTMENT-FORM";
const title = "Department";
const DepartmentExpenses = () => {
  let params = window.location.search;
  let department_id = new URLSearchParams(params).get("department_id");
  let project_id = new URLSearchParams(params).get("project_id");
  let department = new URLSearchParams(params).get("department");
  const [configData, setConfigData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  useEffect(() => {
    const getAllDepartmentWithParty = () => {
      const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&search=${search}`;
      let query = `?department_id=${department_id}&page=${activePage}&limit=${pageDataCount}&search=${search}`;
      if (project_id) {
        query = query + "&project_id=" + project_id;
      }
      setLoading(true);
      getDepartmentExpenses(query).then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      });
    };
    getAllLinks();
    getAllDepartmentWithParty();
  }, [toggle, activePage, pageDataCount, search, department_id, project_id]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("----error----", err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{department}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {department} Expense
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {department} Expense
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by ${title} Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                  </div>
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Party Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>
                          Material Received Date
                        </h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Expenses Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        dataKey={configData}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option>05</option>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default DepartmentExpenses;

const GetDataList = (props) => {
  const { data, index } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {data.party_id ? data.party_id.name : "-"}
        </td>
        <td className="text-center">{numDifferentiation(data.amount) }</td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.material_received_date).format("DD-MM-YYYY")}
        </td>

        <td className="text-center">
          {data.project_id ? data.project_id.name : "-"}
        </td>
        <td className="text-center">{data.note}</td>
        {props.dataKey.map((key, index) => {
          return (
            <td key={index} className="text-center">
              {data[key.item_name] || "-"}
            </td>
          );
        })}
        <td className="text-center">{data.partyCount}</td>
      </tr>
    </>
  );
};

import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput from "../../components/Custom/textinput.js";
import Select from "react-select";
import { updateFormItem } from "../../services/adminService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";

const UpdateConfiguration = (props) => {
  const initialData = props.data;
  const requireOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const itemTypeOptions = [
    { label: "Text", value: "Text" },
    { label: "Date", value: "Date" },
    { label: "Number", value: "Number" },
    { label: "Textarea", value: "textarea" },
  ];
  const [itemType, setItemType] = useState({ value: initialData.item_type });
  const [isRequired, setIsRequired] = useState({
    label: initialData.is_required === true ? "Yes" : "No",
    value: initialData.is_required,
  });
  const createSpaceSchema = Yup.object().shape({
    item_name: Yup.string().trim().required("Please enter Item name").max(10,'Maximum 10 Character Allowed'),
  });

  const handleSubmit = (values, { setFieldError, resetForm }) => {
    if (!itemType || itemType.value === "") {
      return setFieldError("item_type", "Please Select Item type");
    }
    if (!isRequired || isRequired.value === "") {
      return setFieldError("is_required", "Please Select a Required");
    }
    let inputData = {
      item_name: values.item_name,
      item_type: itemType.value,
      is_required: isRequired.value,
    };
    updateFormItem(inputData, props.form, initialData._id)
      .then((res) => {
        props.handleCloseModal();
        notifySuccess(`${props.title} Field Updated Successfully`);
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };
  const handleItemTypeChange = (value) => {
    setItemType(value);
  };
  const handleIsRequiredChange = (value) => {
    setIsRequired(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "20%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Update {props.title} Configuration
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              item_name: initialData.item_name,
              item_type: "",
              is_required: "",
            }}
            validationSchema={createSpaceSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, handleChange, resetForm }) => (
              <Form>
                <div style={{ height: "100px" }}>
                  <label>Item Name</label>
                  <CustomInput
                    type="text"
                    values={values}
                    placeholder="Enter Item Name"
                    name="item_name"
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ height: "100px" }}>
                  <label>Item Type</label>
                  <Select
                    options={itemTypeOptions}
                    name="item_type"
                    getOptionLabel={(option) => option.value}
                    defaultValue={itemType}
                    errors={errors}
                    touched={touched}
                    onChange={handleItemTypeChange}
                    placeholder={"Select Item Type"}
                  />
                  {errors.item_type && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.item_type}
                    </div>
                  )}
                </div>
                <div style={{ height: "100px" }}>
                  <label>Required </label>
                  <Select
                    options={requireOptions}
                    name="is_required"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    defaultValue={isRequired}
                    onChange={handleIsRequiredChange}
                    placeholder={"Select Required"}
                  />
                  {errors.is_required && (
                    <div
                      style={{
                        fontSize: 14,
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="text-left mt-1 text-danger"
                    >
                      {errors.is_required}
                    </div>
                  )}
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateConfiguration;

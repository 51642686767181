import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col,
  Button,
  Input,
} from "reactstrap";
// import PageLoader from "../../components/Common/CustomLoader/loader";
// import { NotificationManager } from "react-notifications";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
// import { notificationMessageFunction } from "../../constants/notificationConst";
import Dropzone from "react-dropzone";
// import { getDomainListForURL } from "../../services/domainService";
import { DownloadDemoCSVFile, addBulkUnits } from "../../services/userService";
import Loader from "../../components/Custom/Loader";

function AddBuncheUnits({ addBunchModal, handleCloseAddBunchModal }) {
  //   const [project, setProject] = useState("");
  const [addCSVFile, setAddCSVFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorCsv, setShowErrorCsv] = useState({
    isError: false,
    errorMessage: "",
  });

  const downloadDemoFile = () => {
    DownloadDemoCSVFile().then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      window.open(data, "download");
    });
  };

  const handleAcceptedFiles = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setAddCSVFile(acceptedFiles);
      setFileName(acceptedFiles[0].name);
      setShowErrorCsv({ isError: false, errorMessage: "" });
    } else {
      setShowErrorCsv({
        isError: true,
        errorMessage: "Select .XlSX file only",
      });
    }
  };

  const handleValidSubmit = (values, { resetForm, setFieldError }) => {
    let addBunchError = false;
    if (!values.project || values.project.trim().length <= 0) {
      setFieldError("project", "Project Name is required");
      addBunchError = true;
    }

    if (!addCSVFile || addCSVFile.length <= 0) {
      addBunchError = true;
      setShowErrorCsv({ isError: true, errorMessage: ".csv file is required" });
    } else {
      setShowErrorCsv({ isError: false, errorMessage: "" });
    }
    let data = new FormData();

    data.append("project_id", project_id);
    data.append("upload", addCSVFile[0]);

    if (!addBunchError && !showErrorCsv.isError) {
      setIsLoading(true);
      addBulkUnits(data)
        .then((res) => {
          let data = res && res.data && res.data.data && res.data.data.data;
          setIsLoading(false);
          handleCloseAddBunchModal();
          notifySuccess("Units Added Successfully");
        })
        .catch((err) => {
          setIsLoading(false);
          notifyError(err.data.error.message);
        });
      // startLoading();
    }
  };

  const handleCloseModal = () => {
    handleCloseAddBunchModal();
    setFileName("");
    setAddCSVFile([]);
    setShowErrorCsv({ isError: false, errorMessage: "" });
  };
  let params = window.location.search;
  let project_id = new URLSearchParams(params).get("project_id");
  let project = new URLSearchParams(params).get("project");

  return (
    <React.Fragment>
      <Modal
        isOpen={addBunchModal}
        centered={true}
        style={{
          maxWidth: `${"30%"} `,
        }}
      >
        <ModalHeader toggle={handleCloseModal}>Add Bulk Units</ModalHeader>
        <ModalBody>
          <div className="p-2">
            <Formik
              initialValues={{
                project: project,
              }}
              enableReinitialize
              onSubmit={handleValidSubmit}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <Col>
                    <label>Project name</label>
                    <div className="has-wrapper mb-20">
                      <Input
                        type="text"
                        value={project}
                        disabled={true}
                        name="project"
                      />
                    </div>
                  </Col>
                  <div
                    className="mt-4"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <h5 className="font-size-14 mt-2">Upload Units File</h5>
                    </Col>
                    <Button
                      id="demoFile"
                      style={{
                        marginRight: "7px",
                        marginTop: "-10px",
                      }}
                      onClick={downloadDemoFile}
                      color="primary"
                    >
                      Demo file
                      <i
                        className="mdi mdi-download"
                        style={{ marginLeft: "10px" }}
                      />
                    </Button>
                  </div>
                  <Col>
                    <div className="has-wrapper mt-2">
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleAcceptedFiles(acceptedFiles)
                        }
                        multiple={false}
                        accept=".xlsx"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dropzone"
                            style={{
                              border: `2px dashed ${
                                showErrorCsv.isError ? "#F32013" : "#00b3f4"
                              }`,
                            }}
                          >
                            {isLoading && <Loader />}
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  justifyContent: "space-around",
                                }}
                              >
                                <p
                                  className="font-size-16"
                                  style={{ marginTop: 65, padding: 10 }}
                                >
                                  {
                                    "First Download Demo File And ADD Units Data"
                                  }
                                  <br />
                                  {
                                    "Drag 'n' drop Units contained Excel file here, or click to select file"
                                  }
                                </p>
                                <em className="font-size-16">
                                  (Only *.xlsx will be accepted)
                                </em>
                                <p className="mt-4">
                                  <b>{fileName}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {showErrorCsv.isError && (
                        <span>
                          <div
                            style={{ fontSize: 14 }}
                            className="text-left mt-1 text-danger"
                          >
                            {showErrorCsv.errorMessage}
                          </div>
                        </span>
                      )}
                    </div>
                  </Col>
                  <ModalFooter style={{ borderTop: "none" }}>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isLoading}
                      onSubmit={(values) => handleValidSubmit(values)}
                    >
                      Add
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default AddBuncheUnits;

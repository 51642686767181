import React from "react";
import { Button, Card, CardBody, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getFormData, updateProjectStatus } from "../../services/userService";
import { useEffect } from "react";
import EmptyView from "../../components/Custom/EmptyView";
import Loader from "../../components/Custom/Loader";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.Login.user);
  // const handleSwitchToggle = () => {
  //   setShowBody(!showBody);
  // };
  const getAllProjects = () => {
    setIsLoading(true);
    getFormData("PROJECT")
      .then((res) => {
        let data = res.data && res.data.data && res.data.data.data;
        data.sort((a, b) => {
          const dateA = new Date(a["Launch Date"]);
          const dateB = new Date(b["Launch Date"]);
          return dateA - dateB;
        });
        data.reverse();
        if (user.role === "user" && user.dashboards) {
          const projects = data.filter((project) =>
            user.dashboard_projects.includes(project._id)
          );
          setProjects(projects);
        } else if (user.role === "superAdmin") {
          setProjects(data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllProjects();
  }, [user]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboards</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            {!isLoading &&
              projects?.length === 0 &&
              user &&
              user.role === "superAdmin" && (
                <div className="d-flex flex-column align-items-center text-center">
                  <EmptyView
                    title={"Sorry!"}
                    discription={"No Project Found"}
                    bgcolor={"white"}
                  ></EmptyView>
                  <Link
                    to={{ pathname: "/project-list" }}
                    style={{
                      width: 200,
                      margin: 16,
                    }}
                  >
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn"
                      color="primary"
                    >
                      Add Projects
                    </Button>
                  </Link>
                </div>
              )}
            {/* <Row> */}
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {isLoading && <Loader />}
                <Table hover>
                  <thead>
                    <tr>
                      <th
                        // className="text-center"
                        style={{ minWidth: "4rem" }}
                      >
                        <h6 style={{ fontWeight: "bold" }}>Project Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Area</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}> Launch Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Address</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Action</h6>
                      </th>
                      {user && user.role === "superAdmin" && (
                        <th
                          className="text-center"
                          style={{ minWidth: "5rem" }}
                        >
                          <h6 style={{ fontWeight: "bold" }}>Archive</h6>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading && projects?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Data Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projects?.map((item, index) =>
                      user && user.role === "superAdmin" ? (
                        <GetDataList
                          data={item}
                          key={index}
                          index={index}
                          getAllProjects={getAllProjects}
                        />
                      ) : (
                        <GetDataForUserList
                          data={item}
                          key={index}
                          index={index}
                        />
                      )
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            {/* </Row> */}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

const GetDataList = (props) => {
  const [status, setStatus] = useState(false);
  let getAllProjects = props.getAllProjects;
  const handleSwitchToggle = (id) => {
    setStatus(true);
    let data = {
      status: 1,
    };
    updateProjectStatus(id, data)
      .then((res) => {
        notifySuccess("project updated successfully");
        setTimeout(() => {
          getAllProjects();
          setStatus(false);
        }, 1000);
      })
      .catch((err) => {
        notifyError(err.data.error.message);
      });
  };
  return (
    <>
      <tr>
        <td>{props.data.name}</td>
        <td className="text-center">{props.data.area}</td>
        <td className="text-center">{props.data["Launch Date"]}</td>
        <td className="text-center">{props.data.Address}</td>
        <td className="text-center">
          {" "}
          <Link
            to={`/project-dashboard?id=${props.data._id}&project=${props.data.name}`}
            className="btn btn-primary waves-effect waves-light"
          >
            View Details
          </Link>
        </td>
        <td className="text-center">
          <Switch
            onColor="#3b5de7"
            offColor="#6c757d"
            height={17}
            width={32}
            checked={status}
            onChange={() => handleSwitchToggle(props.data._id)}
          />
        </td>
      </tr>
    </>
  );
};

const GetDataForUserList = (props) => {
  return (
    <>
      <tr>
        <td>{props.data.name}</td>
        <td className="text-center">{props.data.area}</td>
        <td className="text-center">{props.data["Launch Date"]}</td>
        <td className="text-center">{props.data.Address}</td>
        <td className="text-center">
          {" "}
          <Link
            to={`/project-dashboard?id=${props.data._id}&project=${props.data.name}`}
            className="btn btn-primary waves-effect waves-light"
          >
            View Details
          </Link>
        </td>
      </tr>
    </>
  );
};

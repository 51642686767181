import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
  Button,
} from "reactstrap";
import { Dropdown } from "react-bootstrap";
// import "../Links/links.css";
import EmptyView from "../../components/Custom/EmptyView";
import { getFormFields } from "../../services/adminService";
import {
  DeleteAllUnits,
  deleteFormData,
  getFormData,
} from "../../services/userService";
import AddUnit from "./AddUnit";
import { PaginationFunction } from "../../components/Custom/Pagination";
// import UpdateConfiguration from "./UpdateConfiguration";
import SweetAlert from "react-bootstrap-sweetalert";
import UpdateUnit from "./UpdateUnit";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import AddBuncheUnits from "./AddBunchUnits";
import Loader from "../../components/Custom/Loader";

const form = "UNIT-FORM";
const title = "Unit";
const ProjectUnits = () => {
  let params = window.location.search;
  let project_id = new URLSearchParams(params).get("project_id");
  let project = new URLSearchParams(params).get("project");

  const [configData, setConfigData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(5);
  const [search, setSearch] = useState("");
  const [toggle1, setToggle1] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [loading, setLoading] = useState(false);

  const callToggle = () => setToggle(!toggle);

  const toggleDeleteAll = () => {
    setDeleteAll(!deleteAll);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  useEffect(() => {
    const getAllUnits = () => {
      const pageAndLimit = `&page=${activePage}&limit=${pageDataCount}&search=${search}`;
      setLoading(true);
      getFormData("UNIT&project_id=" + project_id, pageAndLimit).then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      });
    };
    getAllLinks();
    getAllUnits();
  }, [toggle, activePage, pageDataCount, search, project_id, deleteAll]);

  const getAllLinks = () => {
    setLoading(true);
    getFormFields(form)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setConfigData(data);
        // setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("----error----", err);
      });
  };

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  const handleDeleteAllUnit = () => {
    setLoading(true);
    DeleteAllUnits(`?project_id=${project_id}`)
      .then((res) => {
        notifySuccess("All Units deleted successfully");
        toggleDeleteAll();
        setLoading(false);
      })
      .catch((err) => {
        notifyError("Something went wrong");
        setLoading(false);
      });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleDeleteAllUnit();
        }}
        onCancel={toggleDeleteAll}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">{title} Management</li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {project} Units
                  <div className="d-flex gap-1">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by ${title} Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                    <Dropdown drop="left" id="filters">
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-autoclose-false filters"
                      >
                        Add
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="newest"
                          onSelect={() => {
                            showAddConfigModal();
                            setToggle1(true);
                          }}
                        >
                          Add Single
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="oldest"
                          onSelect={() => {
                            setToggle1(false);
                            showAddConfigModal();
                          }}
                        >
                          Add Multiple
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="danger"
                      onClick={toggleDeleteAll}
                    >
                      Delete All
                    </Button>
                  </div>
                  {toggle1 && addModal && (
                    <AddUnit
                      modal={addModal}
                      title={title}
                      handleCloseModal={showAddConfigModal}
                      form={form}
                      labels={configData}
                    />
                  )}
                  {!toggle1 && addModal && (
                    <AddBuncheUnits
                      addBunchModal={addModal}
                      title={title}
                      handleCloseAddBunchModal={showAddConfigModal}
                      project={project}
                      labels={configData}
                    />
                  )}
                </CardTitle>

                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>{title} Name</h6>
                      </th>
                      {configData?.map((config, index) => {
                        return (
                          <th
                            key={index}
                            className="text-center"
                            style={{ minWidth: "5rem" }}
                          >
                            <h6 style={{ fontWeight: "bold" }}>
                              {config.item_name}
                            </h6>
                          </th>
                        );
                      })}
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Customer Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Booking Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Units Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        dataKey={configData}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option>05</option>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
          {deleteAll && sweetAlertFuction()}
        </Row>
      </div>
    </React.Fragment>
  );
};
export default ProjectUnits;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteFormData(form, data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("Unit Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">{data.name}</td>
        {props.dataKey.map((key, index) => {
          return (
            <td key={index} className="text-center">
              {data[key.item_name] || "-"}
            </td>
          );
        })}
        <td className="text-center">
          {data.customer_id ? data.customer_id.name : "-"}
        </td>
        <td className="text-center">
          {data.booking_amount ? data.booking_amount : "-"}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateUnit
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              form={form}
              data={props.data}
              labels={props.dataKey}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Input, Row, Table } from "reactstrap";
import { getProjectStaffSalary } from "../../services/userService";
import Loader from "../../components/Custom/Loader";
import EmptyView from "../../components/Custom/EmptyView";
import { numDifferentiation } from "../../helpers/amount_helper";
import moment from "moment";
import { PaginationFunction } from "../../components/Custom/Pagination";

const title = "Staff Salary";
const StaffSalary = () => {
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [loading, setLoading] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [search, setSearch] = useState("");

  let params = window.location.search;
  let projectId = new URLSearchParams(params).get("project_id");
  let projectName = new URLSearchParams(params).get("project");

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const searchFunction = (value) => {
    setSearch(value);
    setActivePage(1);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const getAllStaffSalary = () => {
    const pageAndLimit = `?project_id=${projectId}&page=${activePage}&limit=${pageDataCount}&search=${encodeURIComponent(
      search
    )}`;
    setLoading(true);
    getProjectStaffSalary(pageAndLimit)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setProjectsData(data);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllStaffSalary();
  }, [activePage, pageDataCount, search]);

  return (
    <>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>
              <div className="page-title-middle">
                <h3 className="page-title mb-0 font-size-18">
                  {" "}
                  {projectName}{" "}
                </h3>
              </div>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                {loading && <Loader />}
                <CardTitle
                  className="h3"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {title}
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="form-control rounded"
                      placeholder={`Search by Employee Name`}
                      style={{ width: 280 }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchFunction(e.target.value);
                        }
                      }}
                    />
                  </div>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Employee Name</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Details</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Total</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Phone No </h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Notes</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && projectsData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Staff Salary Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {projectsData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />
                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default StaffSalary;

const GetDataList = (props) => {
  const { data, index, activePage, pageDataCount } = props;

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (activePage - 1) * pageDataCount}
        </td>
        <td className="text-center">{data.employee_name}</td>
        <td className="text-center">{data?.details || "-"}</td>
        <td className="text-center">
          {numDifferentiation(
            !Number.isInteger(data.total) && typeof data.total === "number"
              ? data.total.toFixed(2)
              : data.total
          )}
        </td>
        <td className="text-center">{data?.payment_mode}</td>
        <td className="text-center">
          {moment(data?.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center">{data["Phone No"]}</td>
        <td className="text-center">{data.note}</td>
      </tr>
    </>
  );
};

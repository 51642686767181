import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
  Button,
} from "reactstrap";
import EmptyView from "../../components/Custom/EmptyView";
import {
  deleteEmployeeSalary,
  getAllEmployeeSalary,
  getFormDataWithId,
} from "../../services/userService";
import { PaginationFunction } from "../../components/Custom/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import UpdateEmployeeSalary from "./UpdateEmployeeSalary";
import { notifySuccess } from "../../components/Custom/notification";
import moment from "moment";
import { numDifferentiation } from "../../helpers/amount_helper";
import EmployeePayroll from "./EmployeePayroll";
import AddEmployeeSalary from "./AddEmployeeSalary";
import Loader from "../../components/Custom/Loader";

const title = "Staff Salary";
const EmployeeSalaryDetails = (props) => {
  const [addModal, setAddModal] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageDataCount, setPageDataCount] = useState(30);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [employeeDetails, setEmployeeDetails] = useState("");

  let params = window.location.search;
  let employee_id = new URLSearchParams(params).get("id");

  const callToggle = () => setToggle(!toggle);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChange = (e) => {
    setActivePage(1);
    setPageDataCount(Number(e.target.value));
  };

  const getEmployeeDetails = (employee_id) => {
    setLoading(true);
    getFormDataWithId("EMPLOYEE", employee_id)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setEmployeeDetails(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmployeeDetails(employee_id);
  }, [employee_id]);

  const getEmployeeSalaries = () => {
    setLoading1(true);
    const pageAndLimit = `?page=${activePage}&limit=${pageDataCount}`;
    getAllEmployeeSalary(pageAndLimit, employee_id)
      .then((res) => {
        let data = res && res.data && res.data.data && res.data.data.data;
        setActivePage(data.length === 0 ? 1 : activePage);
        setTotalCount(res.data.data.totalCount);
        setTotalPaid(res.data.data.totalPaid);
        setEmployeesData(data);
        setLoading1(false);
      })
      .catch((err) => {
        setLoading1(false);
      });
  };

  useEffect(() => {
    getEmployeeSalaries();
  }, [toggle, activePage, pageDataCount, employee_id]);

  const showAddConfigModal = () => {
    setAddModal(!addModal);
    callToggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">{title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {" "}
                    P&L Entries / {title}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="d-flex justify-content-between">
                    <span>Employee Details</span>
                  </div>
                </CardTitle>
                {!loading && (
                  <Table hover>
                    <thead>
                      <tr>
                        <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>Employee Name</h6>
                        </th>
                        <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>Total Amount</h6>
                        </th>
                        <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>
                            Salary Paid Amount
                          </h6>
                        </th>
                        <th className="text-center">
                          <h6 style={{ fontWeight: "bold" }}>
                            Outstanding Amount
                          </h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">{employeeDetails?.name}</td>
                        <td className="text-center">
                          {numDifferentiation(totalAmount)}
                        </td>
                        <td className="text-center">
                          {numDifferentiation(totalPaid)}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color:
                              totalAmount - totalPaid > 0 ? "green" : "red",
                          }}
                        >
                          {numDifferentiation(totalAmount - totalPaid)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </div>
          <div>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="d-flex justify-content-between">
                    <span>Salary Payment List</span>
                    <Button
                      type="submit"
                      name="btn"
                      className="custombtn mr-2"
                      style={{
                        marginRight: 10,
                      }}
                      color="primary"
                      onClick={showAddConfigModal}
                    >
                      Add
                    </Button>
                    {addModal && employeeDetails && (
                      <AddEmployeeSalary
                        modal={addModal}
                        title={title}
                        handleCloseModal={showAddConfigModal}
                      />
                    )}
                  </div>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ minWidth: "4rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Sr no.</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "10rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Project</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Employee</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Amount</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Mode</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Payment Date</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Note</h6>
                      </th>
                      <th className="text-center" style={{ minWidth: "5rem" }}>
                        <h6 style={{ fontWeight: "bold" }}>Actions</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading1 && <Loader />}
                    {!loading1 && employeesData?.length === 0 && (
                      <tr>
                        <td colSpan={12}>
                          <EmptyView
                            title={"Sorry!"}
                            discription={"No Data Found"}
                            bgcolor={"white"}
                          ></EmptyView>
                        </td>
                      </tr>
                    )}
                    {employeesData?.map((item, index) => (
                      <GetDataList
                        data={item}
                        key={index}
                        index={index}
                        activePage={activePage}
                        pageDataCount={pageDataCount}
                        toggle={callToggle}
                      />
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={10}>
                        <div
                          style={{
                            width: "fit-content",
                            display: "flex",
                            justifyItems: "flex-start",
                          }}
                        >
                          <PaginationFunction
                            activePage={activePage}
                            pageDataCount={pageDataCount}
                            totalCount={totalCount}
                            handlePageChange={handlePageChange}
                          />

                          <Input
                            style={{
                              width: "47px",
                              height: "45px",
                              marginLeft: "20px",
                              marginTop: "18px",
                              borderColor: "#00b3f4",
                              cursor: "pointer",
                            }}
                            name="pageid"
                            type="select"
                            onChange={(e) => handleChange(e)}
                          >
                            <option selected={pageDataCount === 5 ? 5 : ""}>
                              05
                            </option>
                            <option selected={pageDataCount === 10 ? 10 : ""}>
                              10
                            </option>
                            <option selected={pageDataCount === 20 ? 20 : ""}>
                              20
                            </option>
                            <option selected={pageDataCount === 30 ? 30 : ""}>
                              30
                            </option>
                          </Input>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </CardBody>
            </Card>
          </div>
          <EmployeePayroll
            employeeId={employee_id}
            employeeDetails={employeeDetails}
            setTotalAmount={setTotalAmount}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EmployeeSalaryDetails;

const GetDataList = (props) => {
  const { data, index } = props;
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleShowUpdateModal = () => {
    setUpdateModal(!updateModal);
    props.toggle();
  };
  const handleShowDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleOnDelete = () => {
    deleteEmployeeSalary(data._id).then((res) => {
      handleShowDeleteModal();
      props.toggle();
      notifySuccess("Salary Deleted Successfully");
    });
  };

  const sweetAlertFuction = () => {
    return (
      <SweetAlert
        title={`Are you sure you want to delete this ${title}?`}
        danger
        showCancel
        confirmBtnText="Delete it!"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          handleOnDelete();
        }}
        onCancel={handleShowDeleteModal}
      />
    );
  };

  return (
    <>
      <tr>
        <td className="text-center">
          {index + 1 + (props.activePage - 1) * props.pageDataCount}
        </td>
        <td className="text-center">
          {" "}
          {data.project_id?.map((project, index) => {
            if (index + 1 < data.project_id.length) {
              return project.name + ",";
            } else {
              return project.name;
            }
          })}
        </td>
        <td className="text-center">
          {data.employee_id ? data.employee_id.name : "-"}
        </td>
        <td className="text-center">
          {numDifferentiation(data.amount.toFixed(2))}
        </td>
        <td className="text-center">{data.payment_mode}</td>
        <td className="text-center">
          {moment(data.payment_date).format("DD-MM-YYYY")}
        </td>
        <td className="text-center" style={{ maxWidth: "10rem" }}>
          {data.note}
        </td>
        <td className="text-center">
          <i
            style={{ color: "#134a87", fontSize: 20, cursor: "pointer" }}
            id="edit-btn"
            className="mdi mdi-pencil"
            onClick={handleShowUpdateModal}
          />
          {updateModal && (
            <UpdateEmployeeSalary
              modal={updateModal}
              title={title}
              handleCloseModal={handleShowUpdateModal}
              data={props.data}
            />
          )}
          <i
            className="mdi mdi-trash-can"
            id="delete-btn"
            type="submit"
            onClick={handleShowDeleteModal}
            style={{ fontSize: 24, color: "rgb(223, 71, 89)" }}
          ></i>
          {deleteModal && sweetAlertFuction()}
        </td>
      </tr>
    </>
  );
};

import { Form, Formik } from "formik";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import {
  getFormData,
  updateCustomer,
  updateFormData,
} from "../../services/userService.js";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";

const form = "CUSTOMER";
const UpdateCustomer = (props) => {
  const labels = props.labels;
  const defaultData = props.data;
  const project_id = props.project_id

  const [project, setProject] = useState({
    label: defaultData.project_id ? defaultData.project_id.name : "",
    value: defaultData.project_id ? defaultData.project_id._id : "",
  });
  const [unit, setUnit] = useState({
    label: defaultData.unit_id ? defaultData.unit_name : "",
    value: defaultData.unit_id ? defaultData.unit_id: "",
  });
  const [unitOptions, setUnitOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  //? style for form
  let style = {};
  if (labels.length > 3) {
    style = {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gap: "10px",
    };
  }

  //? get all project for select options
  // const getAllDepartments = () => {
  //   getFormData("PROJECT").then((res) => {
  //     let data = res && res.data && res.data.data && res.data.data.data;
  //     let options = data.map((project) => {
  //       return { label: project.name, value: project._id };
  //     });
  //     setProjectOptions(options);
  //   });
  // };

  // useEffect(() => {
  //   getAllDepartments();
  // }, []);

  //? get all project for select options
  const getAllUnits = (value) => {
    getFormData("UNIT&project_id=" + project_id).then((res) => {
      let data = res && res.data && res.data.data && res.data.data.data;
      let options = data
        .filter((unit) => {
          return !unit.customer_id || unit.customer_id === "";
        })
        .map((unit) => {
          return { label: unit.name, value: unit._id };
        });
      setUnitOptions(options);
    });
  };
  useEffect(() => {
    // getAllUnits();
  }, [project]);

  //? add validations and intialValues for form
  let validationObject = {
    name: Yup.string()
      .trim()
      .required(`Please Enter ${props.title} Name`)
      .max(25, "Maximum 25 Character Allowed"),
    // project_id: Yup.string().trim().required(`Please Select a Project`),
    unit_id: Yup.string().trim().required(`Please Select a Unit`),
    booking_amount: Yup.number()
      .required("Please Enter a Booking Amount")
      .min(0, "Booking amount must be greater than 0")
      .max(50000000, "Maximum Booking Amount is 50000000"),
  };
  let initialValues = {
    name: defaultData.name,
    project_id: defaultData.project_id,
    unit_id: defaultData.unit_id ? defaultData.unit_id : "",
    booking_amount: defaultData.booking_amount,
  };
  labels.map((label) => {
    initialValues = {
      ...initialValues,
      [label.item_name]: defaultData[label.item_name]
        ? defaultData[label.item_name]
        : "",
    };
    if (label.is_required)
      return (validationObject = {
        ...validationObject,
        [label.item_name]:
          label.item_type === "Number"
            ? Yup.number()
                .required(`Please Enter ${label.item_name}`)
                .max(10000000000, "Number should be less than 10 digit")
            : Yup.string()
                .trim()
                .required(`Please enter ${label.item_name}`)
                .max(100, "Maximum 100 Character Allowed"),
      });
    else return null;
  });
  useEffect(() => {
    getAllUnits(project);
  }, []);

  //? handles
  const handleProjectChange = (value) => {
    setProject(value);
    setUnit("");
    getAllUnits(value);
  };
  const handleUnitChange = (value) => {
    setUnit(value);
  };

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = (values, { setFieldError, resetForm }) => {
    setLoading(true);
    updateCustomer(defaultData._id, values)
      .then((res) => {
        props.handleCloseModal();
        setLoading(false);
        notifySuccess("Customer Updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        notifyError(err.data.error.message);
      });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "35%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Update {props.title}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              resetForm,
              setFieldValue,
            }) => (
              <Form>
                <div style={style}>
                  <div style={{ height: "100px" }}>
                    <label>
                      {props.title} Name <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={`Enter ${props.title} Name`}
                      name="name"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  {/* <div style={{ height: "100px" }}>
                    <label>
                      Project <Required />
                    </label>
                    <Select
                      options={projectOptions}
                      name="project_id"
                      value={project}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldValue("project_id", value.value);
                        setFieldValue("unit_id", "");
                        handleProjectChange(value);
                      }}
                      placeholder={"Select Project"}
                    />
                    {errors.project_id && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.project_id}
                      </div>
                    )}
                  </div> */}
                  <div style={{ height: "100px" }}>
                    <label>
                      Unit <Required />
                    </label>
                    <Select
                      options={unitOptions}
                      name="unit_id"
                      value={unit}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        setFieldValue("unit_id", value.value);
                        handleUnitChange(value);
                      }}
                      placeholder={"Select Unit"}
                    />
                    {errors.unit_id && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.unit_id}
                      </div>
                    )}
                  </div>
                  <div style={{ height: "100px" }}>
                    <label>
                      Booking Amount <Required />
                    </label>
                    <CustomInput
                      type="number"
                      values={values}
                      placeholder={`Enter Booking Amount`}
                      name="booking_amount"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  {labels.map((label, index) => {
                    return (
                      <div style={{ height: "100px" }} key={index}>
                        <label>
                          {label.item_name} {label.is_required && <Required />}
                        </label>
                        <CustomInput
                          type={label.item_type}
                          values={values}
                          placeholder={"Enter " + label.item_name}
                          name={label.item_name}
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          style={{ color: "black" }}
                        />
                      </div>
                    );
                  })}
                </div>
                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateCustomer;

import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as Yup from "yup";
import CustomInput, { Required } from "../../components/Custom/textinput.js";
import Select from "react-select";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification.js";
import {
  addProjectExpense,
  getPartyRates,
} from "../../services/userService.js";
import { getFormFields } from "../../services/adminService.js";
import moment from "moment";
import { Link } from "react-router-dom";

const AddRatedBill = (props) => {
  const ref = useRef(null);

  const project = props.project;
  const department = props.department;
  const party = props.party;

  const [loading, setLoading] = useState(false);
  const [configData, setConfigData] = useState([]);
  const [partyRate, setPartyRate] = useState({});
  const [calcAmt, setCalcAmt] = useState(0);
  const [date, setDate] = useState();
  const paymentModeOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
  ];
  const [paymentMode, setPaymentMode] = useState(paymentModeOptions[0]);

  let partyRateError = false;
  const countable = [];

  //?Initial Values For form
  let initialValues = {
    other_exps: 0,
    material_received_date: moment().format("YYYY-MM-DD").toString(),
    note: "",
    challan_no: "",
    material_receiver_name: "",
    work_details: "",
  };
  //? Validations For Form
  let validationObject = {
    material_received_date: Yup.date().required("Please Enter Date"),
    note: Yup.string().max(100, "notes must be less than 100 characters"),
    challan_no: Yup.string()
      .required("Please Enter Challan No")
      .max(100, "Chalan must be less than 100 characters"),
    material_receiver_name: Yup.string()
      .required("Please Enter Receiver Name")
      .max(50, "Name must be less than 50 characters"),
    work_details: Yup.string().max(
      100,
      "details must be less than 100 characters"
    ),
  };

  props.labels.map((data) => {
    initialValues = { ...initialValues, [data.item_name]: "" };
    if (data.is_required) {
      validationObject = {
        ...validationObject,
        [data.item_name]:
          data.item_type === "Number"
            ? Yup.number()
                .required(`Please Enter ${data.item_name}`)
                .min(0, "must be greater than zero")
                .max(10000000, "Number is too big")
            : Yup.string()
                .required(`Please Enter ${data.item_name}`)
                .max(
                  data.item_type === "textarea" ? 100 : 50,
                  `${data.item_name} must be less than ${
                    data.item_type === "textarea" ? 100 : 50
                  }`
                ),
      };
    }
    return null;
  });

  props.labels.filter((data) => {
    data.countable && countable.push(data.item_name);
    return null;
  });
  const validationFunction = () => {
    let errors = {};
    if (paymentMode === "") {
      errors.payment_mode = "Please Select Payment Mode";
    }
    return errors;
  };

  useEffect(() => {
    const getSubDepartmentFields = () => {
      getFormFields("SUBDEPARTMENT-FORM&dept_id=" + department.value).then(
        async (res) => {
          let data = res.data && res.data.data;
          setConfigData(data);
        }
      );
    };
    getSubDepartmentFields();
  }, [department.value]);
  // useEffect(() => {
  //   const getPartyCurrentRates = () => {
  //     let today = moment(date).format("YYYY-MM-DD");
  //     getPartyRates(`${party.value}&date=${today}`).then((res) => {
  //       let [data] = res.data && res.data.data;
  //       setPartyRate(data);
  //     });
  //   };
  //   getPartyCurrentRates();
  // }, [date, party.value]);

  let submitFormSchema = Yup.object().shape(validationObject);
  const handleSubmit = async (values) => {
    if (partyRateError) {
      return;
    }
    let newObj = {};
    for (let value of countable) {
      newObj[value] = {
        ...newObj[value],
        quantity: values[value],
      };
    }

    for (let key in partyRate) {
      newObj[key] = {
        ...newObj[key],
        rate: partyRate[key],
      };
    }

    const nonCountable = props.labels
      .filter((label) => !label.countable)
      .map((label) => label.item_name);
    for (let value of nonCountable) {
      newObj[value] = values[value];
    }
    let data = {
      project_id: project ? project.value : "",
      department_id: department ? department.value : "",
      party_id: party ? party.value : "",
      payment_mode: paymentMode.value,
      amount: values.amount,
      material_received_date: values.material_received_date,
      note: values.note,
      challan_no: values.challan_no,
      material_receiver_name: values.material_receiver_name,
      work_details: values.work_details,
      other_exps: values.other_exps,
      ...newObj,
    };
    setLoading(true);
    addProjectExpense(data)
      .then((res) => {
        setLoading(false);
        props.handleCloseModal();
        notifySuccess("Raw Material Bill Added Successfully");
      })
      .catch((error) => {
        setLoading(false);
        notifyError(error.data.error.message);
      });
  };
  const handlePaymentModeChange = (value) => {
    setPaymentMode(value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.modal}
        centered={true}
        style={{ maxWidth: "60%", width: "100%" }}
      >
        <ModalHeader toggle={props.handleCloseModal}>
          Add Raw Material Bill
        </ModalHeader>
        <ModalBody>
          <Formik
            innerRef={ref}
            initialValues={initialValues}
            validationSchema={submitFormSchema}
            validate={validationFunction}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              setFieldValue,
              resetForm,
              setFieldError,
            }) => (
              <Form>
                <div className="d-flex flex-column gap-3 mb-3">
                  <div>
                    <label>Project</label>
                    <Input
                      type="text"
                      value={project.label}
                      readOnly
                      touched={touched}
                      errors={errors}
                      name="project_id"
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Department</label>
                    <Input
                      type="text"
                      value={department.label}
                      readOnly
                      name="department_id"
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Party</label>
                    <Input
                      type="text"
                      value={party.label}
                      readOnly
                      name="party_id"
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Payment Mode <Required />
                    </label>
                    <Select
                      options={paymentModeOptions}
                      name="payment_mode"
                      value={paymentMode}
                      className={errors.payment_mode ? "border-danger" : ""}
                      errors={errors}
                      touched={touched}
                      onChange={(value) => {
                        handlePaymentModeChange(value);
                      }}
                      placeholder={"Select Payment Mode"}
                    />
                    {errors.payment_mode && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.payment_mode}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Material Received Date <Required />
                    </label>
                    <Input
                      type="date"
                      value={values.material_received_date}
                      placeholder={"Enter Material Received Date"}
                      name="material_received_date"
                      max={moment().format("YYYY-MM-DD")}
                      className={
                        errors.material_received_date ? "border-danger" : ""
                      }
                      onChange={(value) => {
                        setDate(value.target.value);
                        handleChange(value);
                      }}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                    {errors.material_received_date && (
                      <div
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="text-left mt-1 text-danger"
                      >
                        {errors.material_received_date}
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      Challan No./Bill No. <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Challan No"}
                      name="challan_no"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>
                      Material Receiver Name <Required />
                    </label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Material Receiver Name"}
                      name="material_receiver_name"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Work Details</label>
                    <CustomInput
                      type="textarea"
                      values={values}
                      placeholder={"Enter Work Details"}
                      name="work_details"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>

                  {configData.length > 0 &&
                    configData.map((item, index) => {
                      return (
                        <div key={index}>
                          <label>
                            {item.item_name}
                            {item.is_required && <Required />}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </label>
                          <Input
                            type={item.item_type.toLowerCase()}
                            value={values[item.item_name]}
                            placeholder={`Enter ${item.item_name}`}
                            name={item.item_name}
                            className={
                              errors[item.item_name] ? "border-danger" : ""
                            }
                            onChange={handleChange}
                            touched={touched}
                            errors={errors}
                            style={{ color: "black" }}
                          />
                          {errors[item.item_name] && (
                            <div
                              style={{
                                fontSize: 14,
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="text-left mt-1 text-danger"
                            >
                              {errors[item.item_name]}
                            </div>
                          )}
                          {item.countable && (
                            <div className="d-flex flex-row align-items-center">
                              <Input
                                type="number"
                                value={
                                  partyRate[item.item_name] &&
                                  partyRate[item.item_name]
                                }
                                onChange={(e) =>
                                  setPartyRate({
                                    ...partyRate,
                                    [item.item_name]: e.target.value,
                                  })
                                }
                                placeholder="Enter Rate"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  <div>
                    <label>Other Expense & Tax</label>
                    <Input
                      type="number"
                      value={values.other_exps}
                      name="other_exps"
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Amount</label>
                    <label className="mx-4">
                      {"Calculated Amount "}
                      <Button
                        className="btn btn-primary"
                        onClick={() => {
                          setFieldValue(
                            "amount",
                            Number.isInteger(calcAmt) &&
                              typeof calcAmt === "number"
                              ? calcAmt
                              : calcAmt.toFixed(2)
                          );
                        }}
                      >
                        {(() => {
                          let total = 0;
                          countable.map((e) => {
                            if (values && partyRate) {
                              return (total +=
                                values[e] * (partyRate[e] ? partyRate[e] : 0));
                            } else return total;
                          });

                          values.other_exps
                            ? setCalcAmt(total + values.other_exps)
                            : setCalcAmt(total);
                          return Number.isInteger(
                            total + Number(values.other_exps)
                          ) && typeof total === "number"
                            ? total + Number(values.other_exps)
                            : (total + Number(values.other_exps)).toFixed(2);
                        })()}
                      </Button>
                    </label>
                    <Input
                      type="number"
                      value={values.amount}
                      name="amount"
                      readOnly
                      // onChange={handleChange}
                      // touched={touched}
                      // errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                  <div>
                    <label>Note</label>
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder={"Enter Note"}
                      name="note"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      style={{ color: "black" }}
                    />
                  </div>
                </div>

                <ModalFooter className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    disabled={loading}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {" "}
                    Add
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddRatedBill;

const RateError = (props) => {
  props.error();
  return (
    <>
      <Link to={props.url}>
        <span className="text-danger"> Party Has To Decide Rate First </span>
      </Link>
    </>
  );
};
